.conditionsForm {
  display: flex;
  flex-direction: column;
  position: relative;

  &__select {
    cursor: pointer;
    width: fit-content;
    margin-left: 6px;
    margin-bottom: 18px;
    text-decoration: underline;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    text-decoration-line: underline;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #FE6D04;

    &.view {
      cursor: default;
      pointer-events: none;
    }
  }

  &__button {
    width: 100%;

    .GlobalButton {
      width: 100%;
      padding: 16px 0;
      margin-top: 8px;

      & > svg path {
        fill: #FE6D04;
      }
    }
  }

  &__button.empty {
    .GlobalButton {
      padding: 32px 0;
    }
  }

  &__recipients {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 100%;
    margin-left: 30px;

    & > span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
    }

    & > h4 {
      text-align: right;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.01em;
    }

    &.error {
      & > span, & > h4{
        color: #F54242;
      }
    }
  }

  &__exclaim {
    display: flex;
    position: absolute;
    top: 0;
    left: calc(100% + 152px);
  }

  &__exclaim > * + * {
    margin-left: 12px;
  }
}

.conditionsForm > .conditionItem + .conditionItem {
  margin-top: 4px;
}
