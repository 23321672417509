.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  width: 48px;
  height: 48px;
  overflow: hidden;
  background: #a3a3db;
  border-radius: 24px;

  img {
    width: 100%;
    height: 100%;
  }

  & > p {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
  }

  & > svg path {
    fill: white;
  }
}
