.firstStep {
  display: flex;
  flex-direction: column;
  color: #333333;

  &__title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 12px;
  }

  &__description {
    font-size: 14px;
    margin-bottom: 24px;
  }

  &__buttonsGroup {
    & > button {
      cursor: pointer;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 14px;
      padding: 8px 12px;
      border-radius: 8px;
    }

    &_beginButton {
      border: none;
      color: #FFFFFF;
      background: #FE6D04;
      box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.25);
      margin-right: 36px;
    }

    &_laterButton {
      color: #FE6D04;
      background: #FFFFFF;
      border: 1px solid #FFC093;
      box-sizing: border-box;
      box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.2);
    }
  }
}

