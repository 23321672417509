
.TelegramBotCreateUpdateConnection {
  display: flex;
  flex-direction: column;
  max-width: 910px;
  padding-right: 48px;
  row-gap: 20px;
  font-family: 'Inter', sans-serif;

  &__title {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 28px;

    &__text {
      display: grid;
      align-self: end;

      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      color: #333333;
    }

    &__button {
      display: grid;
      align-items: center;

      & > button {
        padding: 6px 12px 6px 8px;
      }
    }
  }

  &__editMode{
    display: grid;
    row-gap: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;

    &__instruction{

      h3{
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
      }

      &__list{
        display: grid;
        row-gap: 12px;

        &__item{
          display: flex;
          column-gap: 6px;

          a{
            color: #FE6D04;
          }

          svg{
            flex-shrink: 0
          }

        }
      }
    }

  }

  &__name {

    &__text {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
    }

    & input {
      outline: none;
      width: 400px;

      &.active {
        border: 3px solid #F54242;
      }
    }
  }

  &__token {
    
    & input {
      width: 400px;
    }

    &__title {
      display: flex;
      flex-direction: row;
      position: relative;

      svg {
        margin-left: 8px;
      }

      &__text {
        display: grid;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
      }

      &__icon {
        display: grid;
        align-items: center;
        margin-top: 2px;
        cursor: pointer;
        font-family: 'Inter', sans-serif;

        &:hover &_info {
          display: block;
        }

        &_info {
          display: none;
          position: absolute;
          cursor: pointer;
          top: -60px;
          margin-left: 20px;
          padding: 20px 25px;
          background: #FFFFFF;
          box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);
          border-radius: 12px;
          z-index: 22;
        }

      }

    }
  }

  &__submit {
    margin-top: 25px;

    &.editMode{
      bottom: 32px;
    }

    & > button {
      padding: 14px 16px;
    }

    .GlobalButton.disabled {
      cursor: pointer;
      background: #E0E0E0;
      color: #828282;
      box-shadow: 0 4px 4px -2px #828282;
    }
  }

  &__error{
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #F54242;
  }
}
