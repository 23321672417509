.templateForm {
  width: 418px;

  .search {
    margin-bottom: 16px;

    input {
      width: 418px;
      padding-left: 40px;
      padding-right: 32px;
      background: #F9F9F9;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .templateList {
    height: 144px;
    overflow-y: auto;
  }

  &.error {
    border: 3px solid #F54242;
    border-radius: 10px;
    padding: 4px 4px 0 4px;

    .search input{
      width: 400px;
    }
  }
}
