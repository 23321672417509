.log_list {
  display: grid;
  
  & tbody {
    height: calc(100vh - 294px);
    margin-bottom: 30px;
    overflow-y: scroll;
  }

  &__header, &__item {
    display: grid;
    grid-template-columns: minmax(23%, 276px) minmax(15%, 170px) minmax(8%, 96px) repeat(2, minmax(12.5%, 142px)) auto;
    grid-column-gap: 24px;
  }

  &__header {
    padding-bottom: 12px;
  }

  &__item {
    padding: 22px 0 16px 0;
    border-bottom: 2px solid #E6E6EA;
  }

  &__item:first-child {
    border-top: 2px solid #E6E6EA;
  }

  .load {
    position: initial;
  }
}

.broadcastsLog {
  .paginator {
    padding-right: 48px;
  }
}
