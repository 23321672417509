@import 'src/mixin.scss';

.sidePanel {
  position: fixed;
  top: 0;
  opacity: 1;
  z-index: 115;
  @include desktop {
    right: 0;
    width: 40%;
    padding: 32px 0 32px 48px;
    border-radius: 32px 0 0 32px;
    box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.2);
    animation: ani 0.8s forwards;
  }
  @include mobile {
    left: 0;
    width: 100%;
    padding: 20px 0 58px 24px;
    border-radius: 28px 28px 0px 0px;
    animation: vslide 0.8s forwards;
  }
  height: 100%;
  background: #FFFFFF;

  &__content {
    height: 100%;
    overflow-y: scroll;
    @include desktop {
      padding-right: 48px;
    }
    @include mobile {
      padding-right: 24px;
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    &.none {
      background-color: rgba(0, 0, 0, 0);
    }

    &.light {
      background: #333333;
      opacity: 0.3;
    }

    &.dark {
      background: black;
    }
  }

  & > img {
    cursor: pointer;
    position: absolute;
    top: 28px;
    right: 28px;
    width: 16px;
    height: 16px;
    z-index: 100;
  }
}
