.videoPreviewContainer {
  position: relative;
  background: #E5E5EB;
  border-radius: 0 8px;
  display: flex;
  margin-right: 4px;
  margin-top: 2px;

  & .videoPreview {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    margin-left: 12px;

    & > video {
      object-fit: cover;
    }

    & .video-controls {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;

      &__pauseIcon {
        width: 32px;
        height: 32px;
        opacity: 0;
        transition: opacity 0.4s;
        cursor: pointer;

        &.active {
          opacity: 1;
        }
      }

      & > img {
        cursor: pointer;
      }
    }
  }

  &__trashIcon {
    cursor: pointer;
    position: absolute;
    top: 8%;
    right: 5%;
  }
}
