.catalogStatus {
  display: flex;
  align-items: center;
  margin-left: -24px;
  margin-bottom: 8px;

  & > p {
    &.disconnected, &.expired {
      color: #F54242;
    }
  }

  &_point {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin-left: 12px;
    margin-right: 6px;

    &.disconnected, &.expired {
      background-color: #F54242;
    }
  
    &.connected {
      background-color: #2CBA5F;
    }
  }
}
