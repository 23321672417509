.statusBar{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  max-width: 416px;
}

.statusBar > * + * {
  margin-left: 16px;
  margin-top: 8px;
}
