.wabaProductContent {
  position: relative;
  display: flex;

  .regularText.bold {
    margin-bottom: 4px;
  }
  .monospaceRegText.middle {
    margin-bottom: 4px;
  }
  .smallText:nth-child(3) {
    margin-bottom: 4px;
  }

  &_image {
    margin-right: 12px;
    display: flex;
    width: 64px;
    height: 64px;
    padding: 16px;
    justify-content: center;
    align-items: center;

    &.plug {
      border-radius: 8px;
      background: #FFFFFF;
    }

    img {
      width: 64px;
      height: 64px;
      object-fit: cover;
    }
  }

  &_content {

    a p {
      color: #FE6D04;
    }

    .comment {
      margin-top: 16px;

      .audioPlayer {
        width: 250px;
      }
    }
  }
}
