@import 'src/mixin.scss';

.chatMessage {
  max-width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.outbound {
    justify-content: flex-end;
  }

  &.inbound {
    justify-content: flex-start;
  }

  & .message {
    display: flex;
    border-radius: 14px;
    position: relative;
    margin: 0 0;
    max-width: 75%;
    padding: 10px 8px 10px 16px;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15));

    @include mobile {
      max-width: 85%;
    }

    & .mediaContainer {
      max-width: 400px;
      white-space: pre-wrap;

      @include mobile {
        max-width: 100%;
      }

      & .author {
        display: inline-flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.5%;
        color: #015CE3;
      }

      .imageText {
        word-wrap: break-word;
      }

      & .wabaTemplateMessage {
        white-space: pre-wrap;

        &__media {
          margin-bottom: 10px;

          a {
            line-break: anywhere;
          }

          & > img, video {
            width: 100%;
            object-fit: contain;
          }
        }

        &__text {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.005em;
          color: #333333;
          margin-bottom: 10px;
        }

        &__buttons {
          flex-wrap: wrap;
          display: flex;
          justify-content: center;

          & button {
            margin: 5px;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.005em;
            font-family: Inter, sans-serif;
            outline: none;
            border: none;
            width: 190px;
            height: 32px;
            background: #2F80ED;
            box-shadow: 0 4px 4px -2px rgba(0, 12, 68, 0.25);
            border-radius: 8px;
          }
        }
      }

      & .wabaInteractiveMessage {

        &__attachment {
          display: flex;
          width: 100%;

          & img, video {
            width: 100%;
          }
        }

        &__textSection {
          margin-top: 16px;
          margin-bottom: 12px;

          &_attachmentTitle {
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.005em;
            color: #333333;
            margin-bottom: 4px;
          }

          &_body {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.005em;
            color: #333333;
            word-break: break-word;
            margin-bottom: 8px;
          }

          &_footer {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.005em;
            color: #4F4F4F;
          }
        }

        &__actionSection {

          & .buttonsContainer {
            display: flex;
            flex-direction: column;

            &__button {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 32px;
              text-align: center;
              background: #2F80ED;
              box-shadow: 0 4px 4px -2px rgba(0, 12, 68, 0.25);
              border-radius: 8px;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.005em;
              color: #FFFFFF;
              margin-bottom: 8px;
              padding: 10px;
            }
          }

          & .section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background: #FFFFFF;
            box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.15);
            border-radius: 8px;

            &__title {
              margin-top: 12px;
              margin-left: 16px;
              font-weight: bold;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.01em;
              color: #333333;
            }

            &__rowsContainer {
              margin-left: 6px;
              display: flex;
              width: 100%;
              flex-direction: column;
              padding: 10px;
              padding-right: 40px;

              &_row {
                display: flex;
                flex-direction: column;
                width: 100%;
                border-bottom: 1px solid #E0E0E0;
                padding-bottom: 4px;
                margin-bottom: 6px;

                &__title {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: -0.005em;
                  color: #333333;
                }

                &__description {
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: -0.005em;
                  color: #333333;
                }

              }
            }
          }
        }
      }

      & > pre, & .fileContent > pre {
        margin: 0;
        white-space: pre-wrap;
        display: grid;
        font-family: Inter, sans-serif;
        word-break: break-word;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        color: #333333;
      }

      .fileContent {
        display: grid;
        word-break: break-word;
      }

      & > img {
        width: 100%;
        margin-bottom: 4px;
      }

      & .fileLoader {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    & .textContent {
      margin: 0;
      white-space: pre-wrap;
      font-family: Inter, sans-serif;
      word-break: break-word;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      color: #333333;

    }

    & > img {
      height: 400px;
      width: 400px;
      object-fit: cover;
    }
  }

  & .messageDate {
    display: flex;
    justify-content: space-between;
    font-family: Inter, sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.04em;
    color: #4F4F4F;
    margin-left: 12px;
    min-width: 45px;
    align-items: center;

    &.unread {
      & svg path {
        fill: #d2d2d2;
      }
    }

    & svg {
      margin-left: 8px;
      align-self: center;
    }
  }
}

.message.outbound {
  background: #F5F4F3;

  & .message_tail {
    width: 12px;
    height: 18px;
    position: absolute;
    right: 0;
    bottom: -5px;
    transform: rotateY(180deg);

    & svg path {
      fill: #F5F4F3;
    }
  }
}

.message.inbound {
  background: #F4F9FF;

  &.selected {
    background-color: #E1EDFF;

    & .message_tail svg path {
      fill: #E1EDFF
    }

    & .messageDate svg path {
      fill: #4D76B3;
    }
  }

  & .message_tail {
    width: 12px;
    height: 18px;
    position: absolute;
    left: 0;
    bottom: -5px;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 555;
  cursor: pointer;
}

.imageModal {
  justify-content: center;
  width: 80%;
  max-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 4;

  & > img {
    max-width: 100%;
    object-fit: contain;
  }
}

.customAudioPlayer {
  display: flex;
  height: 30px;
  align-items: center;
  min-width: 100%;

  .waveSurferContainer {
    min-width: 200px;

    @include mobile {
      min-width: 120px;
    }
  }

  & > img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  &__time {
    width: 20px;
    margin-left: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #2CBA5F;
  }
}

.messagesDate {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  &__date {
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    padding: 6px 10px;
    background: linear-gradient(0deg, #D6D6E9, #D6D6E9);
    border-radius: 16px;
  }
}
