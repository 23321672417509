.catalogsChooseProductList {
  height: calc(100vh - 458px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
  color: #000000;

  &__product {
    &.splitter {
      padding-top: 24px;
      border-top: 3px;
      border-top-style: solid;
      border-top-color: #E0E0E0;
      border-top-left-radius: 1px;
      border-top-right-radius: 1px;
    }
  }
}
