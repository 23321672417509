.addTags {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .addTagItem {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 4px 8px;
    gap: 8px;
    border-radius: 6px;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
  }

  .dropDownAddTags {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    background: #F4F9FF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.20) inset;

    .regularText {
      color: #828282;
    }

    .arrow {
      transform: rotateX(0);
      transition-duration: 300ms;
      width: 16px;
      height: 16px;

      &.open {
        transition-duration: 300ms;
        transform: rotateX(180deg);
      }
    }

    &__list {
      max-height: 200px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 5px;
      background: #f4f9ff;
      border-radius: 8px;
      padding: 8px;

      &_item {
        padding: 4px;
        cursor: pointer;

        &:hover {
          background: #ffe1cc;
          border-radius: 6px;
        }
      }
    }

    &__preview {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      max-height: 140px;
      overflow-y: scroll;
    }
  }
}
