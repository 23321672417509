.integrations, .integrationDescription {
  overflow-y: auto;
  padding-right: 48px;
}

.integrationMainContent {
  display: flex;
  flex-direction: column;

  .integrationContent {
    display: grid;
    grid-template-columns: repeat(auto-fill, 240px);
    grid-row-gap: 32px;
    grid-column-gap: 16px;
    row-gap: 32px;
    column-gap: 16px;
    color: #474747;
  }

  h1 {
    margin-bottom: 16px;
  }

  .integrationInstalledContainer {
    margin-bottom: 8px;
  }

  .integrationDiv {
    display: flex;
    flex-direction: column;
    width: 240px;

    & > *:first-child {
      margin-bottom: 12px;
    }

    p {
      color: #474747;
      font-size: 14px;
      font-family: Inter, sans-serif;
      text-align: center;
      word-break: break-word;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.07px;
    }

    .integrationImg {
      cursor: pointer;
      border-radius: 12px;
      height: 130px;

      img {
        width: 240px;
        height: 130px;
        border: 1px solid #F2F2F2;
        box-sizing: border-box;
        border-radius: 12px;
      }
    }
  }
}

.integrationDescription {
  &__header {
    display: grid;
    align-items: start;
    justify-items: start;
    margin-bottom: 25px;

    &__notification {
      display: grid;
      margin-bottom: 15px;

      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cv08' on;
      color: #F54242;

      &__title {
        display: grid;
        grid-template-columns: 25px 1fr;
        align-items: center;

        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cv08' on;
        color: #F54242;
      }

      &__text {
        span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    &__oneButton {
      display: grid;
      grid-template-columns: 40px auto;
      border-radius: 10px;
      background-color: #394f70;
      border: 1px solid rgb(0, 111, 159);
      cursor: pointer;

      font-family: sans-serif;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #ffffff;

      &__logo {
        position: relative;
        display: grid;
        width: 100%;
        height: 100%;

        img {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
          border-radius: 9px 0 0 9px;
        }
      }

      &__text {
        display: grid;
        align-items: center;
        padding: 0 20px;
        width: max-content;
        height: 54px;
      }
    }
  }

  &__text {
    h2 {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    li {
      font-family: Inter, serif;
      font-style: normal;
      font-weight: normal;
    }
  }

  &__Description {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
  }

  .amocrm_install_button {
    display: inline-flex;
    align-items: center;
    margin-bottom: 25px;
    border-radius: 10px;
    overflow: hidden;
    height: 58px !important;
    background-color: #394f70 !important;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 13px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;

    .amocrm_install_button_div:first-child {
      padding: 0 8px !important;
    }

    .amocrm_install_button_div {
      height: 100%;
      display: flex;
      background: #006f9f;
      align-items: center;
    }

    span {
      display: inline-block;
      font-family: 'Roboto', sans-serif;
      font-style: normal;

      letter-spacing: -0.005em;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: none !important;
      color: #ffffff;
    }
  }

  &__description {
    display: flex;

    &_item {
      margin-top: 20px;

      &-title {
        display: inline-flex;
      }

      &-text {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        color: #333333;
      }

      &-mark {
        margin-right: 6px;
        justify-content: center;
        display: flex;
        align-items: center;
        min-width: 20px;
        height: 20px;
        border: 1px solid #333333;
        box-sizing: border-box;
        border-radius: 12px;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      a {
        color: #fe6d04;
        text-decoration: underline;
      }
    }
  }

  &__form {
    margin-top: 12px;
    margin-bottom: 48px;

    .GlobalButton {
      padding: 14px 16px;
    }
  }
}
