.contacts {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-areas: "header" "contant" "footer";
  grid-template-rows: 250px 1fr 70px;
  padding: 32px;
  color: #333333;
  background: #FFFFFF;
  box-shadow: -12px 0 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0 0 32px;
  font-style: normal;
  z-index: 2;

  &__header {
    display: grid;
    grid-area: header;
    grid-template-rows: 1fr 70px;

    .search__input{
      border: 1px solid #E0E0E0;
      box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.30) inset;
    }

    &__sort {
      display: grid;
      grid-template-rows: 76px 1fr;

      &__titleButton {
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content: space-between;

        button{
          outline: none;
          border: none;
          display: grid;
          grid-template-columns: 20px 1fr;
          column-gap: 8px;
          padding: 15px;
          cursor: pointer;

          right: 48px;
          top: 28px;
          background: #FE6D04;
          box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.25);
          border-radius: 10px;

          color: white;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
        }


      }

      &__inputs{
       display: flex;
        flex-direction: row;
        align-items: flex-end;
        column-gap: 24px;

        .dropdownContainer {
          margin-bottom: 0;
          width: 300px;
        }

        &__keyWords{
          display: grid;
          margin-top: 39px;
          margin-bottom: 4px;

          &__item{
            display: grid;
            grid-template-columns: 40px 1fr;
            height: 48px;
            max-width: 312px;

            background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
            border: 1px solid #E0E0E0;
            box-sizing: border-box;
            box-shadow: inset 0 2px 4px -2px rgba(0, 0, 0, 0.3);
            border-radius: 24px;

            &:focus-within{
              border: 3px solid #A3BCFF;
            }

            &__icon{
              display: grid;
              align-self: center;
              justify-self: center;
              cursor: pointer;
            }

            input{
              display: grid;
              align-items: end;
              margin: 7px;
              outline: none;
              border: none;
              background: #FFFFFF;

              font-family: Inter, sans-serif;
              font-weight: normal;
              font-size: 18px;
              line-height: 24px;
              color: #828282;
            }


          }

        }
      }

    }

    &__list{
      display: grid;
      grid-template-columns: 66px minmax(250px, 1fr) repeat(4,minmax(200px,1fr));
      padding: 0 16px;
      align-items: center;
      justify-items: start;

      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      border-bottom: 2px #E6E6EA solid;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .loaderContainer {
      height: 100%;
    }

    &__nothing{
      display: grid;
      align-items: center;
      justify-items: center;

      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.01em;
    }
  }
}


