.createAndEditProduct {

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    img {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    margin-bottom: 40px;

    .textareaContainer_textarea{
      width: 100%;
      height: 110px;
    }

    .priceAndCurrency {
      display: flex;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }
      input[type=number] {
        -moz-appearance:textfield; /* Firefox */
      }
      .inputContainer:first-child {
        margin-right: 12px;
      }

      input {
        width: 100%;
      }
    }

    .imagesGroup {
      margin-top: 24px;

      &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        p {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.005em;
          font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cpsp' on;
          color: #333333;
        }
      }
    }
  }

  .dropdownTags__wrapperPreview__previewList__item {
    background: #FFFFFF;
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;

    &.clicked {
      pointer-events: none;
    }

    .GlobalButton {
      padding: 13px 16px 15px;
    }
  }

  .inputContainer {
    width: 100%;
  }

  .inputContainer__wrapper{
    width: 100%;
  }

  input {
    padding: 0 16px 0 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }
}
