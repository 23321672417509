.InvoiceItemChat {
  padding: 12px 12px 0 12px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
  border-radius: 6px;

  &__item {
    border-bottom: 1px #bdbdbd solid;
    border-radius: 2px;
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &_content {
      margin-bottom: 4px;

      & > div {
        padding-bottom: 12px;
        display: flex;
        flex-direction: column;

        h6 {
          padding-bottom: 4px;
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.005em;
          font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
          color: #333333;
        }

        p {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.04em;
          font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
          color: #333333;
        }
      }
    }

    &_button {
      padding: 12px 0;
      cursor: pointer;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      text-decoration-line: underline;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #fe6d04;
    }
  }
}
