.ContactDetailEdit__wrapper {
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  padding: 32px 0 32px 48px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.05);
  border-radius: 32px 0 0 32px;
}

.ContactDetailEdit {
  display: flex;
  flex-direction: column;

  & > h1 {
    margin-bottom: 24px;
  }

  &__content {

    .inputContainer{
      width: 464px;
      margin-bottom: 0;

      &__wrapper, input{
        width: 100%;
      }
    }
  }

  &__content > * + * {
    margin-top: 32px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 40px;

    .GlobalButton{
      padding: 13px 16px 15px 16px;
    }
  }

  &__footer > * + * {
    margin-left: 24px;
  }
}

.ContactDetailEdit__item__list__item__focus:focus-within {
  background: #F4F9FF;
  border-radius: 6px;
  border: 3px solid #A3BCFF;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) inset;
}
