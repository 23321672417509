.products_list {
  display: grid;
  grid-area: list;
  height: calc(100vh - 174px);
  grid-template-areas: 'title' 'content';
  grid-template-rows: 0fr 1fr;
}

.products__title {
  display: grid;
  grid-area: title;
  grid-template-columns: 16% minmax(20%, 3fr) repeat(1, minmax(17%, 0fr));
  padding-right: 40px;

  & > div {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
    padding-bottom: 12px;
    border-radius: 2px;
    border-bottom: 2px solid #E6E6EA;
  }
}

.products__content {
  grid-area: content;
  overflow-y: auto;
  padding-right: 40px;
}

@media screen and (max-width: 1400px) {
  .products__title {
    grid-template-columns: 16% minmax(20%, 2fr) repeat(1, minmax(29%, 0fr));
  }
  .products__content {
    .productItem {
      grid-template-columns: 16% minmax(20%, 2fr) repeat(1, minmax(28%, 0fr));
    }
  }
}

@media screen and (max-width: 1024px) {
  .products__title {
    grid-template-columns: 16% minmax(20%, 2fr) repeat(1, minmax(29%, 0fr));
  }
  .products__content {
    .productItem {
      grid-template-columns: 16% minmax(20%, 2fr) repeat(1, minmax(28%, 0fr));
    }
  }
}

@media screen and (max-width: 800px) {
  .products__title {
    grid-template-columns: 16% minmax(20%, 2fr) repeat(1, minmax(29%, 0fr));
  }
  .products__content {
    .productItem {
      grid-template-columns: 16% minmax(20%, 2fr) repeat(1, minmax(28%, 0fr));
    }
  }
}

@media screen and (max-width: 420px) {
  .products__title {
    grid-template-columns: 16% minmax(20%, 2fr) repeat(1, minmax(29%, 0fr));
  }
  .products__content {
    .productItem {
      grid-template-columns: 16% minmax(20%, 2fr) repeat(1, minmax(28%, 0fr));
    }
  }
}
