.chatBillContainer__content__productContainer {
  margin-bottom: 48px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }

  input[type='number'],
  input[type='number']:hover,
  input[type='number']:focus {
    appearance: none;
    -moz-appearance: textfield;
  }

  &_content {
    margin-bottom: 24px;
    margin-top: 36px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      background: #BDBDBD;
      border-radius: 1px;
      width: 100%;
      height: 1px;
      bottom: -24px;
    }

    &_errorName {
      margin-top: 12px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      color: #F54242;
    }

    &_error {
      position: absolute;
      margin-top: 12px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      color: #F54242;
    }

    &_title {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      margin-bottom: 16px;

      h5 {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
        color: #333333;
      }

      p {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;
        color: #F54242;

        img {
          margin-right: 4px;
        }
      }
    }

    .contentList {
      display: none;
      position: absolute;
      width: 100%;
      max-height: 320px;
      background: #F4F9FF;
      border-radius: 6px;
      padding: 6px 2px 6px 2px;
      z-index: 2222;
      margin-top: 4px;
      overflow: auto;

      &:hover {
        display: block;
      }

      &_item {
        border-radius: 6px;
        cursor: pointer;
        padding: 8px 10px 10px 10px;

        &:hover {
          background: #FFE1CC;
        }
      }
    }

    & .inputContainer {
      margin-bottom: 24px !important;
      &__wrapper {
        width: auto;
      }

      &__input {
        width: 100%;

        &.name:focus + .contentList {
          display: block;
        }
      }
    }

    &_inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .inputContainer {
        margin-right: 0;
      }
    }

    h4 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;
      color: #333333;
    }
  }

}
