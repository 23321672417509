@import 'src/mixin.scss';

.dropdownTemplate {
  position: relative;
  width: 131px;
  background: #fff;
  font-weight: 500;
  color: #4F4F4F;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
  margin: 0 26px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 5px;

  font-family: Inter, sans-serif;
  height: 32px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: center;
  cursor: pointer;

  @include mobile {
    width: auto;
    padding: 8px 6px 8px 8px;
    gap: 8px;
  }

  @include _1300 {
    width: 50px;
    font-size: 0;
  }

  &.blocked {
    background: #E0E0E0;
    cursor: url("../../../../../assets/cursor.svg"), auto;
  }

  & svg.blocked {
    cursor: url("../../../../../assets/cursor.svg"), auto;

    & path {
      fill: #BDBDBD;

    }
  }

  & .templates {
    display: flex;
    flex-direction: column;

    &__upperBlock.blocked .search {
      width: 100%;
      margin-left: 16px;
      margin-right: 16px;

      &__input {
        width: 100%;
      }
    }

    &__upperBlock {
      margin-top: 8px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      @include mobile {
        margin-top: 12px;
      }

      &__templatesSwitch {
        display: flex;
        padding: 3px;
        background: #F9F9F9;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
        border-radius: 8px;

        &_item {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          padding: 5px 12px;


          &.active {
            background: #FE6D04;
            box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.15);
            border-radius: 7px;

            & > p {
              color: #fff;
            }
          }

          & > p {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.005em;
            font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
            color: #4F4F4F;
          }
        }
      }

      & .search {
        margin: 0;

        @include mobile {
          width: 100%;
          margin: 0 20px;
          gap: 6px;
        }

        &__input {
          padding-left: 35px;
          padding-right: 10px;
          font-size: 14px;
          width: 166px;
          height: 36px;
          color: #828282;

          @include mobile {
            width: 100%;
            height: 48px;
            font-family: Inter, sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.09px;
          }
        }

        & svg {
          top: 11px;
          width: 13px;
          height: 13px;

          @include mobile {
            top: auto;
            width: 16px;
            height: 16px;
          }

          & path {
            fill: #828282;
          }
        }
      }
    }
  }

  & .template {
    display: flex;
    flex-direction: column;
    max-height: 136px;
    overflow-y: scroll;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 5px;
    cursor: pointer;

    @include mobile {
      margin-top: 24px;
      max-height: 200px
    }

    &__content {
      display: flex;
      background: #F5F4F3;
      border: 1px solid #F2F2F2;
      box-sizing: border-box;
      border-radius: 8px;
      width: 92%;
      overflow: hidden;
      white-space: nowrap;
      min-height: 40px;
      margin-bottom: 8px;
      align-items: center;

      & > p {
        position: relative;
        width: 95%;
        display: flex;
        margin-left: 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        color: #333333;
        overflow: hidden;

        @include mobile {
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.09px;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          width: 34px;
          height: 30px;
          right: 0;
          background: linear-gradient(90deg, rgba(245, 244, 243, 0) 0%, rgba(245, 244, 243, 0.0086472) 6.67%, rgba(245, 244, 243, 0.03551) 13.33%, rgba(245, 244, 243, 0.0816599) 20%, rgba(245, 244, 243, 0.147411) 26.67%, rgba(245, 244, 243, 0.231775) 33.33%, rgba(245, 244, 243, 0.331884) 40%, rgba(245, 244, 243, 0.442691) 46.67%, rgba(245, 244, 243, 0.557309) 53.33%, rgba(245, 244, 243, 0.668116) 60%, rgba(245, 244, 243, 0.768225) 66.67%, rgba(245, 244, 243, 0.852589) 73.33%, rgba(245, 244, 243, 0.91834) 80%, rgba(245, 244, 243, 0.96449) 86.67%, rgba(245, 244, 243, 0.991353) 93.33%, #F5F4F3 100%);
        }
      }
    }
  }

  & .popupWrapper {
    @include desktop {
      cursor: auto;
      display: flex;
      flex-direction: column;
      width: 500px;
      min-height: 236px;
      right: 0;
      left: auto;
    }

    & > svg {
      left: initial;
      right: 24px;
      bottom: -6px;
    }

    & .templateContainer {
      display: flex;
      flex-direction: column;
      margin: 12px 16px 16px 16px;
      flex: 1;
      justify-content: space-between;

      .templateContent {
        padding: 10px 12px;
      }

      &__buttonsContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .GlobalButton {
          padding: 6px 12px 6px 8px;

          &:last-child {
            padding: 6px 12px;
          }
        }
      }
    }
  }
}
