@import 'src/mixin.scss';

.conversationListContainer {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  flex-shrink: 1;
  flex-basis: 376px;
  min-width: 376px;
  height: 100vh;
  overflow: hidden;
  position: relative;
  border-radius: 20px 0 0 20px;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.2);

  @include mobile {
    width: 100%;
    min-width: 100%;
  }
}
