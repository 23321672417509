.dateTimePicker {
  display: flex;
  width: 200px;
  align-items: center;
  position: relative;
  white-space: nowrap;

  img {
    position: absolute;
    right: 10px;
    pointer-events: none;
  }

  .react-datepicker-wrapper {
    input {
      cursor: pointer;
      width: 200px;
      height: 36px;
      padding: 8px 31px 8px 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      outline: none;
      border: 0;
      background: #F4F9FF;
      box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
  }

  .react-datepicker {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 12px 8px -8px rgba(0, 0, 0, 0.15);
    border-radius: 20px;

    &__header {
      background: #FFFFFF;
      border-bottom: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    &__today-button {
      cursor: pointer;
      text-align: center;
      font-weight: bold;
      background: #FFFFFF;
      border-top: 0;
    }

    &__header--time {
      border: 1px solid #F2F2F2;
      border-bottom: 0;
      box-shadow: 0px 12px 8px -8px rgba(0, 0, 0, 0.15);
    }

    &__time-container--with-today-button {
      right: -85px;
      border: 0;
    }

    &__time, &__time-box, &__time-container {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &__time {
      border: 1px solid #F2F2F2;
      border-top: 0;
      box-shadow: 0px 12px 8px -8px rgba(0, 0, 0, 0.15);
    }
  }

  .react-datepicker-time {

    &__header {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 100%;
    }
  }

}
