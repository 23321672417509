@import 'src/mixin.scss';

.subscriptionsItem {
  @include desktop {
    display: grid;
    grid-template-columns: 40% minmax(20%, 1fr) repeat(2, minmax(20%, 1fr));
    padding: 16px 0 20px 0;
    border-bottom: 1px solid #E6E6EA;
    border-radius: 2px;
  }
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
  color: #333333;
}
