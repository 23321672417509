.productItem {
  cursor: pointer;
  display: grid;
  grid-template-columns: 15% minmax(20%, 3fr) 17%;
  padding: 16px 0 16px 16px;
  border-radius: 2px;
  border-bottom: 2px solid #E6E6EA;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
  color: #333333;

  &:hover {
    background: #F6F6F6;
    border-radius: 8px;
  }

  &_preview {
    width: 120px;
    height: 80px;
    object-fit: cover;
    border-radius: 6px;
  }

  &_option {
    display: flex;
    justify-content: space-between;

    &_price{
      white-space: nowrap;
    }

    &_button {
      display: flex;
      margin-right: 18px;

      .GlobalButton {
        padding: 6px 8px;
      }

      #edit {
        margin-right: 8px;

        svg path {
          fill: #FE6D04;
        }
      }

      #delete {
        border: 1px solid #FFB2B2;
        box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.2);
      }
    }
  }
}
