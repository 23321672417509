.comeBackList{
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 20px;
  background: #FFFFFF;
  box-shadow: -12px 0 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0 0 32px;
  padding: 28px 48px 40px 48px;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;

  &__header{
    display: flex;
    align-items: center;
    column-gap: 26px;

    h2{
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      color: #333333;
    }
  }
}
