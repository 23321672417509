@import 'src/mixin.scss';

.chatField {
  width: 100%;
  min-width: 400px;
  display: flex;
  min-height: 200px;
  flex-direction: column;
  align-items: center;
  background: #F6F6F6;
  border-bottom-left-radius: 32px;
  position: relative;

  @include mobile {
    min-width: 100%;
    border-bottom-left-radius: 0;
  }

  &__timer {
    position: absolute;
    top: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 28px;
    background: #2CBA5F;
    box-shadow: 0 4px 8px -4px rgba(18, 119, 86, 0.65);
    border-radius: 14px;

    &_text {
      color: #FFF;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.04em;
      margin-left: 5px;
    }
  }

  &__timer.timeLeft {
    background: #F54242;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: auto;
    border-radius: 10px 10px 16px 16px;

    &__customTemplateAttachmentPreview {
      position: absolute;
      opacity: 0.9;
      bottom: 32%;
    }

    & textarea, .chatFieldBlockedText {
      padding-top: 12px;
      padding-left: 16px;
      border: none;
      resize: none;
      outline: none;
      border-left: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12);
      font-family: Inter, sans-serif;
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;

      a {
        color: #FE6D04;
        text-decoration: underline;
      }
    }

    & .chatFieldBlockedText {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 108px;
      background: #EFEFF2;
      
      & > p {
        text-align: center;
        line-height: 24px;
        white-space: pre-line;
        
        @include mobile {
          flex-direction: column;
          align-items: center;
        }

        span {
          display: inline-flex;
          align-items: baseline;
          margin-left: 12px;

          svg {
            margin-right: 4px;
            align-self: center;
          }
        }
      }
    }

    .buttonsContainer {
      display: flex;
      justify-content: space-between;
      padding: 0 11px;
      min-height: 52px;
      flex-direction: row;
      -webkit-box-pack: justify;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-box-align: center;

      align-items: center;

      background: #F4F9FF;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      border-left: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      border-bottom: 1px solid #E0E0E0;
      box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.03);

      & > button, &__rightBlock > button {
        font-family: Inter, sans-serif;
        height: 32px;
        border-radius: 8px;
        border: none;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        text-align: center;
        cursor: pointer;
      }

      & > button.blocked, &__rightBlock > button.blocked {
        background: #E0E0E0;
        box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        color: #BDBDBD;
        cursor: url("../../../../assets/cursor.svg"), auto;
      }

      & > button.blocked svg path {
        fill: #BDBDBD;
      }

      &__leftBlock {
        display: flex;
        width: 100%;

        &_answerButton {
          display: flex;
          align-items: center;
          padding: 0 11px;
          font-weight: 400;
          background: #4D76B2;
          box-shadow: 0 4px 4px -2px rgba(0, 31, 142, 0.25);
          color: #FFFFFF;

          font-family: Inter, sans-serif;
          height: 32px;
          border-radius: 8px;
          border: none;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.005em;
          text-align: center;
          cursor: pointer;

          span {
            display: flex;
            width: max-content;
            flex-direction: row;
          }

          & > svg {
            margin-right: 9px;
          }

          &_text {
            display: none;
          }

          @include _1300 {
            span {
              display: none;
            }
            svg {
              margin-right: 0;
            }
          }

          @include mobile {
            span {
              display: none;
            }
            svg {
              margin-right: 0;
            }
          }
        }
      }

      &__rightBlock {
        display: flex;

        @include mobile {
          justify-content: space-between;
          width: 100%;

          .iconsAndDropdown {

            .dropdownTemplate {
              margin: 0 12px 0 20px;
            }
          }
        }

        .iconsAndDropdown {
          display: flex;
        }

        .iconsContainer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: relative;

          @include mobile {
            svg {
              margin-right: 16px;
            }
          }

          & input[type="file"] {
            display: none;
          }

          & > svg, label > svg {
            cursor: pointer;
          }

          & svg.blocked {
            cursor: url("../../../../assets/cursor.svg"), auto;

            & path {
              fill: #BDBDBD;

            }
          }

          .emoji-mart {
            position: absolute;
            left: 25px;
            bottom: 41px;
          }

          .emojiIcon {
            margin-left: 18px;
            margin-right: 18px;
          }

          &__recordGroup {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            min-width: 152px;
            height: 32px;
            background: #FFF;
            box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
            border-radius: 16px;

            &_stopButton, &_sendButton {
              border: none;
              outline: none;
              height: 17px;
              width: 17px;
              background: #FFF;
              cursor: pointer;
            }

            &_timerText {
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #333333;
            }

            &_recordGif {
              width: 24px;
              height: 24px;
            }
          }
        }

        .GlobalButton {
          padding: 6px 12px;
        }
      }
    }

    .popupWrapper {
      width: auto;
      height: auto;
      left: 30%;

      @include mobile {
        position: fixed;
        border-radius: 28px 28px 0 0;
        width: 100%;
        bottom: 0;
        left: 0;
        min-height: 368px;

        &__title {
          border-bottom: 1px solid #E0E0E0;
          border-radius: 1px;
          width: auto;
          height: auto;
          margin-left: 0;
          margin-top: 0;
          padding: 20px 24px;
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: -0.18px;
        }

        .preview__bottomBlock {
          flex-direction: column;
        }

        &__closeIcon {
          right: 24px;
          top: 24px;

          svg {
            width: 20px;
            height: 20px;

            path {
              fill: #333333;
            }
          }
        }
      }

      & > svg {
        display: none;
      }

      & .preview {

        &__fileContainer {
          width: auto;
          height: 100%;
          margin: 12px 16px 14px 16px;
          display: flex;
          justify-content: center;

          & > img {
            object-fit: contain;
            min-width: 100px;
            max-width: 400px;
          }

          & > video {
            width: 400px;

            @include mobile {
              width: 100%;
            }
          }

          & > p {
            font-weight: 600;
          }
        }

        &__bottomBlock {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 16px 14px 16px;

          & input {
            border: none;
            border-radius: 6px;
            background: #F4F9FF;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.20) inset;
            outline: none;
            font-family: Inter, sans-serif;
            font-style: normal;
            line-height: 20px;
            font-weight: 400;

            @include desktop {
              margin-right: 25px;
              padding: 8px 12px;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: -0.09px;
              font-feature-settings: 'cv01' on, 'cv02' on, 'cv09' on, 'cv05' on;
            }

            @include mobile {
              width: 100%;
              height: 58px;
              padding: 16px;
              margin-bottom: 24px;
              font-size: 18px;
              line-height: 20px;
              letter-spacing: -0.07px;
              font-feature-settings: 'cpsp' on, 'cv05' on, 'case' on, 'cv09' on, 'cv02' on, 'cv01' on;
            }
          }

          .GlobalButton {
            padding: 6px 12px;

            @include mobile {
              width: 100%;
              padding: 13px 16px 15px 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .chatField__container__buttonsContainer__rightBlock_dropdown {
    width: 50px;

    & > span {
      display: none;
    }
  }

}
