@import 'src/mixin.scss';

.comment {
  width: calc(100% + 46px);
  padding: 8px 12px;
  border-radius: 8px;
  background: #EAF3FF;

  .smallText.bold {
    margin-bottom: 2px;
  }

  .audioPlayer {
    display: flex;
    align-items: center;
    height: 42px;

    .waveSurferContainer{
      min-width: 200px;

      @include mobile {
        min-width: 120px;
      }
    }

    img {
      margin-right: 8px;
    }

    .customAudioPlayer__time {
      color: #333333;
      margin-right: 8px;
    }
  }
;
}
