@import 'src/mixin.scss';

.chat {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  position: relative;
  height: 100vh;
  border-radius: 32px 0 0 32px;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.2);
  background: #FFFFFF;
  z-index: 1;

  & .infinite-scroll-component__outerdiv {
    width: 100%;
  }
}
