@import "src/mixin.scss";
.shop {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  max-width: 552px;
  min-width: 350px;
  height: 136px;
  background: #F5F4F3;
  border: 1px solid #F2F2F2;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 24px 20px 24px 16px;
  position: relative;

  &__nameLink {
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    &_name {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
      white-space: nowrap;
      overflow: hidden;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 32px;
        height: 100%;
        right: 0;
        top: 0;
        background: linear-gradient(90deg, rgba(245, 244, 243, 0) 0%, rgba(245, 244, 243, 0.0086472) 6.67%, rgba(245, 244, 243, 0.03551) 13.33%, rgba(245, 244, 243, 0.0816599) 20%, rgba(245, 244, 243, 0.147411) 26.67%, rgba(245, 244, 243, 0.231775) 33.33%, rgba(245, 244, 243, 0.331884) 40%, rgba(245, 244, 243, 0.442691) 46.67%, rgba(245, 244, 243, 0.557309) 53.33%, rgba(245, 244, 243, 0.668116) 60%, rgba(245, 244, 243, 0.768225) 66.67%, rgba(245, 244, 243, 0.852589) 73.33%, rgba(245, 244, 243, 0.91834) 80%, rgba(245, 244, 243, 0.96449) 86.67%, rgba(245, 244, 243, 0.991353) 93.33%, #F5F4F3 100%);
      }
    }

    &__link {
      display: flex;
      column-gap: 10px;
      align-items: flex-start;

      &__copy{
        display: grid;
        word-break: break-all;
      }

      &__icon{
        cursor: pointer;

        & > svg {
          width: 18px;
          height: 18px;

          path {
            fill: #FE6D04;
          }
        }
      }

      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
    }
  }

  &__buttons {
    display: flex;
    align-self: flex-end;
    position: absolute;
    height: 40px;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    border-radius: 12px 0 0 0;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;

    & > button {
      cursor: pointer;
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      padding: 10px 16px;
  
      & > svg {
        margin-right: 6px;
      }
  
      &:first-of-type {
        border-radius: 12px 0 0 0;
      }
      &:last-of-type {
        border-bottom-right-radius: 12px;
      }
    }
  
    &_edit {
      color: #333333;
    }
  
    &_delete {
      color: #F54242;
    }

    &_edit, &_delete {
      span {
        @include _768 {
          display: none;
        }
      }
    }
  }
}
