.search {
  border: 0;
  position: relative;
  display: inline-flex;
  align-items: center;

  &.mobile {
    background: #FFFFFF;
    border-radius: 24px;
    border: 1px solid #E0E0E0;
    width: 48px;
    height: 48px;
  }

  &__input {
    width: 100%;
    height: 48px;
    outline: none;
    border: none;
    background: #FFFFFF;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 24px;
    padding-left: 40px;
    padding-right: 32px;
    font-family: Inter, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
  }

  &_icon {
    cursor: auto;
    position: absolute;
    left: 14px;
  }

  &_clear {
    cursor: pointer;
    height: 10px;
    width: 10px;
    position: absolute;
    right: 14px;
  }
}
