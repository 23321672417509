@import 'src/mixin.scss';

.dropdownChatPopup {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 16px;

  @include mobile {
    padding: 0 24px;
  }

  &_closeIcon {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 0;
    top: -23px;
  }

  .regularText, .smallText {
    margin-bottom: 4px;
    margin-top: 10px;

    @include mobile {
      margin-bottom: 12px;
    }
  }

  &_currentConnection {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    height: 36px;
    background: #F4F9FF;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: #333333;

    @include mobile {
      height: 56px;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.09px;

      svg {
        top: auto;
      }
    }

    &.disabled {
      color: #828282;

      & > svg path {
        fill: #828282;
      }
    }

    &__arrowIcon {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 15px;
      height: 15px;

      &.active {
        transform: rotateX(180deg);
      }
    }
    
    &__icon {
      display: flex;
      
      svg {
        margin-left: 10px;
        margin-right: 0;
        width: 24px;
        height: 24px;
      }
    }

    &__number {
      margin-left: 10px;
    }
  }

  &_dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow: auto;
    max-height: 200px;
    background: #F4F9FF;
    box-shadow: 0 6px 11px rgba(125, 123, 123, 0.04);
    border-radius: 4px;

    &__item {
      position: relative;
      display: flex;
      padding: 9px;
      text-align: left;
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.04em;
      color: #333333;

      &-phone {
        overflow: hidden;
        width: 100%;
      }

      &:hover {
        background: #FFE1CC;
        border-radius: 6px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 5px;
        bottom: 0;
        width: 17px;
        height: 30px;
        right: 7px;
        background: linear-gradient(
            90deg, rgba(244, 249, 255, 0) 0%, rgba(244, 249, 255, 0.0086472) 6.67%, rgba(244, 249, 255, 0.03551) 13.33%, rgba(244, 249, 255, 0.0816599) 20%, rgba(244, 249, 255, 0.147411) 26.67%, rgba(244, 249, 255, 0.231775) 33.33%, rgba(244, 249, 255, 0.331884) 40%, rgba(244, 249, 255, 0.442691) 46.67%, rgba(244, 249, 255, 0.557309) 53.33%, rgba(244, 249, 255, 0.668116) 60%, rgba(244, 249, 255, 0.768225) 66.67%, rgba(244, 249, 255, 0.852589) 73.33%, rgba(244, 249, 255, 0.91834) 80%, rgba(244, 249, 255, 0.96449) 86.67%, rgba(244, 249, 255, 0.991353) 93.33%, #F5F4F3 100%);
      }

      &:hover::after {
        background: linear-gradient(
            90deg, rgba(255, 225, 204, 0) 0%, rgba(255, 225, 204, 0.0086472) 6.67%, rgba(255, 225, 204, 0.03551) 13.33%, rgba(255, 225, 204, 0.0816599) 20%, rgba(255, 225, 204, 0.147411) 26.67%, rgba(255, 225, 204, 0.231775) 33.33%, rgba(255, 225, 204, 0.331884) 40%, rgba(255, 225, 204, 0.442691) 46.67%, rgba(255, 225, 204, 0.557309) 53.33%, rgba(255, 225, 204, 0.668116) 60%, rgba(255, 225, 204, 0.768225) 66.67%, rgba(255, 225, 204, 0.852589) 73.33%, rgba(255, 225, 204, 0.91834) 80%, rgba(255, 225, 204, 0.96449) 86.67%, rgba(255, 225, 204, 0.991353) 93.33%, #FFE1CC 100%);
      }

      & svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }
}
