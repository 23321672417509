.TelegramCreateConnectionView {
  display: flex;
  flex-direction: column;
  max-width: 780px;

  & .inputContainer {

    & > .inputContainer__wrapper {
      width: 400px;
  
      input {
        width: 400px;
      }
    }
  }

  .GlobalButton {
    padding: 14px 16px;
  }

  .GlobalButton:last-child {
    margin-top: 40px;
  }

  &__phone {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    
    &_lable {
      display: inline-flex;
      position: relative;
      margin-bottom: 12px;

      & > p {
        font-size: 18px;
        font-weight: 500;
      }

      & > span {
        display: inline-flex;
        align-items: center;
      }
      
      svg {
        width: 16px;
        height: 16px;
        margin-left: 12px;
      }

      & > .info__message {
        display: none;
        position: absolute;
        width: auto;
        top: -60px;
        margin-left: 20px;
        padding: 20px 25px;
        background: #FFFFFF;
        box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);
        border-radius: 12px;
        z-index: 22;
      }
      & >.info__icon:hover ~.info__message {
        display: block;
      }
    }

    .phoneField {

      .PhoneInput {
        height: 56px;
        width: 300px
      }
    }
  }

  &__blockCode {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    & > p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on;
    }

    &__code {
      display: flex;
      align-items: flex-end;
      margin-top: 16px;

      & .inputContainer {
        margin-bottom: 0;

        & > .inputContainer__wrapper {
          width: 212px;
      
          input {
            width: 212px;
          }
        }
      }

      .GlobalButton {
        margin: 1px 0 1px 24px;
      }
    }
  }

  &__blockPassword {
    display: flex;
    flex-direction: column;
    max-width: 460px;
    margin-top: 40px;

    & > p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on;
    }

    & .inputContainer {
      margin-top: 16px;
      margin-bottom: 0;
    }
  }
}
