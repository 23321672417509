.catalogsList {
  position: relative;
  height: calc(100vh - 140px);
  overflow-y: auto;
  display: grid;
  grid-template-rows: repeat(auto-fit, 90px);
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  column-gap: 24px;
  row-gap: 24px;
  padding-right: 48px;
}