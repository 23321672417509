@import 'src/mixin.scss';

.wabaCartContent {
  display: inline-flex;
  width: 400px;

  @include mobile {
    width: 100%;
  }

  .cart {
    margin-right: 12px;
  }

  &__container {
    width: 100%;

    &.open {

      .wabaCartContent__buttons {
        margin-bottom: 6px;

        &.oneButton {
          justify-content: flex-end;
        }
      }

      .comment {
        margin-bottom: 16px;
      }

      .rolledAmount {
        margin-bottom: 16px;
      }
    }
  }

  &__content {
    width: 100%;

    .regularText.bold {
      margin-bottom: 4px;
    }

    .listProducts {
      width: calc(100% + 46px);
      margin-bottom: 16px;

      .total {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.54px;

        &_text {
          margin-right: 16px;
          letter-spacing: -0.09px;
        }
      }
    }

    .rolledAmount {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: calc(100% + 46px);

      @include mobile {
        gap: 8px;
        flex-direction: column;
        align-items: flex-start;
      }

      .GlobalButton {
        padding: 6px 12px 6px 8px;

        svg {
          transform: rotate(-90deg);

          path {
            fill: #FA822C;
          }
        }
      }
    }

    .wabaProduct {
      display: flex;
      border-bottom: 1px #BDBDBD solid;
      padding-bottom: 12px;
      padding-top: 12px;

      &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 64px;
        min-width: 64px;
        height: 64px;
        margin-right: 12px;

        &.plug {
          border-radius: 8px;
          background: #FFFFFF;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__content {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        width: 100%;

        &_price {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }
      }
    }
  }

  .comment {
    margin-top: 16px;

    .smallText {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: calc(100% + 46px);

    @include mobile {
      flex-direction: column;
      gap: 16px;
    }

    .GlobalButton {
      padding: 6px 12px 6px 8px;

      &:last-child {

        svg {
          transform: rotate(-90deg);

          path {
            fill: #FA822C;
          }
        }
      }

      svg path {
        fill: #FFFFFF;
      }

      &.open svg {
        transform: rotate(90deg);
      }
    }
  }
}
