.BePaidEditContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > button {
    margin-top: 40px;
    padding: 14px 16px 15px;
  }

  &_input {
    margin-top: 24px;

    input {
      width: 400px;
    }
  }
}

