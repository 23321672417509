.customTemplates {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    padding-right: 48px;

    &_title {
      display: flex;
      justify-content: space-between;

      .GlobalButton {
        padding: 15px;
      }
    }

    & .search {
      margin-top: 24px;
      &__input {
        width: 405px;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
        border: 1px solid #E0E0E0;
        box-shadow: inset 0 2px 4px -2px rgba(0, 0, 0, 0.3);
        border-radius: 24px;
      }
    }
  }

 &__content {
    display: flex;
    flex-direction: column;
    padding-right: 48px;
    overflow-y: scroll;
  }
}
