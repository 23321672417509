.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  .tagsItem {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.40);
    border-radius: 6px;
    gap: 8px;

    svg {
      cursor: pointer;
      width: 10px;
    }
  }
}
