.optionItem {
  display: grid;
  grid-template-columns: 100px auto 424px 50px;
  column-gap: 16px;
  padding: 12px 6px;
  align-content: center;
  align-items: center;
  border-bottom: 2px #E6E6EA solid;

  &.hide {
    grid-template-columns: auto 424px 50px;
  }

  .GlobalButton.small.white {
    padding: 8px;
    border: 1px solid #ffb3b3;
    align-self: center;
    justify-self: flex-end;
  }
}