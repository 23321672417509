.filePayloadContent {

  & .removeActionButton {
    position: absolute;
    right: 20px;
    bottom: 10px;
  }

  &__attachmentPreview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 170px;
  }

  &__dropzone {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 170px;
    background: #F9F9F9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    &.error {
      border: 1px solid red;
    }

    &_errorMessage {
      margin-top: 12px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      color: #F54242;
      margin-bottom: 20px;
    }

    &_input {
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &_text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #333333;

      & svg {
        margin-bottom: 23px;
      }

      & > p {
        margin-bottom: 8px;
      }
    }
  }
}





