.odnoklassnikiConnectionGroup {
  display: grid;
  grid-template-rows: auto auto 1fr;
  row-gap: 20px;
  max-width: 910px;
  padding-right: 48px;

  &__title {
    h1 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      color: #333333;
    }
  }

  &__api {
    display: grid;
    grid-template-rows: auto auto 56px;
    row-gap: 16px;

    .GlobalButton{
      padding: 13px 16px;
    }

    h3 {
      margin-top: 8px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      color: #333333;
    }
  }
  &__text {
    margin-top: 36px;
    display: grid;
    row-gap: 12px;
    
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #333333;
    font-feature-settings: 'cv01' on, 'cv02' on, 'cv09' on, 'cv05' on;

    li {
      margin-bottom: 16px;
    }

    svg {
      flex-shrink: 0;
    }

    h3 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on,
        'cpsp' on, 'cv08' on;
      color: #333333;
    }

    &__item {
      display: grid;
      grid-template-columns: 25px auto;
      align-items: start;
    }
  }
}
