.listAction {
  border: 1px solid #E6E6EA;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 12px 20px 20px 20px;
  margin-bottom: 24px;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    &_title {
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.01em;
      color: #333333;
    }
  }
}


