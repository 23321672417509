@import 'src/mixin.scss';

.settingsContainer {
  display: flex;
  overflow: hidden;
  background: #F6F6F6;
  box-shadow: -12px 0 48px -24px rgba(0, 0, 0, 0.2);
  z-index: 2;

  @include mobile {
    overflow: visible;
  }
}

.mainContainer {
  width: 100vw;
  background: #FFFFFF;
  overflow: hidden;

  @include desktop {
    box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.2);
    border-radius: 32px 0 0 32px;
  }
}

.mainContent {
  overflow-y: auto;
  height: 100%;

  @include desktop {
    padding: 32px 0 32px 48px;
  }
  @include mobile {
    padding: 20px;
  }

  p {
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    @include mobile {
      overflow-wrap: break-word;
    }
  }

  h1 {
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 16px;
  }

  h3 {
    margin-bottom: 12px;
  }

  h4 {
    margin-bottom: 12px;
  }

  input {
    background: #F4F9FF;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border: none;
    height: 56px;
    color: #333333;
    padding-left: 16px;
    padding-right: 12px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
  }
}
