.itemChangeAt {
  display: flex;
  justify-content: space-between;
  
  &__content {
    flex-direction: column;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
  }

  &__name {
    white-space: break-spaces;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
  }
  
  &__date {
    letter-spacing: -0.03em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  }

  &__buttons {
    display: flex;

    .GlobalButton {
      padding: 6px 8px;
    }

    #edit {
      margin-right: 8px;
      svg path {
        fill: #FE6D04;
      }
    }

    #delete {
      border: 1px solid #FFB2B2;
      box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.2);
    }
  }
}

.itemChangeAt > * + * {
  margin-top: 8px;
}
