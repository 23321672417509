.filePreview {
  height: 56px;
  display: flex;
  align-items: center;

  &.document {
    height: auto;
  }

  img {
    height: 100%;
    object-fit: contain;
  }
}

.videoPreview {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 56px;

  & > video {
    height: 100%;
    width: auto;
    object-fit: cover;
  }

  & .video-controls {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    &__pauseIcon {
      width: 32px;
      height: 32px;
      opacity: 0;
      transition: opacity 0.4s;
      cursor: pointer;

      &.active {
        opacity: 1;
      }
    }

    & > img {
      cursor: pointer;
    }
  }
}
