.itemConnection {
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 12px 8px 8px;
  width: 170px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  & > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover::after {
    content: attr(data-info);
    display: flex;
    position: absolute;
    top: -30%;
    left: 90%;
    width: fit-content;
    white-space: nowrap;
    padding: 4px 8px;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    color: #333333;
  }
}

.itemConnection > * + * {
  margin-left: 4px;
}
