.blackListContent {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  max-width: 400px;

  &_title {
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 8px;
    border-bottom: 2px solid #E6E6EA;
  }

  &_empty {
    margin-top: 10px;
  }

  &_more {
    cursor: pointer;
    margin-top: 20px;
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
    color: #FE6D04;
  }
}