.interactiveMessages {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    &_title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .GlobalButton {
        padding: 15px;
      }
    }

    &_bottom {
      display: flex;
      align-items: center;
      
      & .search {

        &__input {
          background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
          border: 1px solid #E0E0E0;
          box-shadow: inset 0 2px 4px -2px rgba(0, 0, 0, 0.3);
          border-radius: 24px;
        }
      }
    }

    &_bottom > * + * {
      margin-left: 24px;
    }
  }

  &__templates {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
}

.interactiveMessages > * {
  padding-right: 48px;
}
