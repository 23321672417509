@import 'src/mixin.scss';

.conversationList {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  background: #F6F6F6;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @include mobile {
    border-top: 1px solid #E0E0E0;
  }
}

.nothingToShow {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 10%;
  text-align: center;
  background-color: #F6F6F6;
}
