.templateView {
  width: 418px;
  padding: 10px 12px;
  background: #F5F4F3;
  border: 1px solid #F2F2F2;
  border-radius: 8px;

  input {
    width: 100px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: -0.0005em;
    color: #333333;
    padding-left: 7px;
    padding-right: 7px;
  }

  &.error {
    border: 3px solid #F54242;
    border-radius: 10px;
    padding: 4px 4px 0 4px;
  }
}
