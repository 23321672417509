.noneProducts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: -12px 0 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0 0 32px;
  width: 100vw;
  height: 100vh;
  padding: 32px 48px 46px 48px;
  z-index: 2;

  &_text {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      margin-bottom: 8px;
    }
  }

  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cv08' on, 'case' on;
    color: #333333;
  }

  img {
    width: 400px;
    height: 400px;
  }

  .GlobalButton {
    margin-top: 56px;
    padding: 13px 42px 15px;
  }
}

.products {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-areas: 'header' 'list' 'bottom';
  grid-template-rows: 0fr 1fr 50px;
  background: #FFFFFF;
  box-shadow: -12px 0 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0 0 32px;
  padding: 32px 48px 48px 48px;
  z-index: 2;

  &__header {
    display: grid;
    grid-area: header;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;

    &_right {
      display: grid;
      grid-column-end: none;
      white-space: nowrap;
      justify-items: flex-end;
      grid-template-columns: 2fr 1fr;

      .search {
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-shadow: inset 0 2px 4px -2px rgba(0, 0, 0, 0.3);
        border-radius: 24px;
        height: 48px;
        margin-right: 16px;
      }

      .GlobalButton {
        padding: 13px 16px 15px;
      }
    }
  }

  & > .load {
    display: grid;
    grid-area: list;
  }

  &__bottom {
    display: grid;
    grid-area: bottom;
    padding-right: 48px;
  }
}
