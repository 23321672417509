.connectionNameEdit {
  display: flex;

  & > form input {
    outline: none;
    border: none;
    margin-bottom: 8px;
  }
  
  &__buttonsWrapper {
    padding: 10px 16px 10px 16px;
    display: flex;
    font-size: 18px;
    background: #FFFFFF;
    border-radius: 12px 0 0 0;
    align-self: flex-end;

    &_edit, &_cancel {
      cursor: pointer;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      font-family: Inter, sans-serif;
      font-size: 14px;

      & > svg {
        margin-right: 6px;
      }
    }

    &_edit {
      color: #333333;
      margin-right: 15px;
    }

    &_edit:disabled {
      color: #d9d0d0;

      & > svg path {
        fill: #d9d0d0;
      }
    }

    &_cancel {
      color: #F54242;
    }
  }
}
