.broadcastsForm {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  padding: 32px 0 32px 48px;
  background-color: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0 0 32px;
  position: relative;

  & h3 {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
    margin-bottom: 20px;
  }

  & h4 {
    margin-bottom: 10px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    color: #333333;
  }

  & input {
    width: 648px;
    padding-left: 16px;
    padding-right: 12px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    color: #333333;
  }

  & .switchTabs {
    height: 28px;
    background: #E7E7E7;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    & > button {
      width: 209px;
      height: 28px;
      margin-right: 0;
      padding: 4px 16px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;

      &.false {
        background: #E7E7E7;
      }
    }
  }

  & .dropdownContainer {
    width: 648px;

    &__dropdown_content__item_logo {
      width: 32px;
      height: 32px;
    }

    &__dropdown_logo {
      width: 32px;
      height: 32px;
      top: 12px;
      left: 12px;
    }

    .logo {
      padding-left: 32px;
    }
  }

  .conditionsForm, .delayForm, .broadcastTest {
    width: 648px;
  }

  &__duration {
    display: flex;
    flex-direction: column;

    & > p {
      font-weight: 700;
      font-size: 24px;
      line-height: 44px;
      letter-spacing: -0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      color: #333333;
    }
  }

  &__buttons {
    display: flex;
    margin-top: 72px;

    .GlobalButton {
      padding: 13px 16px 15px;
    }
    .GlobalButton:first-of-type {
      margin-right: 24px;
    }

    &.check {
      .GlobalButton:first-of-type {
        pointer-events: none;
      }
    }
  }
}

.broadcastsForm__content {
  height: calc(100vh - 128px);
  overflow-y: scroll;
}

.broadcastsForm__content form > * + div {
  margin-bottom: 24px;
}
