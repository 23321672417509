.createEditShop {
  display: grid;
  grid-template-rows: 1fr 52px;
  height: 100%;

  &__form {
    display: grid;
    align-content: start;

    &__list {
      display: grid;

      max-width: 648px;
      min-width: 400px;

      &__name {
        width: 100%;
        margin-bottom: 24px;

        &_error {
          margin-top: 8px;
          color: #F54242
        }

        &_title {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 12px;

          &.error {
            color: #F54242
          }
        }

        &_input {
          padding: 16px;
          width: 100%;
          background: #F4F9FF;
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
          border-radius: 6px;
          outline: none;
          border: none;

          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.005em;
          font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
          color: #333333;

          &.error {
            border: 3px solid #F54242
          }
        }

      }
    }
  }

  &__bottom {
    display: flex;

    &.clicked {
      pointer-events: none;
    }
  }
}
