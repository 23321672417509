.messageNotification {
  display: flex;
  align-items: center;
  position: absolute;
  top: 24px;
  right: 16px;
  width: 395px;
  height: 80px;
  background: #F6F6F6;
  box-shadow: 0 12px 48px -16px rgba(9, 19, 33, 0.65);
  border-radius: 12px;
  z-index: 55555;
  cursor: pointer;

  &__avatar {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a3a3db;
    margin: 16px;
  }

  &__description {
    display: flex;
    color: #333333;
    border-right: solid 1px #E5E5EB;

    &_time {
      display: flex;
      align-items: center;
      height: 24px;
      margin-left: 15px;
      margin-right: 12px;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.04em;
      color: #4F4F4F;
    }
  }

  &__userInfo {
    display: flex;
    flex-direction: column;
    color: #333333;

    &_name, &_text {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 32px;
        height: 30px;
        right: 0;
        background: linear-gradient(90deg, rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0.0086472) 6.81%, rgba(246, 246, 246, 0.03551) 13.61%, rgba(246, 246, 246, 0.0816599) 20.42%, rgba(246, 246, 246, 0.147411) 27.22%, rgba(246, 246, 246, 0.231775) 34.03%, rgba(246, 246, 246, 0.331884) 40.83%, rgba(246, 246, 246, 0.442691) 47.64%, rgba(246, 246, 246, 0.557309) 54.44%, rgba(246, 246, 246, 0.668116) 61.25%, rgba(246, 246, 246, 0.768225) 68.06%, rgba(246, 246, 246, 0.852589) 74.86%, rgba(246, 246, 246, 0.91834) 81.67%, rgba(246, 246, 246, 0.96449) 88.47%, rgba(246, 246, 246, 0.991353) 95.28%, #F6F6F6 102.08%);
      }
    }

    &_name {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      width: 208px;
      height: 24px;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
    }

    &_text {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      max-width: 208px;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
    }
  }

  &__closeIcon {
    margin-left: 15px;
    cursor: pointer;
  }
}
