.secondStep {
  color: #333333;

  &__title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  &__description {
    letter-spacing: -0.005em;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__more {
    font-size: 14px;
    color: #FE6D04;
  }
}



