.copyLink {
  display: flex;
  flex-flow: column;

  & h2, p {
    margin-bottom: 8px;
  }

  & input {
    display: inline-flex;
    height: 56px;
    padding: 16px;
    outline: none;
    border: none;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 6px;
    background: #F4F9FF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.20) inset;

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }

  &__buttons {
    display: flex;
    justify-content: space-between ;

    .GlobalButton {
      display: flex;
      align-self: center;
      padding: 13px 16px 15px 12px;
      margin-top: 20px;

      & > svg {
        & path {
          fill: #FFFFFF;
        }
      }
    }
  }
}
