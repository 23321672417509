@import 'src/mixin.scss';

.imagePreviewContainer {
  position: relative;
  background: #E5E5EB;
  border-radius: 0 8px 0 0;
  gap: 8px;
  padding: 6px 6px 6px 12px;
  display: flex;

  @include mobile {
    margin: 0;

    svg {
      width: 16px;
      height: 16px;
      margin: 0;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    display: flex;

    @include mobile {
      margin: 0;
    }

    & > img {
      object-fit: cover;
    }
  }

  &__trashIcon {
    width: 14px;
    height: 14px;
  }
}

