.connectionAddon {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-top: 12px;

  &_arrowIcon {
    position: absolute;
    top: 18px;
    right: 15px;
    cursor: pointer;

    &.active {
      transform: rotateX(180deg);
    }
  }
}