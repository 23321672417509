.log_list__item {
  cursor: pointer;
}

.log_list__item:hover {
  background: #F6F6F6;
  border-radius: 8px;
}

.hidden {
  display: none;
}
