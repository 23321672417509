.telegramEdit {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 128px);
  padding-right: 48px;
  color: #333333;

  & > p {
    margin-bottom: 12px;
  }

  & .inputContainer {
    margin-top: 20px;
  }

  & .inputContainer__input {
    width: 600px;
  }

  &__footer {
    margin-top: 40px;

    & > .GlobalButton {
      padding: 14px 16px;
    }
  }

  &__footer.clicked {
    pointer-events: none;
  }
}
