.shops {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: -12px 0 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0 0 32px;
  padding: 28px 48px 40px 48px;
  width: 100vw;
  height: 100vh;
  align-items: flex-start;
  position: relative;
  overflow-y: hidden;

  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    & > .GlobalButton {
      padding: 13px 16px;
    }
  }

  &__content {
    display: grid;
    grid-template-rows: repeat(auto-fit, 136px);
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    column-gap: 24px;
    row-gap: 24px;
    position: relative;
    width: 100%;
    height: calc(100vh - 140px);
    overflow-y: auto;
    padding-right: 48px;
  }

  &__information {
    display: flex;
    margin-top: 32px;

    a {
      text-decoration-line: underline;
      color: #FE6D04;
    }
  }

  &__information > * + * {
    margin-left: 8px;
  }
}
