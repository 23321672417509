.blackListItem {
  display: flex;
  align-items: center;
  margin-top: 20px;
  border-bottom: 2px solid #E6E6EA;
  padding-bottom: 15px;
  justify-content: space-between;

  & > p {
    font-size: 18px;
    margin-left: 5px;
  }

  & > button {
    padding: 6px 8px 6px 8px;
    font-weight: normal !important;
  }
}
