.templatesContent {
  display: flex;
  flex-grow: 1;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  padding: 32px 0 32px 48px;
  border-radius: 32px 0 0 32px;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
  z-index: 1;
}
