.notifications {
  overflow-y: auto;
  padding-right: 48px;
  position: relative;

  &__add {
    & >.GlobalButton {
      padding: 6px 12px 6px 8px;
      margin-top: 16px;
    }
  }
}

.NotificationsList {
  margin-top: 24px;
}
