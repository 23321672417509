
.ChatItemContact {
  display: grid;
  grid-template-columns: 36px 1fr;
  padding: 6px 12px 10px 8px;
  margin: 5px;
  width: 180px;
  height: 52px;
  background: #FFFFFF;
  cursor: pointer;
  border: 1px solid #F2F2F2;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  gap: 10px;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.02em;
  position: relative;
  overflow: hidden;
  white-space: nowrap;

  .phoneText {
    color: #333333;
    font-feature-settings: 'cv08' on, 'cpsp' on, 'case' on, 'cv05' on, 'cv02' on, 'cv01' on, 'cv09' on;
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: -0.2px;
  }

  &:after {
    /* hide */
    content: "";
    position: absolute;
    width: 32px;
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, white 100%);
  }

  &:hover {
    background: #E0E0E0;
  }
  &:hover:after {
    background: linear-gradient(90deg, rgba(224, 224, 224, 0) 0%, rgba(224, 224, 224, 0.0086472) 6.67%, rgba(224, 224, 224, 0.03551) 13.33%, rgba(224, 224, 224, 0.0816599) 20%, rgba(224, 224, 224, 0.147411) 26.67%, rgba(224, 224, 224, 0.231775) 33.33%, rgba(224, 224, 224, 0.331884) 40%, rgba(224, 224, 224, 0.442691) 46.67%, rgba(224, 224, 224, 0.557309) 53.33%, rgba(224, 224, 224, 0.668116) 60%, rgba(224, 224, 224, 0.768225) 66.67%, rgba(224, 224, 224, 0.852589) 73.33%, rgba(224, 224, 224, 0.91834) 80%, rgba(224, 224, 224, 0.96449) 86.67%, rgba(224, 224, 224, 0.991353) 93.33%, #E0E0E0 100%);
  }
  svg {
    width: 24px;
    height: 24px;
    margin: 5px 0 0 6px;
  }
}
