.catalogsChooseProductCard {
  display: flex;
  margin-bottom: 24px;

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 56px;
    border-radius: 2px;
    margin: 0 16px 0 40px;
    background-color: #DADADA;

    & > img {
      width: 84px;
      height: 56px;
      border-radius: 2px;
      object-fit: contain;
      background-color: white;
    }

    & > svg {
      width: 33px;
      height: 28px;
    }

    & > svg path {
      fill: #BDBDBD;
    }
  }

  &__select {
    margin-top: 3px;
  }

  &__about {
    width: 340px;
    font-family: Inter, sans-serif;
    font-style: normal;
    color: #333333;
  }

  &__name {
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    margin-bottom: 8px;
  }

  &__price {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.03em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  }
}
