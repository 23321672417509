@import 'src/mixin.scss';

.popupWrapper {
  position: absolute;
  z-index: 10;
  background: #FFFFFF;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 06.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 12px;
  transition: opacity 0.5ms;
  border: 1px solid #EBEBEB;

  &.top {
    bottom: 48px;
  }

  &.bottom {
    top: 57px;
    left: 0;
  }

  &__title {
    text-align: left;
    position: relative;
    overflow: hidden;
    white-space: pre-wrap;
    padding: 12px 0 0 16px;

    h3 {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        width: 34px;
        height: 30px;
        right: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, #FFFFFF 100%);

      }
    }
  }

  &__closeIcon {
    cursor: pointer;
    display: flex;
    right: 11px;
    position: absolute;
    top: 10px;
  }

  &__triangleTail {
    width: 12px;
    height: 6px;
    position: absolute;
    left: 24px;

    &.bottom {
      top: -6px;
      transform: rotate(180deg);
    }
  }

  .createCompany {
    .GlobalButton {
      padding: 6px;
      margin-bottom: 16px;
      margin-left: 10px;

      svg {
        width: 12px;
        height: 12px;

        path {
          fill: #FE6D04;
        }
      }
    }
  }
}

