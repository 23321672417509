.dropdownWithCheckbox {
  display: grid;
  grid-template-rows: 24px minmax(56px, auto);
  row-gap: 12px;

  &_label {
    color: #333333;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.09px;
    font-feature-settings: 'cpsp' on, 'cv05' on, 'cv02' on, 'cv01' on, 'cv09' on;
  }

  &__dropdown {
    width: 440px;
    height: 56px;
    color: #828282;
    border-radius: 6px;
    background: #f4f9ff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) inset;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    position: relative;

    .arrow {
      z-index: 1000;
      position: absolute;
      width: 12px;
      cursor: pointer;
      border-radius: 6px;
      right: 14px;
      transition-duration: 300ms;
      transform: rotateX(0);

      &.open {
        transition-duration: 300ms;
        transform: rotateX(180deg);
      }
    }

    &:after {
      content: '';
      width: 48px;
      height: 52px;
      right: 16px;
      top: 2px;
      position: absolute;
      background: linear-gradient(90deg, rgba(244, 249, 255, 0.00) 0%, rgba(244, 249, 255, 0.01) 6.67%, rgba(244, 249, 255, 0.04) 13.33%, rgba(244, 249, 255, 0.08) 20.00%, rgba(244, 249, 255, 0.15) 26.67%, rgba(244, 249, 255, 0.23) 33.33%, rgba(244, 249, 255, 0.33) 40.00%, rgba(244, 249, 255, 0.44) 46.67%, rgba(244, 249, 255, 0.56) 53.33%, rgba(244, 249, 255, 0.67) 60.00%, rgba(244, 249, 255, 0.77) 66.67%, rgba(244, 249, 255, 0.85) 73.33%, rgba(244, 249, 255, 0.92) 80.00%, rgba(244, 249, 255, 0.96) 86.67%, rgba(244, 249, 255, 0.99) 93.33%, #F4F9FF 100%);
    }

    .previewTags {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      column-gap: 4px;
      overflow: hidden;

      .tagPreview {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
        white-space: nowrap;
        padding: 4px 8px;
        gap: 8px;
        color: #333333;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.07px;
        font-feature-settings: 'cpsp' on, 'case' on, 'cv05' on, 'cv02' on, 'cv01' on, 'cv09' on;

        .close {
          width: 14px;
          height: 14px;
          cursor: pointer;
        }
      }
    }
  }

  &__content {
    z-index: 2222;
    width: 440px;
    max-height: 300px;
    overflow-x: scroll;
    background: #f4f9ff;
    position: absolute;
    top: 64px;
    right: 0;
    box-shadow: 0 6px 11px rgba(125, 123, 123, 0.04);
    border-radius: 4px;
    padding: 8px 0 8px 5px;

    &_item {
      color: #333333;
    }
  }

  .dropTag {
    display: flex;
    width: 100%;
    cursor: pointer;
    padding: 8px 10px 10px 10px;
    margin-bottom: 5px;

    &.active {
      border-radius: 6px;
      background: #ffe1cc;
    }

    &:hover {
      border-radius: 6px;
      background: #ffe1cc;
    }

    .checkbox {
      align-self: center;
      margin-bottom: 0;

      input {
        width: auto;
        height: auto;
        position: relative;
        margin-right: 16px;
      }
    }

    .tag {
      color: #333333;
      padding: 4px 8px;
      border-radius: 6px;
      box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
    }
  }
}
