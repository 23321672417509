.cardButtons {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;

  & > button {
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    padding: 10px 16px;

    & > svg {
      margin-right: 6px;
    }

    &:first-of-type {
      border-radius: 12px 0 0 0;
    }
    &:last-of-type {
      border-bottom-right-radius: 12px;
    }
  }

  &_editButton {
    color: #333333;
  }

  &_deleteButton {
    color: #F54242;
  }
}
