.container {
  position: absolute;
  top: 0;
  left: 0;
  background: #333333;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  z-index: 101;
}

.profileInvoice {
  opacity: 1;
  display: flex;
  justify-content: space-between;
  position: fixed;
  padding: 32px 24px 32px 48px;
  width: 40%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 105;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0 0 32px;
  overflow-y: scroll;
  transform: translateX(100%);
  animation: ani 0.8s forwards;

  .terminalAndLogo {
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  h6 {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
    margin-bottom: 4px;
  }

  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }

  &__left {
    width: 100%;

    & > div {
      margin-bottom: 16px;
    }

    &_container {
      display: flex;
      justify-content: space-between;

      &_left {

        &_date {
          margin-bottom: 16px;
        }
      }

      &_right, &_right_amount, &_right_status {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      &_right_amount {
        margin-bottom: 16px;
        white-space: nowrap;
      }

      &_right_amount p {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: right;
        letter-spacing: -0.03em;
        font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #333333;
      }

      &_right_status p {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: right;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      }
    }

    &_url {
      overflow-wrap: anywhere;

      p {
        color: #FE6D04;
      }
    }

    &_products {
      display: grid;
      margin-top: 32px;

      h4 {
        margin-bottom: 16px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
        color: #333333;
      }

      &_title {
        display: grid;
        grid-template-columns: 40% minmax(10%, 1fr) repeat(1, minmax(10%, 1fr));
        border-bottom: 2px #E6E6EA solid;
        padding-bottom: 12px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #333333;

        &_count, &_price {
          display: grid;
          justify-content: flex-end;
        }
      }

      &_list {
        overflow-y: scroll;
      }
    }
  }

  &__right {

    img {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
}
