.subscriptionStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  padding: 4px 8px 4px 6px;
  white-space: nowrap;

  &.paid, &.warn {
    background: #E6F9D4;
    box-shadow: 0 2px 2px -2px rgba(62, 114, 92, 0.4);
    p{
      color: #006416;
    }
  }

  &.expired, &.unpaid {
    background: #FABBBC;
    box-shadow: 0 2px 2px -2px rgba(96, 0, 0, 0.4);
    p{
      color: #6B1616;
    }
  }

  &.unknown, &.paused {
    background: #FFFFFF;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
    p{
      color: #333333;;
    }
  }

  &.test, &.testEnd {
    background: #FFD5AB;
    box-shadow: 0 2px 2px -2px rgba(168, 81, 0, 0.4);
    p{
      color: #5E3E00;
    }
  }

  img {
    margin-right: 8px;
  }

  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  }
}
