.notificationBadge {
  height: 21px;
  min-width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2CBA5F;
  box-shadow: 0 2px 2px -2px rgba(0, 104, 37, 0.25);
  border-radius: 12px;

  & > span {
    padding: 2px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    text-align: right;
    letter-spacing: -0.04em;
  }
}

 
