.conversationChatsList {
  padding: 10px 20px 0 79px;
  cursor: pointer;
  position: relative;

  &.active {
    background: #FFFFFF;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.12);
    border-radius: 4px 0 0 4px;
    z-index: 7;
    padding-top: 10px;
    margin-top: -1px;

    &::after {
      content: url("../../../../assets/bottom corner.svg");
      width: 8px;
      height: 8px;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &::before {
      content: url("../../../../assets/top corner.svg");
      width: 8px;
      height: 8px;
      position: absolute;
      top: -15px;
      right: 0;
    }

    & .conversationChatsList__header {
      border-bottom: none;

      &_info__name::after {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.81%, rgba(255, 255, 255, 0.03551) 13.61%, rgba(255, 255, 255, 0.0816599) 20.42%, rgba(255, 255, 255, 0.147411) 27.22%, rgba(255, 255, 255, 0.231775) 34.03%, rgba(255, 255, 255, 0.331884) 40.83%, rgba(255, 255, 255, 0.442691) 47.64%, rgba(255, 255, 255, 0.557309) 54.44%, rgba(255, 255, 255, 0.668116) 61.25%, rgba(255, 255, 255, 0.768225) 68.06%, rgba(255, 255, 255, 0.852589) 74.86%, rgba(255, 255, 255, 0.91834) 81.67%, rgba(255, 255, 255, 0.96449) 88.47%, rgba(255, 255, 255, 0.991353) 95.28%, #FFFFFF 102.08%);
      }
    }
  }

  &__header {
    display: flex;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 10px;
    gap: 4px;
    height: 100%;

    &_wabaIcon, &_whatsAppIcon, &_telegramIcon, &_telegramBotIcon {
      width: 24px;
      height: 24px;
    }

    &_info {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      flex-basis: 70%;
      white-space: nowrap;
      overflow: hidden;

      &__name, &__phone {
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        color: #333333;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 32px;
          height: 20px;
          right: 0;
          top: 0;
          background: linear-gradient(90deg, rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0.0086472) 6.81%, rgba(246, 246, 246, 0.03551) 13.61%, rgba(246, 246, 246, 0.0816599) 20.42%, rgba(246, 246, 246, 0.147411) 27.22%, rgba(246, 246, 246, 0.231775) 34.03%, rgba(246, 246, 246, 0.331884) 40.83%, rgba(246, 246, 246, 0.442691) 47.64%, rgba(246, 246, 246, 0.557309) 54.44%, rgba(246, 246, 246, 0.668116) 61.25%, rgba(246, 246, 246, 0.768225) 68.06%, rgba(246, 246, 246, 0.852589) 74.86%, rgba(246, 246, 246, 0.91834) 81.67%, rgba(246, 246, 246, 0.96449) 88.47%, rgba(246, 246, 246, 0.991353) 95.28%, #F6F6F6 102.08%);
        }
      }

      &__phone {
        margin-top: 2px;
        font-weight: normal;
      }

      &__lastMessageInfo {
        display: flex;
        flex-direction: column;
        flex: 1;

        &_time {
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          text-align: right;
          letter-spacing: -0.04em;
          font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
          color: #4F4F4F;
        }

        &_unansweredCountPoint {
          margin-top: 13px;
          width: 8px;
          height: 8px;
          background-color: #2CBA5F;
          border-radius: 6px;
          align-self: flex-end;
        }
      }
    }

    &_lastMessageInfo {
      display: flex;
      align-self: flex-end;
    }
  }

  &__tags {
    //margin-top: 12px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;

    &_tag {
      margin-left: 4px;
      margin-top: 4px;
      width: fit-content;
      background: #F6F8C5;
      box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
      border-radius: 6px;
      padding: 4px 8px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      color: #333333;
    }
  }
}

