.blackListAdd {
  display: flex;
  column-gap: 8px;

  & > button {
    padding: 13px 16px 15px 12px;
    margin-top: 36px;
  }

  .phoneField {

    .PhoneInput {
      height: 56px;
      width: 282px
    }
  }
}
