.messagePlug {
  &__field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 648px;
    height: 112px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
    color: #828282;
    background: #FFFFFF;
    border-top: 1px solid #E0E0E0;
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 10px 10px 0 0;
  }
}
.messagePlug::after {
  content: '';
  box-sizing: border-box;
  display: flex;
  width: 648px;
  height: 48px;
  background: #F4F9FF;
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.03);
  border-radius: 0 0 16px 16px;
}
