.interactiveMessageTemplateCard {
  display: flex;
  position: relative;
  padding: 20px 0 24px 24px;
  background: #F5F4F3;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  margin-bottom: 16px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > h3 {
      margin-bottom: 12px;
    }

    &.monospaceSmText {
      color: #828282;
    }
  }

  &__container > p + * {
    margin-bottom: 8px;
  }
}
