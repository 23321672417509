.notItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #FFFFFF;
  overflow-y: scroll;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > p {
    width: 476px;
    text-align: center;
    white-space: pre-line;
  }

  & > .GlobalButton {
    padding: 15px;
    margin-top: 56px;
  }
}
