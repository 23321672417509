.itemNameDate {
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  font-style: normal;
  color: #333333;

  &__name {
    word-wrap: break-word;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cpsp' on;
  }
  
  &__date {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  }
}

.itemNameDate > * + * {
  margin-top: 4px;
}
