.orders {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-areas: "header" "contant" "footer";
  grid-template-rows: 0fr 1fr 70px;
  background: #FFFFFF;
  box-shadow: -12px 0 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0 0 32px;
  padding: 32px 0 18px 32px;
  z-index: 2;

  &__wrapper {

    &_header {
      display: grid;
      grid-area: header;
      grid-template-rows: 1fr 70px;

      h1 {
        padding-left: 16px;
      }

      &_list {
        display: grid;
        grid-template-columns: 55% minmax(15%, 2fr) repeat(2, minmax(10%, 1fr));
        column-gap: 24px;
        align-items: center;
        justify-items: start;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #333333;
        border-bottom: 2px #E6E6EA solid;

        &_order {
          padding-left: 16px;
        }

        &_amount {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          padding-right: 12px;
        }
      }
    }

    &_list {
      grid-area: contant;
      overflow-y: scroll;
    }
  }
}

.orders > * {
  padding-right: 48px;
}

@media screen and (max-width: 1024px) {
  .orders {
    min-width: 960px;
    max-width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .orders {
    min-width: 960px;
    max-width: 75%;
  }
}

@media screen and (max-width: 420px) {
  .orders {
    min-width: 960px;
    max-width: 75%;
  }
}
