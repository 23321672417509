@import "src/mixin.scss";

.infoUsers {
  background: #F5F4F3;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 20px 0 0 20px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  min-width: 680px;
  position: relative;

  @include _1300{
    width: 680px;
    .settingsContainer{
      overflow: visible;

    }
    &.minHeight{
      min-height: 160px;
    }
  }


  &.pb{
    padding-bottom: 20px;
  }

  & .edit{
    display: flex;
    justify-content: space-between;
    margin-right: 20px;


    svg{
      width: 15px;
      height: 15px;
      cursor: pointer;
      fill: #333333;

      & path {
        fill: #333333;
      }
    }
  }


  & .editableCard {
    width: 100%;
    position: relative;
    min-height: 176px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &__name {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      color: #333333;
    }

    &__switchContainer {
      display: flex;
      align-items: center;

      & > span {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        color: #333333;
        margin-right: 8px;
      }

      &_switch {
        width: 237px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #F9F9F9;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
        border-radius: 8px;

        &__firstSwitch, &__secondSwitch {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.005em;
          height: 30px;
          border-radius: 7px;

          & > span {
            margin: 5px 12px;
          }
        }

        &__firstSwitch.active, &__secondSwitch.active {
          background: #1C2027;
          box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.15);
          color: #FFF;
        }

        &__firstSwitch {
          margin-left: 4px;
        }

        &__secondSwitch {
          margin-right: 4px;
        }

      }
    }

    .GlobalButton {
      margin-top: 24px;
    }

  }

  &__text {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    column-gap: 5px;
    row-gap: 10px;

    &_name {
      display: flex;
      flex-wrap: nowrap;
      color: #333333;
      font-family: Inter, serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;

    }
  }

  &__content{

    &__userEdit{
      display: flex;
      flex-direction: column;

      &__rolesButtons{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 24px;

        &_save {
          margin-right: 24px;

          button {
            padding: 6px 12px;
            font-size: 14px !important;
          }
        }
      }

      h2{
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__role {
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-right: 8px;
    color: #FFFFFF;
    padding: 4px 8px;
    height: 24px;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    overflow:hidden;
    background: #a3a3d8;
    max-width: 360px;

    &.longWord{

      text-overflow: ellipsis;
      position: relative;
      &:after {
        /* hide */
        content: "";
        position: absolute;
        width: 32px;
        height: 100%;
        right: 0;
        top: 0;
        background: linear-gradient(90deg, rgba(163, 163, 216, 0) 0%, rgba(163, 163, 216, 0.0086472) 6.67%, rgba(163, 163, 216, 0.03551) 13.33%, rgba(163, 163, 216, 0.0816599) 20%, rgba(163, 163, 216, 0.147411) 26.67%, rgba(163, 163, 216, 0.231775) 33.33%, rgba(163, 163, 216, 0.331884) 40%, rgba(163, 163, 216, 0.442691) 46.67%, rgba(163, 163, 216, 0.557309) 53.33%, rgba(163, 163, 216, 0.668116) 60%, rgba(163, 163, 216, 0.768225) 66.67%, rgba(163, 163, 216, 0.852589) 73.33%, rgba(163, 163, 216, 0.91834) 80%, rgba(163, 163, 216, 0.96449) 86.67%, rgba(163, 163, 216, 0.991353) 93.33%, #a3a3d8 100%);
      }
    }

    &.OWNER {
      background: #2CBA5F;
    }

    &.MANAGER {
      background: #F2C94C;
    }

    &.ADMINISTRATOR {
      background: #F2994A;
    }
  }

  p {
    color: #333333;
    letter-spacing: -0.03em;
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;

    .UsersButton {

      background: #FFFFFF;
      border-radius: 12px 0 12px 0;
      display: flex;

      .optionallyButton {
        padding: 10px 16px;
        border: none;
        background: none;
        font-family: Inter, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          margin-right: 4px;
        }
      }
    }
  }
}
