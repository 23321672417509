.contactsForm {

  &__header {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &__text {
      max-width: 418px;
      margin-bottom: 8px;

      span {
        background-color: rgba(152, 151, 149, 0.15);
        padding: 5px;
        border-radius: 6px;

      }
    }
  }

  &__content {
    display: flex;

    &__textarea {
      position: relative;

      textarea {
        background: #F4F9FF;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        height: 160px;
        overflow-x: hidden;
        width: 535px;
        resize: none;
        outline: none;
        border: none;
        padding: 16px;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
      }

      &__wrapperRecalculate {
        position: absolute;
        bottom: 16px;
        right: 16px;

        &_recalculate {
          background: #FFFFFF;
          display: flex;
          align-items: center;
          box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
          border-radius: 8px;
          padding: 6px 8px;
          cursor: pointer;

          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.005em;
          font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
          color: #4F4F4F;

          svg {
            margin: 4.7px;
          }

          &:active:not(.disabled) {
            transform: translate(0em, 0.15em);
          }
        }

      }
    }

    &__static {
      margin: 10px 41px 30px 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__recipients {

        &_title {
          display: flex;
          justify-content: flex-end;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }

        &_count {
          display: grid;
          justify-items: end;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
        }

        &.error {
          color: #F54242;
        }
      }

      &__doubleError {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #828282;


        &_double {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 3px;

          span {
            margin-left: 16px;
          }
        }

        &_error {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            margin-left: 16px;
          }
        }
      }
    }
  }
}
