@import 'src/mixin.scss';

.dialogsInfo {
  display: flex;
  @include desktop {
    align-items: center;
    position: relative;
    width: fit-content;
  }
  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 36px;
  }

  & > p {
    font-size: 18px;
    color: #333333;
  }

  .warn {
    color: #F54242;
  }

  .GlobalButton {
    padding: 6px 12px;
    min-width: 112px;
    @include desktop {
      margin-left: 12px;
      position: absolute;
      top: -6px;
      left: 100%;
    }
    @include mobile {
      margin: 8px 0;
    }
  }
}
