.addRoleModalWrapper{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 333;
  width: 100%;
  height: 100%;

 &__addRoleModal{
   display: grid;
   height: 100%;
   align-items: center;
   justify-items: center;


   &__item{
     display: grid;
     grid-template-rows: 26px auto auto;
     row-gap: 8px;
     padding: 20px 24px;
     width: 384px;
     min-height: auto;

     background: white;
     box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);
     border-radius: 12px;

     &__title{
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       svg{
         cursor: pointer;
       }
     }

     &__input{
       display: grid;
       //grid-template-rows: 26px 36px;
       //row-gap: 4px;

       & .error{
         color: #F54242;
       }

       h3{
         font-weight: 500;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: -0.005em;
       }

       input{
         height: 36px;
       }

       &__errorText{
         font-size: 14px;
         color: #F54242;
       }
     }

     &__buttons{
       display: flex;
       align-items: flex-end;
       justify-content: space-between;
       margin-top: 25px;

       button{
         padding: 6px 12px;
         font-weight: 400
       }
     }
   }
 }
}
