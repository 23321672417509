.modulBankCreate {

    &__header {
      display: inline-flex;
      margin-bottom: 20px;
  
      & > h3 {
        font-family: 'Inter'; 
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: -0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
        color: #333333;
      }
  
      & > button {
        padding: 6px 12px 6px 8px !important;
        margin-right: 28px;
      }
    }
  
    .dropdownContainer {
      width: 400px;
    }
  
    .inputContainer__input {
      width: 400px;
    }
  
    & form button {
      margin-top: 40px;
      padding: 13px 16px;
    }
  }