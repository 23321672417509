.gift {
  display: inline-flex;
  padding: 2px 4px;
  align-items: center;

  border-radius: 6px;
  background: #333;

  & > p {
    color: #FFF;
    font-variant-numeric: lining-nums proportional-nums stacked-fractions;
    font-feature-settings: 'cpsp' on, 'cv05' on, 'case' on, 'cv09' on, 'cv02' on, 'cv01' on;
  }

  & svg {
    width: 16px;
    height: 16px;
  }
}

.gift > * + * {
  margin-left: 4px;
}
