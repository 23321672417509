.interactiveTemplateComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  margin: 12px 16px 10px;

  & .wrapper {
    border-radius: 8px;
    border: 1px solid #F2F2F2;
    background: #F5F4F3;
    overflow-y: auto;
    max-height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px
  }

  &__attachmentContainer {
    width: 100%;

    &_attachment {
      display: flex;
      width: 100%;

      & img {
        width: 100%;
        object-fit: cover;
      }

      & .documentPreviewContainer {
        width: 100%;
        border-radius: 0;
      }
    }
  }

  &__textSection {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 4px;
    margin-bottom: 12px;
    padding-left: 5px;
    padding-right: 5px;

    &_title {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
    }

    &_body {
      text-align: initial;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
    }

    &_footer {
      margin-top: 8px;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #4F4F4F;
    }
  }

  &__actionSection {
    width: 100%;

    &_buttonSection {
      width: 100%;

      &__button {
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2F80ED;
        box-shadow: 0 4px 4px -2px rgba(0, 12, 68, 0.25);
        border-radius: 8px;
        margin-bottom: 8px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #FFFFFF;;
      }
    }

    &_listSection {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      background: #FFFFFF;
      box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.15);
      border-radius: 8px;


      &__title {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
        color: #333333;
        margin-bottom: 8px;
        margin-top: 12px;
      }

      &__row {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid #E0E0E0;
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 10px;
        }

        &_title {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.005em;
          font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
          color: #333333;
          margin-bottom: 2px;
        }

        &_description {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.005em;
          font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
          color: #333333;
          margin-bottom: 6px;
        }
      }
    }
  }

  &__buttonsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }
}

