.navbarContainer {
  position: sticky;
  top: 0;
  min-width: 216px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 16px 0 20px 0;

  &.rolledUp {
    min-width: 64px;
    width: 64px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 20px 10px 18px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      border-radius: 2px;
      background: linear-gradient(90deg, #E6E6EA 0%, #D0D0D9 50%, #E6E6EA 100%);
    }

    &.rolledUp {
      justify-content: center;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &__closeTrigger {
    position: absolute;
    right: 0;
    top: 20px;
    cursor: pointer;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    padding: 6px 6px 6px 4px;
    flex-direction: column;
    flex-shrink: 0;
    border-radius: 6px 0 0 6px;
    background: #F6F6F6;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &__tabs {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 12px 16px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &.rolledUp {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & a {
      text-decoration: none;
    }

    & .tab {
      display: flex;
      align-items: center;
      padding: 8px;
      cursor: pointer;
      width: 192px;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      &.active {
        border-radius: 10px;
        background: linear-gradient(192deg, #E6E6EA 9.05%, #F5F4F3 91.82%);
        box-shadow: 0 2px 8px -2px rgba(63, 75, 138, 0.10);

        &.rolledUp {

          .tab {
            width: 24px;
          }
        }

        p {
          color: #FE6D04;
        }

        & svg path {
          fill: #FE6D04;
        }
      }

      &:hover {
        p {
          color: #FE6D04 !important;
        }

        svg path {
          fill: #FE6D04 !important;
        }
      }

      &.rolledUp {
        width: 44px;
        display: flex;
        justify-content: center;

        svg {
          margin-right: 0;
        }
      }

      & p {
        color: #333;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.09px;
      }

      .popup {
        height: 24px;
      }

      &:hover .popupTitle {
        display: inline-block;
        animation: fadein 400ms ease-in;
      }

      .popupTitle {
        top: auto;
        left: 66px;
      }
    }
  }

  &__footer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 16px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      border-radius: 2px;
      background: linear-gradient(90deg, #E6E6EA 0%, #D0D0D9 50%, #E6E6EA 100%);
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -32px;
      width: 100%;
      height: 32px;
      background: linear-gradient(180deg, rgba(230, 230, 234, 0.00) 0%, rgba(230, 230, 234, 0.01) 6.67%, rgba(230, 230, 234, 0.04) 13.33%, rgba(230, 230, 234, 0.08) 20%, rgba(230, 230, 234, 0.15) 26.67%, rgba(230, 230, 234, 0.23) 33.33%, rgba(230, 230, 234, 0.33) 40%, rgba(230, 230, 234, 0.44) 46.67%, rgba(230, 230, 234, 0.56) 53.33%, rgba(230, 230, 234, 0.67) 60%, rgba(230, 230, 234, 0.77) 66.67%, rgba(230, 230, 234, 0.85) 73.33%, rgba(230, 230, 234, 0.92) 80%, rgba(230, 230, 234, 0.96) 86.67%, rgba(230, 230, 234, 0.99) 93.33%, #E6E6EA 100%);
    }

    &.rolledUp {

      & .supportMenu {
        align-items: center;

        & > :first-child {
          margin-bottom: 16px;
        }
      }
    }

    .promotionBanner {
      margin-left: 12px;
      margin-right: 20px;
      margin-bottom: 12px;

      &__banner {
        width: 176px;
        height: 118px;
      }

      &__logo {
        width: 100%;
      }
    }

    .companyDropdown {
      margin: 0 12px 16px 12px;
    }

    .supportMenu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 20px;

      a:first-child {
        margin-bottom: 12px;
      }

      a:hover svg path {
        fill: #FE6D04 !important;
      }
    }
  }
}

.onboardingHelpTooltip {
  position: absolute;
  left: 250px;
  min-width: 385px;
  bottom: 230px;
  background-color: #FFFFFF;
  display: flex;
  padding: 24px 48px 24px 24px;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 12px;

  & .wrapper {
    position: relative;

    & > p {
      color: #333333;
      font-size: 14px;
    }

    & .tailIcon {
      position: absolute;
      top: -10px;
      width: 15px;
      height: 15px;
      left: -35px;
      transform: rotateZ(90deg);
    }

    & .closeIcon {
      cursor: pointer;
      position: absolute;
      bottom: 53px;
      right: -35px;
    }
  }
}
