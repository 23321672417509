@import 'src/mixin.scss';

.createChatPopup {
  position: absolute;
  width: 336px;
  z-index: 325;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 20px 24px 24px 24px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 12px;
  font-family: Inter, sans-serif;

  @include mobile {
    position: fixed;
    width: 100%;
    bottom: 0;
    top: auto !important;
    left: 0 !important;
    border-radius: 28px 28px 0 0;
    padding: 0 0 24px 0;
  }

  &.new {
    left: -7px;
    top: 60px;
  }

  &.bottom {
    top: 38px;
    left: -60px;
  }

  &.top {
    top: -290px;
    left: -60px;
  }

  .inputStandard {
    height: 36px;
    padding: 8px 4px 8px 12px;
    background: #f4f9ff;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: none;
    outline: none;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;

    &.selector {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    @include mobile {
      padding: 20px 24px;
      border-bottom: 1px solid #E0E0E0;
      border-radius: 1px;
      margin-bottom: 16px;
    }

    &_title {
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.01em;
      color: #333333;
    }

    svg {
      width: 14px;
      height: 14px;

      @include mobile {
        width: 18px;
        height: 18px;
      }
    }

    & > svg path {
      fill: #333333;
    }
  }

  .phoneDropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    @include mobile {
      padding: 0 24px;
    }

    .switchTabs {
      margin-bottom: 12px;

      & > button {
        padding: 5px 12px;
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }

    p {
      margin-bottom: 4px;

      @include mobile {
        margin-bottom: 12px;
      }
    }

    .phoneField .PhoneInput {
      width: 100%;
      height: 35px;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.56px;
      font-feature-settings: 'cpsp' on, 'case' on, 'cv05' on, 'cv02' on, 'cv01' on, 'cv09' on;

      @include mobile {
        height: 56px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.09px;
      }
    }

    &_dropdown {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      background: #f4f9ff;
      box-shadow: 0 6px 11px rgba(125, 123, 123, 0.04);
      border-radius: 4px;
    }

    .phoneInput:focus-within + .contentList {
      display: block;
    }

    .contentList {
      display: none;
      position: absolute;
      width: 100%;
      background: #f4f9ff;
      border-radius: 6px;
      padding: 6px 2px 6px 2px;
      z-index: 2222;
      bottom: -42px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.03em;
      font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;

      &_item {
        position: relative;
        display: flex;
        padding: 4px;
        text-align: left;
        width: 100%;
        color: #333333;
        border-radius: 6px;
        cursor: pointer;

        &:hover {
          background: #FFE1CC;
        }
      }

      &:hover {
        display: block;
      }
    }
  }

  &__errorMessage {
    margin-top: 12px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #f54242;
    text-align: start;

    @include mobile {
      margin: 12px 20px 0 20px;
    }
  }

  &__tail.new, &__tail.bottom {
    position: absolute;
    top: -6px;
    left: 22px;
    transform: rotate(180deg);
  }
  &__tail.top {
    position: absolute;
    bottom: -6px;
    left: 22px;
  }

  .GlobalButton {
    margin-top: 24px;
    padding: 6px 12px;

    @include mobile {
      width: auto;
      margin: 24px 24px 0 24px;
      padding: 13px 16px 15px 16px;
    }
  }
}
