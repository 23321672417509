@import 'src/mixin.scss';

.editForm {
  @include desktop {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 10px;
    justify-items: center;
    margin-bottom: 40px;
  }
  @include mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 62px;
  }

  &__buttonsWrapper {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    align-self: flex-end;

    & > button:first-of-type {
      border-radius: 12px 0 0 0;
    }

    &_edit, &_cancel {
      cursor: pointer;
      padding: 10px 16px 10px 16px;
      background: #FFFFFF;
      border: none;
      display: flex;
      align-items: center;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      & > svg {
        margin-right: 6px;
      }
    }

    &_edit {
      color: #333333;
    }

    &_edit:disabled {
      color: #d9d0d0;

      & > svg path {
        fill: #d9d0d0;
      }
    }

    &_cancel {
      color: #F54242;
    }
  }

  &__details {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 10px;
    font-weight: normal;
    font-size: 18px;
    color: #333333;

    @include desktop {
      margin-top: 8px;
      margin-bottom: 24px;
    }
    @include mobile {
      margin-left: 36px;
      margin-bottom: 32px;
      grid-template-columns: 100%;
    }
  }

  &__date {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    position: relative;

    @include mobile {
      display: none;
    }

    img {
      margin-left: 8px;
    }
  }
}

.info {
  display: inline-flex;
  vertical-align: middle;
  margin-left: 8px;

  &__icon {
    & svg {
      fill: #FE6D04;
    }
  }

  &__message {
    display: none;
    @include desktop {
      position: absolute;
      width: max-content;
      left: 0;
      top: 24px;
      border-radius: 12px;
      padding: 20px 18px 20px 24px;
      white-space: pre-line;
      font-size: 14px;
      line-height: 20px;
    }
    @include mobile {
      position: fixed;
      width: 100%;
      height: 174px;
      left: 0;
      bottom: 0;
      border-radius: 28px 28px 0 0;
      padding: 24px 24px;
      z-index: 100;
      font-size: 18px;
      line-height: 24px;
    }
    background: #FFFFFF;
    box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);
    border-radius: 12px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;

    color: #333333;
  }

  &:hover &__message {
    display: block;
  }
}

.load {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0);
}

@include mobile {
  .listiconContainer__list__item_image {
    transform: rotate(90deg);
  }
}
