.createContact__wrapper {
  width: 100%;
  min-height: 100%;
  overflow-y: scroll;
  padding: 32px 0 32px 48px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.05);
  border-radius: 32px 0 0 32px;
}

.createContact {
  display: flex;
  flex-direction: column;

  & > h1 {
    margin-bottom: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    .inputContainer {
      width: 464px;
      margin-bottom: 0;

      &__wrapper, input {
        width: 100%;
      }
    }
  }

  &__content > * + * {
    margin-top: 32px;
  }

  &__footer {
    display: flex;
    flex-direction: row;

    .GlobalButton {
      padding: 13px 16px 15px 16px;
    }
  }

  &__footer > * + * {
    margin-left: 24px;
  }
}
