.addUser {
  display: grid;
  row-gap: 24px;
  position: relative;

  &__alert {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: start;
    justify-items: center;

    &__item {
      animation: fadein 0.2s ease-in alternate;
      margin-bottom: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 12px 48px -16px rgba(33, 160, 56, 0.45);
      border-radius: 12px;
      padding: 20px 32px 20px 32px;;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      color: #FFFFFF;

      @keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
  }

  &__content {
    display: grid;
    row-gap: 12px;

    &__iventeLink {

      width: 100%;
      margin-right: 16px;
      position: relative;
      display: flex;

      input {
        width: 100%;
        background: #f4f9ff;
        box-shadow: inset 0 1px 2px rgb(0 0 0 / 20%);
        border-radius: 6px;
        border: none;
        height: 56px;
        color: #333;
        padding-left: 16px;
        padding-right: 12px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;

        text {
          width: 100px;
        }
      }

      &__reloadButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: none;
        top: 12px;
        right: 12px;
        position: absolute;
        width: 161px;
        height: 32px;
        background: #FFFFFF;
        box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        color: #333333;
        font-family: Inter, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;

        img {
          margin-right: 8px;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          border: 0;
          width: 32px;
          height: 32px;
          right: 160px;
          background: linear-gradient(90deg, rgba(244, 249, 255, 0) 0%, rgba(244, 249, 255, 0.0086472) 6.81%, rgba(244, 249, 255, 0.03551) 13.61%, rgba(244, 249, 255, 0.0816599) 20.42%, rgba(244, 249, 255, 0.147411) 27.22%, rgba(244, 249, 255, 0.231775) 34.03%, rgba(244, 249, 255, 0.331884) 40.83%, rgba(244, 249, 255, 0.442691) 47.64%, rgba(244, 249, 255, 0.557309) 54.44%, rgba(244, 249, 255, 0.668116) 61.25%, rgba(244, 249, 255, 0.768225) 68.06%, rgba(244, 249, 255, 0.852589) 74.86%, rgba(244, 249, 255, 0.91834) 81.67%, rgba(244, 249, 255, 0.96449) 88.47%, rgba(244, 249, 255, 0.991353) 95.28%, #F4F9FF 102.08%);
        }
      }
    }
  }

  .GlobalButton {
    padding: 13px 16px 15px 12px;

    & > svg {
      & path {
        fill: #FFFFFF;
      }
    }
  }
}
