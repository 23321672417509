@import 'src/mixin.scss';

.lableInfo {
  display: flex;
  flex-direction: column;
  
  
  &__lable {
    display: inline-flex;
    margin-bottom: 12px;
    width: fit-content;
    @include desktop {
      position: relative;
    }

    & .info__message {
      display: none;
      @include desktop {
        position: absolute;
        height: fit-content;
        top: auto;
        bottom: 0;
        left: calc(100% + 12px);
        border-radius: 12px;
        padding: 16px 32px 16px 16px;
        white-space: pre-line;
        font-size: 14px;
        line-height: 20px;
        z-index: 10;
      }
    }
  
    @include desktop {
      & .info__icon:hover ~.info__message {
        display: block;
      }
    }
    
    & > span {
      display: inline-flex;
      align-items: center;
    }

    & svg {
      width: 16px;
      height: 16px;
      margin-left: 12px;
    } 
  }
}
