.onboardingPopup {
  transition: all 700ms ease;
  transition-property: top, left, bottom, right, height;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  border: 1px solid #F2F2F2;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 12px;
  width: fit-content;
  background-color: #FFFFFF;

  &__stepper {
    display: flex;
    justify-content: space-between;
    margin-top: 26px;

    &_stepsWrapper {
      display: flex;
      align-items: center;
    }

    &_steps {
      width: 129px;
      height: 24px;
      align-items: center;
      justify-content: space-evenly;
      background-color: #EBEBEB;
      border-radius: 12px;
      display: flex;
      margin-right: 8px;

      &__step {
        transition: background-color, 800ms ease-in-out;
        background-color: #FFFFFF;
        cursor: pointer;
        width: 8px;
        height: 8px;
        border-radius: 5px;

        &.active {
          background-color: #FE6D04;
        }
      }
    }

    &_count {
      font-weight: 600;
      color: #333333;
      font-size: 14px;
    }

    &_arrowGroup {
      position: relative;
      display: flex;

      &_left, &_right {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 9px 10px;
        border-radius: 8px;
        box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.2);
      }

      &_left {
        margin-right: 12px;
        border: 1px solid #FFC093;
        background-color: #FFFFFF;

        & > svg {
          transform: rotateY(180deg);

          & > path {
            fill: #FE6D04;
          }
        }
      }

      &_right {
        background-color: #FE6D04;
        border: none;

        &.disabled {
          background: #E0E0E0;

          & > svg path {
            fill: #828282;
          }
        }
      }
    }
  }

  & .wrapper {
    position: relative;

    & .closeIcon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }

    & .tailIcon {
      width: 24px;
      height: 15px;
      cursor: pointer;
      position: absolute;
    }
  }
}

