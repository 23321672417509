.interactiveMessageSettingsActions {
  margin-top: 48px;

  &__header {
    width: 182px;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #333333;

    & > p {
      margin-bottom: 12px;
    }
  }
}




