.addActionButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 163px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #FFC093;
    box-sizing: border-box;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #FE6D04;

    & svg {
      margin-right: 6px;

      & path {
        fill: #FE6D04;
      }
    }
}
