.lifespanPayment {

  &__header {

    h3 {
      margin-bottom: 4px;
    }

    p {
      margin-bottom: 16px;
    }
  }

  &__content {
    display: flex;
    gap: 16px;
    max-width: 400px;

    input {
      width: auto;
    }
  }

  &__errorMessage {
    margin-top: 12px;
    color: #F54242;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.07px;
    font-feature-settings: 'cpsp' on, 'cv05' on, 'case' on, 'cv09' on, 'cv02' on, 'cv01' on;
  }
}
