.checkbox {
  display: flex;
  position: relative;
  width: fit-content;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
  color: #333333;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    all: revert;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    background-color: #eee;
    border-radius: 25%;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
}

//&_checkbox:hover input ~ .checkmark {
//  background-color: #ccc;
//}

.checkbox input ~ .checkmark {
  background-color: #FFFFFF;
  border-radius: 25%;
  border: #333333 2px solid;
}

.checkbox input:checked ~ .checkmark {
  background-color: #FE6D04;
  border: none;
  border-radius: 25%;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 3px;
  bottom: 4px;
  width: 13px;
  height: 10px;
  background-image: url('../../../assets/vector-chackbox.svg');
}
