.PopupApiKey {
  display: flex;
  flex-flow: column;

  & h2, p {
    margin-bottom: 8px;
  }

  & input {
    display: inline-flex;
    height: 56px;
    padding: 16px 5px 16px 7px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 6px;
    background: #F4F9FF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.20) inset;
  }

  .GlobalButton {
    display: flex;
    align-self: center;
    padding: 13px 16px 15px 12px;
    margin-top: 20px;

    & > svg {
      & path {
        fill: #FFFFFF;
      }
    }
  }
}
