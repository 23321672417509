.templateItem {
  width: auto;
  padding: 10px 12px;
  background: #F5F4F3;
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  color: #333333;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 42px;
    right: 0;
    background: linear-gradient(90deg, rgba(245, 244, 243, 0) 0%, rgba(245, 244, 243, 0.0086472) 6.67%, rgba(245, 244, 243, 0.03551) 13.33%, rgba(245, 244, 243, 0.0816599) 20%, rgba(245, 244, 243, 0.147411) 26.67%, rgba(245, 244, 243, 0.231775) 33.33%, rgba(245, 244, 243, 0.331884) 40%, rgba(245, 244, 243, 0.442691) 46.67%, rgba(245, 244, 243, 0.557309) 53.33%, rgba(245, 244, 243, 0.668116) 60%, rgba(245, 244, 243, 0.768225) 66.67%, rgba(245, 244, 243, 0.852589) 73.33%, rgba(245, 244, 243, 0.91834) 80%, rgba(245, 244, 243, 0.96449) 86.67%, rgba(245, 244, 243, 0.991353) 93.33%, #F5F4F3 100%);
  }
}
