.connectionView {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    padding-right: 48px;

    & > button {
      padding: 13px 16px 15px 16px;
    }
  }
}
