.blackList { 
  color: #333333;
  margin-bottom: 16px;
  width: fit-content;

  &_title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 16px;
  }

  &_addPhone {
    display: flex;
    column-gap: 12px;
  }

  &_description {
    display: flex;
    margin-top: 4px;
    max-width: 385px;

    & > svg {
      min-width: 17px;
      margin-right: 14px;
      margin-top: 3px;
    }

    & > p {
      font-size: 14px;
    }
  }

}
