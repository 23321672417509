.templatesList {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 376px;
  position: relative;
  background: #F6F6F6;
  border-radius: 20px 0 0 20px;
  box-shadow: -12px 0 48px -24px rgba(0, 0, 0, 0.2);

  &__item {
    display: flex;
    position: relative;
    align-items: center;
    width: calc(100% + 3px);
    height: 88px;
    cursor: pointer;
    margin-left: -3px;
    z-index: 9;

    &.active {
      background: #FFFFFF;
      box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.12);
      border-radius: 4px 0 0 4px;

      &::after {
        content: url("../../../../assets/bottom corner.svg");
        width: 8px;
        height: 8px;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      &::before {
        content: url("../../../../assets/top corner.svg");
        width: 8px;
        height: 8px;
        position: absolute;
        top: -15px;
        right: 0;
      }

      &:first-child {
        &::before {
          content: '';
          background-color: #FFFFFF;
          width: 32px;
          height: 32px;
          position: absolute;
          top: 0;
          right: -32px;
        }
      }
    }

    &:first-child {
      border-radius: 20px 0 0 4px;

      &::before {
        content: "";
      }
    }

    &_content {
      margin-left: 30px;
      display: flex;
      width: 100%;
      align-items: center;

      &__name {
        font-weight: 500;
        font-size: 18px;
        letter-spacing: -0.005em;
        color: #333333;
      }

      & svg {
        margin-right:12px ;
      }
    }
  }

  &::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 40px;
    right: -40px;
    background-color: #F6F6F6;
  }
}
