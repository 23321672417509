@import 'src/mixin.scss';

.promoCode {
  margin-top: 20px;
  display: flex;
  @include mobile {
    flex-direction: column;
  }
  
  &__field {
    display: flex;
    flex-direction: column;
    
    &_lable {
      display: inline-flex;
      margin-bottom: 12px;
      @include desktop {
        position: relative;
      }

      & > span {
        display: inline-flex;
        align-items: center;
      }

      & > .info__message {
        display: none;
        @include desktop {
          position: absolute;
          width: 272px;
          height: 72px;
          left: 80px;
          bottom: 32px;
          border-radius: 12px;
          padding: 16px 32px 16px 16px;
          white-space: pre-line;
          font-size: 14px;
          line-height: 20px;
          z-index: 10;
        }
      }
      @include desktop {
        & >.info__icon:hover ~.info__message {
          display: block;
        }
      }

      & svg {
        width: 16px;
        height: 16px;
        margin-left: 12px;
      }
      
    }

    & .inputContainer {
      width: 100%;

      &__wrapper {
        width: 100%;

        & input {
          background: #F4F9FF;
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
          border-radius: 6px;
          border: none;
          height: 56px;
          color: #333333;
          padding-left: 16px;
          padding-right: 12px;
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          width: 100%;
        }
      }
    }
  }

  & > .GlobalButton {
    padding: 13px 16px 15px;
    @include desktop {
      margin-top: 36px;
      margin-left: 16px;
    }
    @include mobile {
      width: 100%;
    }
  }
}
