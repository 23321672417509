.helpTooltipPopup {
  width: fit-content;
  position: absolute;
  left: 10px;
  bottom: 88px;
  min-width: 215px;
  display: flex;
  padding: 16px 12px 16px 12px;
  flex-direction: column;
  font-size: 14px;
  color: #333333;
  background: #FFFFFF;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 12px;
  z-index: 4;

  & .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 12px;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 12px;
  }

  &__bd {
    color: #333333;
    margin-bottom: 12px;
  }

  &__meeting {
    cursor: pointer;
  }

  &__tail {
    position: relative;

    & .tailIcon {
      position: absolute;
      top: 15px;
      left: 5px;
    }
  }
}
