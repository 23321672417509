.broadcastsPreview {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  padding: 32px 32px 16px 48px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.1);
  border-radius: 32px 0 0 32px;

  h6 {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }

  &__statistic {
    display: flex;

    & > div {
      margin-right: 64px;
    }
  }

  &__settings {
    margin-top: 48px;

    &_header {
      display: flex;
      align-items: flex-end;

      h5 {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
        color: #333333;
        display: flex;
        align-items: flex-start;

        svg {
          margin-right: 8px;
        }
      }

      .GlobalButton {
        margin-left: 12px;
        width: 150px;
        height: 32px;
        padding: 6px 12px 6px 8px;

        path {
          fill: #FE6D04;
        }
      }
    }

    .broadcastsInfo {
      margin-top: 16px;
      padding: 24px 24px 18px 32px;
      width: 800px;
      background: #FFFFFF;
      border: 1px solid #D2D2D2;
      border-radius: 12px;
      position: relative;

      .errorSubscriptions {
        display: flex;
        align-items: center;
        width: 624px;
        height: 40px;
        background: #FFCED4;
        border-radius: 10px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #333333;
        padding-left: 16px;
        margin-bottom: 24px;
      }

      .itemConnection {
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        }
      }

      p {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
        color: #333333;
      }

      .messageImage {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        object-fit: contain;

        p {
          margin-bottom: 8px;
        }

        img {
          height: 160px;
        }
      }

      .messageVideo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p {
          margin-bottom: 8px;
        }

        .videoPreview {
          height: 160px;
        }
      }

      .messageFile {

        p {
          margin-bottom: 8px;
        }
      }

      & .messageWabaTemplate {
        white-space: pre-wrap;
        width: 60%;
        padding: 10px 12px;
        background: #f5f4f3;
        border: 1px solid #f2f2f2;
        border-radius: 8px;

        &__media {
          margin-bottom: 10px;

          & > img, video {
            object-fit: contain;
            width: 100%;
          }
        }

        &__text {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.005em;
          color: #333333;
          margin-bottom: 10px;
        }

        &__buttons {
          flex-wrap: wrap;
          display: flex;
          justify-content: center;

          & button, a {
            margin: 5px;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: -0.005em;
            font-family: Inter, sans-serif;
            outline: none;
            border: none;
            width: 190px;
            height: 32px;
            background: #2F80ED;
            box-shadow: 0 4px 4px -2px rgba(0, 12, 68, 0.25);
            border-radius: 8px;
          }
        }
      }

      textarea {
        width: 736px;
        height: 160px;
        background: #F4F9FF;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        border: none;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
        color: #333333;
        padding: 16px;
        resize: none;
        outline: none;
      }

      &__right {
        position: absolute;
        right: 24px;
        top: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .GlobalButton {
          padding: 6px 12px 6px 8px;
        }

        .hidden {
          display: none;
        }
      }

      &__status {
        width: fit-content;
        border-radius: 10px;
        padding: 8px 12px 8px 12px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: right;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #333333;
        margin-bottom: 8px;

        &.planned {
          background: #F6F8C5;
        }

        &.deliver {
          background: #FFD5AB;
        }

        &.finish {
          background: #99D7CC;
        }

        &.error {
          background: #FABBBC;
        }
      }

      .GlobalButton {
        padding: 6px 12px 6px 12px;
      }
    }
  }
}
