.ConnectionsContainer {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-right: 48px;
  padding-bottom: 32px;

  .ConnectionsContent {
    overflow-y: auto;

    .ConnectionsSelect {
      display: flex;
      flex-wrap: wrap;

      &_div {
        display: flex;
        flex-direction: column;
        margin-right: 16px;
        margin-bottom: 36px;
        cursor: pointer;

        img {
          border-radius: 10px;
          margin-bottom: 12px;
        }

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          letter-spacing: -0.005em;
          font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
          color: #333333;
        }
      }
    }
  }
}

.connectionWrapper {
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-bottom: 26px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  color: #333333;

  p{
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;
  }

  &__connection{
    display: flex;
    border-radius: 10px;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 128px;
  }

  &__tinkoffCard, &__sberCard, &__modulBankCard, &__payKeeperCard, &__paypalCard, &__whatsAppCard,&__telegramCard,&__whatsAppBusinessCard {
    display: flex;
    border-radius: 10px;
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 128px;
  }

  &__tinkoffCard {
    background: #FFDD2D;
  }

  &__sberCard {
    background: #F5F4F3;
  }

  &__modulBankCard {
    background: #0F1A7C;
  }

  &__payKeeperCard {
    background: #CC1626;
  }

  &__paypalCard {
    background: #F7F9FA;
  }

  &__whatsAppCard {
    background: #25D366;
  }
 &__telegramCard {
    background: #59A3D6;
  }

  &__title {
    margin-top: 12px;
    font-size: 14px !important;
    font-weight: 500;
  }
}

