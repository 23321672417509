.customTemplateCard {
  display: flex;
  position: relative;
  min-height: 100px;
  padding: 20px 38px 24px 24px;
  background: #F5F4F3;
  border: 1px solid #F2F2F2;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  margin-bottom: 16px;

  &__content {
    display: flex;
    flex-direction: column;
    min-width: 0;

    & > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
