.contactItem {
  display: grid;
  grid-template-rows: max-content auto;
  border-bottom: 2px #E6E6EA solid;
  cursor: pointer;
  padding: 14px 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  height: max-content;

  &:hover {
    background: #F6F6F6;
    border-radius: 0 0 8px 8px;

    .contactName, .contactEmail, .contactPhone, .contactTags, .contactChat{
      &:after {
        background: linear-gradient(90deg, hsla(0, 0%, 96%, 0), hsla(0, 0%, 96%, .009) 6.67%, hsla(0, 0%, 96%, .036) 13.33%, hsla(0, 0%, 96%, .082) 20%, hsla(0, 0%, 96%, .147) 26.67%, hsla(0, 0%, 96%, .232) 33.33%, hsla(0, 0%, 96%, .332) 40%, hsla(0, 0%, 96%, .443) 46.67%, hsla(0, 0%, 96%, .557) 53.33%, hsla(0, 0%, 96%, .668) 60%, hsla(0, 0%, 96%, .768) 66.67%, hsla(0, 0%, 96%, .853) 73.33%, hsla(0, 0%, 96%, .918) 80%, hsla(0, 0%, 96%, .964) 86.67%, hsla(0, 0%, 96%, .991) 93.33%, #f6f6f6);
      }
    }
  }

  &__show {
    display: grid;
    grid-template-columns: 66px minmax(250px, 1fr) repeat(4, minmax(200px, 1fr));
    height: 92px;
    overflow: hidden;

    &.active {
      align-content: center;
      height: auto;
      overflow: visible;
    }
  }

  .contactPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 48px;
    width: 48px;
    background: #a3a3db;
    border-radius: 50%;

    p {
      font-weight: 600;
      font-size: 20px;
      color: #FFFFFF;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .contactName {
    width: 95%;
    overflow: hidden;
    white-space: normal;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 32px;
      height: 100%;
      right: 0;
      top: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, white 100%);
    }

  }

  .contactEmail {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(auto-fill, 24px);
    row-gap: 8px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 32px;
      height: 100%;
      right: 0;
      top: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, white 100%);

    }
  }

  .contactPhone {
    width: 200px;
    display: grid;
    grid-template-rows: repeat(auto-fill, 24px);
    row-gap: 8px;

  }

  .contactTags {
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 32px;
      height: 100%;
      right: 0;
      top: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, white 100%);
    }

    &_list {
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
      overflow: hidden;
      white-space: nowrap;

      &_item {
        margin: 4px;
        box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
        border-radius: 6px;
        padding: 5px;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  &__toggle {
    display: grid;
    height: 12px;
    justify-items: end;

    &__item {
      display: grid;
      grid-template-columns: 15px 1fr;
      column-gap: 6px;
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.005em;

      svg {
        width: 10px;
        height: 15px;
      }
    }
  }
}
