.inputsPhones {

  & > p.regularText {
    margin-bottom: 12px;
  }

  .phoneItem {
    display: flex;
    align-items: baseline;

    .phoneField {
      .PhoneInput {
        height: 56px;
        width: 464px;
        & > input {
          font-size: 18px;
          font-style: normal;
          font-weight: normal;
          line-height: 24px;
          color: #333333;
        }
      }
    }

    .GlobalButton {
      margin-left: 16px;
      padding: 6px 8px;
      border: 1px solid #ffb3b3;
    }
  }
  
  &__footer {
    margin-top: 16px;

    .GlobalButton {
      padding: 6px 8px;
    }
  }
}

.inputsPhones > div.phoneItem + div.phoneItem {
  margin-top: 8px;
}
