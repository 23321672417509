.informing {
  display: flex;

  & > svg {
    margin-top: 3px;
    width: 16px;
    height: 16px;
  }

  & > span {
    margin-left: 12px;
    white-space: pre-line;
    min-width: 244px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
  }
}
