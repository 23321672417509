.yookassaEdit {
  display: flex;
  flex-direction: column;

  & > p:last-of-type {
    margin-bottom: 32px;
  }

  & .inputContainer__input {
    width: 400px;
  }
  
  .dropdownContainer {
    width: 400px;
  }

  & .GlobalButton {
    margin-top: 40px;
    padding: 14px 16px 15px;
  }
}

.yookassaEdit > p + p {
  margin-top: 12px;
}
