.connectionStatus {
  display: flex;
  align-items: center;
  margin-left: -24px;
  margin-bottom: 8px;

  &_point {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin-left: 12px;
    margin-right: 6px;

    &.qr, &.loading, &.not_paid, &.conflict {
      background-color: #F54242;
    }
  
    &.ok, &.good {
      background-color: #2CBA5F;
    }
  }
}
