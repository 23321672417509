.radioButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-bottom: 12px;
  position: relative;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
  color: #333333;

  .checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
    min-width: 18px;
    background: #FFFFFF;
    border: 2px solid #333333;;
    border-radius: 50%;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: flex;
  }

  .checkmark:after {
    width: 8px;
    height: 8px;
    background: #2CBA5F;
    box-shadow: 0 2px 4px -2px rgba(24, 123, 59, 0.65);
    border-radius: 50%;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 18px;
    height: 18px;
    box-shadow: none;
  }

  :last-child {
    margin-right: 12px;
  }
}
