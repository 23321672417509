.wabaCreateForm {
  display: flex;
  flex-direction: column;
  max-width: 910px;
  padding-right: 48px;

  & > form {
    display: flex;
    flex-direction: column;

    & > button {
      padding: 14px 16px 15px 16px !important;
    }
  }

  &__instruction {
    margin-bottom: 28px;

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;

    color: #333333;

    & > ol {
      margin-top: 32px;
    }
    
    & > ol > li + li {
      margin-top: 8px;
    }
  }

  &__360dialog{
    cursor: pointer;
    display: flex;
    align-items: center;

    min-width: 374px;
    max-width: 414px;
    height: 52px;
    padding: 13px 16px 15px;

    background: #FF4369;
    box-shadow: 0 4px 4px -2px rgba(141, 0, 0, 0.25);
    border-radius: 10px;

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cpsp' on;

    color: #FFFFFF;
    transition-duration: 200ms;

    &:active:not(.disabled) {
      transition-duration: 200ms;
      transform: translate(0em, 0.15em);
    }

    svg{
      margin-right: 16px;
    }
  }

  &__information {
    display: flex;
    margin-top: 40px;
    
    & > p {
      margin-left: 8px;

      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
      color: #333333;

      & > a {
        text-decoration: underline;
        color: #FE6D04;
      }
    }
  }
}
