.catalogsForm {
  max-width: 560px;
  height: 100%;
  overflow: hidden;

  & .inputContainer {
    width: 100%;

    & input {
      width: 464px;
      padding-left: 16px;
      padding-right: 12px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      color: #333333;
    }
  }

  & .load {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    position: inherit;
    z-index: 500;
    background-color: rgba(0, 0, 0, 0);
  }

  & .search {
    margin-bottom: 24px;

    &__input {
      width: 464px;
      height: 56px;
      background: #F4F9FF;
    }
  }

  & > h3, h4 {
    margin-bottom: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }

  & > h3 {
    font-size: 32px;
    line-height: 44px;
  }

  & > h4 {
    font-size: 24px;
    line-height: 32px;
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    &.right {
      justify-content: flex-end;
    }

    &.clicked {
      pointer-events: none;
    }

    & > button {
      padding: 13px 16px 15px;
    }
  }
}
