.broadcastsView {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-right: 28px;
    margin-bottom: 18px;

    & > .GlobalButton {
      padding: 13px 16px 15px 12px;
    }
  }

  &__content {
    padding-right: 16px;
    overflow-y: scroll;
  }

  h5 {
    margin-bottom: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }

  & thead {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
  }
}

.broadcastsView__content > div:last-child {
  margin-top: 40px;
}
