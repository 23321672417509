.whatsappEdit{
  display: flex;
  flex-direction: column;
  max-width: 910px;
  padding-right: 48px;

  font-family: Inter,sans-serif;
  color: #333333;

  & > p {
    margin-bottom: 12px;
  }

  & > button {
    margin-top: 40px;
    padding: 14px 16px 15px;
  }

  .inputContainer__input {
    width: 400px;
  }
}
