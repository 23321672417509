.profileInvoiceItem{
  display: grid;
  grid-template-columns: 40% minmax(10%, 1fr) repeat(1, minmax(10%, 1fr));
  border-bottom: 1px #E6E6EA solid;
  padding: 20px 0;

  &_name{
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }

  &_count, &_price  {
    display: grid;
    justify-content: flex-end;
    width: 100%;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.03em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
  }
}
