@import 'src/mixin.scss';

.conversationHeader {
  min-height: 88px;
  display: flex;
  align-items: center;
  position: relative;
  background: #E5E5EB;
  padding: 0 20px;

  @include mobile {
    background: #FFF;
    border-top-left-radius: 0;

    h1 {
      margin-right: 24px;
    }
  }

  &__createChatPopup {
    position: relative;
    top: -27px;
    left: -87px;
  }

  &__addChatButton {
    position: relative;
    min-width: 48px;
    height: 48px;
    margin-right: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #FFC093;
    box-sizing: border-box;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    outline: none;
    cursor: pointer;

    @include mobile {
      margin-right: 16px;
    }

    &.disabled {
      background: none;
      border: none;
      box-shadow: none;
      cursor: auto;
    }

    &.disabled:active {
      border: none;
    }

    & > svg path {
      fill: #FE6D04;
    }

    &:active {
      border: 1px solid #FFF;

      & > svg path {
        fill: #ac1eba;
      }
    }
  }

  .closeIcon {
    width: 18px;
    height: 18px;
    margin-left: 24px;
  }
}
