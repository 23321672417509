.users {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  &__header {
    display: flex;
    justify-content: space-between;

    .GlobalButton {
      padding: 13px 16px 15px 12px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    button:active, button:focus, input:active, input:focus {
      outline: none;
    }

    button::-moz-focus-inner {
      border: 0;
    }

    p {
      color: #333333;
      margin-bottom: 20px;
      font-weight: normal;
      font-size: 18px;
    }
  }
}

.users > * {
  padding-right: 48px;
}
