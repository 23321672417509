.comboList {
  position: relative;

  & > ul {
    display: none;
    position: absolute;
    width: inherit;
    background: #F4F9FF;
    border-radius: 6px;
    padding: 6px 2px 6px 2px;
    z-index: 200;
    margin-top: 4px;

    &:hover{
      display: block;
    }

    & > li {
      list-style: none;
      border-radius: 6px;
      cursor: pointer;
      padding: 8px 10px 10px 10px;

      &:hover {
        background: #FFE1CC;
      }
    }
  }

  > *:focus-within + ul {
    display: block;
  }
}
