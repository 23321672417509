.NoConnectionsVideo {
  height: 100%;
  overflow-y: scroll;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__video {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
  }

  &__content {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 16px;
      text-align: center;
    }

    a {
      cursor: pointer;
      color: #FE6D04;
    }

    .checkbox {
      margin-top: 36px;
      padding-left: 26px;
      font-feature-settings: 'cpsp' on, 'case' on, 'cv05' on, 'cv02' on, 'cv01' on, 'cv09' on;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.07px;
    }

    .GlobalButton {
      margin-top: 18px;
      padding: 13px 16px 15px 12px;
    }
  }
}
