@import 'src/mixin.scss';

@include mobile {
  ::-webkit-scrollbar {
    width: 0;
  }
}

.profileGroup {
  display: flex;
  flex-direction: column;
  z-index: 50;
  width: 288px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #E6E6EA;

  @include mobile {
    width: 100%;
    position: fixed;
    height: 100vh;
    z-index: 10;
  }

  h4 {
    margin-bottom: 12px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    min-height: 88px;

    svg {
      cursor: pointer;
    }
  }

  .avatar {
    width: 100%;
    min-height: 288px;
    border-radius: 0;

    @include mobile {
      img {
        object-fit: contain;
      }
    }

    & > p {
      font-size: 100px;
    }
  }

  &__detail > * + * {
    margin-top: 16px;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    padding: 20px 24px 24px 24px;

    &_group {
      display: inline-flex;
    }
    &_group > * + * {
      margin-left: 8px;
    }

    .chatGroup__icon {
      display: inline-flex;
      width: 18px;
      height: 18px;
      background-color: #A3A3DB;
      border-radius: 50%;

      & > svg {
        margin: auto;
        width: 12px;
        height: 12px;
      }
      & > svg path {
        fill: white;
      }
    }
  }
}
