.tagsContainer {
  overflow-y: hidden;

  .moduleInfo {
    display: none;
    position: absolute;
    z-index: 2;
    top: 40px;
    width: 30vw;
    background: #F54242;
    box-shadow: 0 12px 48px -16px rgba(119, 0, 0, 0.65);
    border-radius: 12px;
    padding: 20px 16px 20px 32px;

    &.open {
      display: block;
    }

    img {
      position: absolute;
      cursor: pointer;
      right: 16px;
      top: 16px;
    }

    h2 {
      color: #FFFFFF;
    }

    p {
      color: #FFFFFF;
    }
  }

  textarea:active, textarea:focus, input:active, input:focus {
    outline: none;
  }

  textarea::-moz-focus-inner {
    border: 0;
  }

  .loaderContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_header {
    display: flex;
    justify-content: space-between;

    & > button {
      padding: 13px 16px 15px 12px;
    }
  }

  &__table {
    display: flex;
    margin-top: 14px;
    overflow-y: auto;
    height: 100%;
    flex-direction: column;

    &_caption {
      display: grid;
      grid-template-columns: minmax(20%, 196px) auto;
      column-gap: 30px;
      border-bottom: 2px solid #E6E6EA;
      border-radius: 2px;

      & > h5 {
        margin-bottom: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #333333;
      }
    }

    &_tags {
      display: flex;
      flex-direction: column;
    }

    &_item {
      display: flex;
      justify-content: space-between;

      & > .GlobalButton {
        padding: 6px 8px;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    &_container {
      display: grid;
      grid-template-columns: minmax(20%, 196px) auto;
      column-gap: 30px;
      border-bottom: 2px solid #E6E6EA;
      border-radius: 2px;
      padding: 16px 0;
    }

    &_container:first-child {
      padding-top: 20px;
    }

    &_name {
      display: flex;
      align-items: center;
      height: 28px;
      min-width: fit-content;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 4px 8px;
      box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
      border-radius: 6px;

      & > p {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #333333;
      }
    }

    &_description {
      display: flex;
      justify-content: space-between;
      width: 432px;

      & > p {
        white-space: pre-wrap;
        overflow-wrap: break-word;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
        color: #333333;
      }

    }
  }

  &_noneTag {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    &_dude {
      width: 400px;
      height: 400px;
    }

    & > button {
      padding: 13px 16px 15px 12px;
      margin-top: 56px;
    }
  }
}

.GlobalButton.disabled{
  box-shadow: none;
  background: #E0E0E0;
  color: #828282;
}

.createAndEditTag {
  display: none;
  position: absolute;
  z-index: 1;
  width: 24vw;
  right: 30px;
  top: 30px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 12px;
  padding: 24px 20px 16px 24px;

  &.open {
    display: block;
  }

  &_header {
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
  }

  h5 {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
  }

  h6 {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: -0.04em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;
    color: #333333;
  }

  textarea {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'ss02' on;
    color: #333333;
    padding: 8px;
  }

  textarea:focus {
    border-radius: 6px;
    border: 3px solid #8BACFF;
  }

  &_name {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &_text {
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
    }

    textarea {
      resize: none;
      border: none;
      background: #F4F9FF;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      &.error {
        border: 3px solid #ef7676;
      }
    }

    &-error {
      margin-top: 5px;
      color: #F54242;
      font-size: 14px;
    }
  }

  &_description {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &_text {
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
    }

    textarea {
      height: 60px;
      resize: none;
      border: none;
      background: #F4F9FF;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
  }

  .colorItems {
    display: flex;
    flex-flow: wrap row;

    &_item {
      position: relative;
      border-radius: 28px;
      width: 32px;
      height: 32px;
      margin-right: 12px;
      margin-bottom: 12px;

      .dawIcon {
        position: absolute;
        top: 21%;
        left: 21%;
      }
    }
  }

  &_footer {
    margin-bottom: 32px;

    &_preview {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
      border-radius: 6px;
      padding: 4px 8px;
      width: fit-content;
    }
  }

  &_button {
    display: flex;
    justify-content: space-between;

    &_save {
      padding: 6px 12px;
    }

    &_close {
      padding: 6px 12px;
    }
  }
}

.tagsContainer > * {
  padding-right: 48px;
}
