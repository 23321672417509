.broadcasts {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  padding: 32px 0 32px 48px;
  background-color: #FFFFFF;
  box-shadow: -12px 0 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0 0 32px;
  z-index: 2;
}
