@import 'src/mixin.scss';

@include mobile {
  ::-webkit-scrollbar {
    width: 0;
  }
}

.profileContact {
  display: flex;
  flex-direction: column;
  z-index: 50;
  width: 288px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #E6E6EA;

  @include mobile {
    width: 100%;
    position: fixed;
    height: 100vh;
    z-index: 10;
  }

  h4 {
    margin-bottom: 12px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    min-height: 88px;

    svg {
      cursor: pointer;
    }
  }

  .avatar {
    width: 100%;
    min-height: 288px;
    border-radius: 0;

    @include mobile {
      img {
        object-fit: contain;
      }
    }

    & > p {
      font-size: 100px;
    }
  }

  &__detail > * + * {
    margin-top: 16px;
  }

  &__detail {
    display: flex;
    flex-direction: column;
    padding: 20px 24px 24px 24px;

    &_phoneEmail > * + * {
      margin-top: 12px;
    }

    &_phoneEmail {
      display: flex;
      flex-direction: column;
      position: relative;

      .phoneEmail__phoneList > * + * {
        margin-top: 6px;
      }
      .phoneEmail__phoneList {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow-x: hidden;

        &_item {
          display: flex;
          align-items: center;
          width: fit-content;

          svg {
            width: 24px;
            height: 24px;
            margin-right: 6px;
          }
        }

        &_item::after {
          content: "";
          position: absolute;
          width: 32px;
          height: 100%;
          right: 0;
          top: 0;
          background: linear-gradient(90deg, rgba(230, 230, 234, 0) 0%, rgba(230, 230, 234, 0.0086472) 6.67%, rgba(230, 230, 234, 0.03551) 13.33%, rgba(230, 230, 234, 0.0816599) 20%, rgba(230, 230, 234, 0.147411) 26.67%, rgba(230, 230, 234, 0.231775) 33.33%, rgba(230, 230, 234, 0.331884) 40%, rgba(230, 230, 234, 0.442691) 46.67%, rgba(230, 230, 234, 0.557309) 53.33%, rgba(230, 230, 234, 0.668116) 60%, rgba(230, 230, 234, 0.768225) 66.67%, rgba(230, 230, 234, 0.852589) 73.33%, rgba(230, 230, 234, 0.91834) 80%, rgba(230, 230, 234, 0.96449) 86.67%, rgba(230, 230, 234, 0.991353) 93.33%, #E6E6EA 100%);
        }
      }
    }

    .useModalContact {
      @include desktop {
        width: 100%;
      }
      @include mobile {
        flex-direction: column;
        grid-template-rows: 66px 1fr;
        width: 100%;
        height: 60%;
        position: fixed;
        bottom: 0;
        left: 0;
        top: auto;
        padding: 0;

        &__header {
          padding: 20px 24px;
        }

        &__content {
          padding: 0 24px 24px 24px;
          height: 100%;

          textarea {
            height: 100%;
          }
        }

        &__footer {
          flex-direction: column;
          align-items: center;
          padding: 0 24px 24px 24px;
          gap: 16px;

          .GlobalButton {
            width: 100%;
            padding: 13px 16px 15px 16px;
          }
        }
      }
    }

    &_notes {
      position: relative;

      .GlobalButton {
        margin-top: 12px;
        padding: 6px 12px 6px 8px;
      }

      .note__buttons {

        p {
          display: none;
        }

        .note__buttons_edit {
          margin-right: 24px;
        }
      }
    }

    &_tags {
      position: relative;

      @include mobile {
        .addTags {
          padding: 0 24px;

          .dropDownAddTags {
            padding: 16px;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      .GlobalButton {
        margin-top: 12px;
        padding: 6px 12px 6px 8px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: auto;
    padding-bottom: 24px;

    .GlobalButton {
      padding: 13px 16px 15px 16px;

      @include mobile {
        width: 100%;
      }
    }
  }
}
