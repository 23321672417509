.conditionItem {
  display: flex;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px 0 24px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #333333;

  &__title {
    width: 64px;
    font-weight: 700;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  }

  &__condition {
    flex-grow: 1;
    font-weight: 400;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  }

  &__name {
    padding: 4px 8px;
    background: #FFCED4;
    box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    font-weight: 400;
  }

  & > svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-left: 18px;
  }
}
