.whatsApp {

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__connectionsList {
    display: flex;
    flex-direction: column;

    &_title {
      color: #333333;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 16px;
    }
  }
}

.imageQRModal {
  background-color: #FFFFFF;
  width: 364px;
  height: auto;
  border: 1px solid #F2F2F2;
  border-radius: 12px;
  position: absolute;
  top: calc(50vh - 210px);
  right: 68px;
  z-index: 500;
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  cursor: default;

  &_wrapper {
    width: 100%;
    height: 100%;
    cursor: default;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;

    background-color: rgba(0, 0, 0, 0);
  }

  &__title {
    color: #333333;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    margin: 24px 16px 0 32px;
    width: 238px;
  }

  &__close {
    position: absolute;
    top: calc(50vh - 180px);
    right: 84px;
    z-index: 600;
    cursor: pointer;
  }

  &__image {
    width: 360px;
    height: 360px;
    align-self: center;
  }

}

.copyQR {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 24px;

  & > button {
    width: 264px;
    height: 32px;
    padding: 6px 12px 6px 8px !important;
    font-weight: 400 !important;

    & > svg {
      width: 14px;
      height: 14px;

      & path {
        fill: #FFFFFF;
      }
    }
  }
}
