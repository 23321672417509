.catalogsView {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 100vw;
  height: 100vh;
  padding: 32px 0 32px 48px;
  background: #FFFFFF;
  box-shadow: -12px 0 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0 0 32px;
  z-index: 2;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    & > button {
      padding: 13px 16px 15px 16px;
    }
  }
}

.catalogsView > * {
  padding-right: 48px;
}
