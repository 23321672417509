.textMessage {
  margin-bottom: 24px;

  &__field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 648px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 10px 10px 16px 16px;
    margin-bottom: 24px;

    textarea {
      resize: none;
      height: 112px;
      width: 100%;
      background: #FFFFFF;
      border: 0 solid #E0E0E0;
      border-radius: 10px 10px 16px 16px;
      padding: 14px 16px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;
      color: #333333;
      outline: none;
    }

    &_file {
      background: #E5E5EB;
      border-radius: 12px 12px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;

      svg {
        cursor: pointer;
      }
    }

    &_bottom {
      display: flex;
      height: 48px;
      background: #F4F9FF;
      box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.03);
      border-radius: 0 0 16px 16px;
      padding: 14px 20px;
      margin-bottom: 0 !important;

      .emoji-mart {
        position: absolute;
        bottom: 8px;
        z-index: 100;
      }

      .emojiIcon {
        margin-right: 16px;
      }

      .inputFile {
        display: none;
      }

      svg {
        cursor: pointer;
      }

      input {
        width: 100%;
      }

      .limitFile {
        margin-left: 8px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #828282;
      }
    }

    &__errorMessage {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #F54242;
    }

    &.error {
      border: 3px solid #F54242;
    }
  }
}
