@import 'src/mixin.scss';

.createAndEditRequisites {
  display: flex;
  z-index: 10;
  flex-direction: column;
  justify-items: center;
  @include desktop {
    position: absolute;
    top: 68px;
    right: 24px;
    width: 332px;
    border-radius: 12px;
  }
  @include mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 28px 28px 0px 0px;
  }
  padding: 20px 24px 24px 24px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);

  @include mobile {
    &_input::before {
      content: '';
      display: block;
      position: fixed;
      left: 0;
      width: 100%;
      border-bottom: 1px solid #E0E0E0;
    }
    &_input {
      .inputContainer:first-of-type {
        margin-top: 20px;
      }
    }
    &_input {
      .inputContainer {
        width: 100%;
      }
      .inputContainer__wrapper {
        width: 100%;
      }
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
  }

  &_close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
  }

  &_bottom {
    display: flex;
    align-items: center;
    @include desktop {
      justify-content: space-between;
    }
    @include mobile {
      flex-direction: column;
    }

    @include mobile {
      & > button:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  .inputContainer__label {
    @include desktop {
      margin-bottom: 0;
    }
  }

  .inputContainer__input {
    @include desktop {
      width: 264px;
      height: 36px;
    }
    @include mobile {
      width: 100%;
      height: 56px;
    }
    padding-left: 12px;
    font-size: 16px;
  }

  & .GlobalButton {
    @include desktop {
      padding: 6px 12px 6px 12px;
    }
    @include mobile {
      padding: 13px 16px 15px;
      width: 100%;
    }
  }

  @include desktop {
    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
    }
  }
  @include mobile {
    p {
      font-size: 18px;
      line-height: 24px;
      padding-top: 20px;
    }
  }

  h5 {
    margin-bottom: 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }
}
