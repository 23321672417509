.invoiceItem {
  display: grid;
  grid-template-columns: 55% minmax(15%, 2fr) repeat(2, minmax(10%, 1fr));
  column-gap: 24px;
  align-items: center;
  justify-items: start;
  padding: 20px 12px 20px 0;
  border-bottom: 1px #E6E6EA solid;
  margin-right: -12px;

  &:hover {
    cursor: pointer;
    background: #F6F6F6;
    border-radius: 0 0 8px 8px;
  }

  &_order, &_status {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }

  &_date, &_amount {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.03em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
  }

  &_order {
    padding-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &_amount {
    font-weight: 600;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
  }
}
