.createNotes {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__note {

    &_textarea {
      border: none;
      outline: none;
      resize: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      width: 464px;
      padding: 16px 16px 6px 16px;
      background: #F4F9FF;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      min-height: 56px;
      max-height: 240px;
      overflow-y: auto;

      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
    }
  }

  &__button {

    .GlobalButton {
      padding: 6px 8px;
    }
  }
}
