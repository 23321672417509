.permissionsList {
  display: grid;
  grid-template-rows: 20px auto;
  row-gap: 12px;

  &__list{
    display: grid;
    row-gap: 16px;

  }
}
