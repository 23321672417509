.whatsAppCreateConnectionView {
  display: flex;
  flex-direction: column;
  padding-right: 48px;

  & .inputContainer__wrapper {
    & > input {
      width: 648px;
    }
  }

  &__description {
    width: 648px;
    margin-bottom: 16px;

    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-weight: 400;

    & > p {
      margin-top: 24px;
    }
  }

  &__recommendation {
    width: 648px;
    border-radius: 12px;
    padding: 16px 20px;
    background: #FFF4EC;

    h4 {
      margin-bottom: 8px;
      letter-spacing: -0.14px;
    }

    ol {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  &__qrCodeWrapper {
    margin-top: 40px;
    width: 690px;
    display: flex;
    color: #333333;

    &_qrcode {
      position: relative;
      margin-right: 24px;

      & > button {
        cursor: pointer;
        font-family: Inter, sans-serif;
        font-size: 14px;
        min-width: 148px;
        min-height: 32px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        border: none;
        padding: 6px 12px;
        box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.25);
        border-radius: 8px;
      }

      & > img {
        width: 300px;
        height: 300px;
      }
    }

    &_description {
      margin-top: 15px;

      &-title {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 8px;
      }

      &-subdesctiption {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.005em;
        text-align: left;
      }
    }
  }
}
