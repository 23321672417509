.yookassaCreate {
  display: flex;
  flex-direction: column;
  padding-right: 48px;

  &__auth {
    cursor: pointer;
    display: flex;
    align-items: center;
    
    max-width: 363px;
    padding: 13px 24px 15px 16px;
    
    outline: none;
    border: none;
    border-radius: 10px;
    background: #112A7C;
    box-shadow: 0px 4px 4px -2px rgba(3, 0, 141, 0.25);

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.09px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cpsp' on;

    color: #FFFFFF;
    transition-duration: 200ms;

    &:active:not(.disabled) {
      transition-duration: 200ms;
      transform: translate(0em, 0.15em);
    }

    svg{
      margin-right: 16px;
    }
  }

  &__information {
    display: flex;
    margin-top: 24px;

    & > svg {
      margin-right: 8px;
    }
    
    & a {
      color: #FE6D04;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}