.statistics {
  display: flex;
  flex-direction: column;
  color: #333333;

  & > h4 {
    font-family: Inter,sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -.01em;
    -webkit-font-feature-settings: "pnum" on,"lnum" on,"ordn" on,"cv01" on,"cv02" on,"cv05" on,"cv09" on;
    font-feature-settings: "pnum" on,"lnum" on,"ordn" on,"cv01" on,"cv02" on,"cv05" on,"cv09" on;
  }

  &__dates {
    display: flex;
    margin-bottom: 16px;

    &_pick {
      display: flex;
      align-items: center;
      margin-right: 16px;
      white-space: nowrap;
      position: relative;

      img {
        position: absolute;
        top: 18px;
        right: 12px;
        pointer-events: none;
      }

      .react-datepicker-wrapper {
        input {
          width: 12vw;
          cursor: pointer;
        }
      }

      input::-webkit-calendar-picker-indicator {
        color: transparent;
        opacity: 1;
        background: url('../../../../../assets/calendar.svg') no-repeat center;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__counter {
    display: flex;

    &_value {
      margin-right: 8px;
      height: 30px;
      background: #333333;
      box-shadow: 0 5px 2px -4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      border: none;
      padding: 2px 6px;
      color: #FFFFFF;
      font-size: 18px;
      line-height: 26px;
    }
  }
}