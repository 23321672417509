.selectTags {
  display: grid;
  row-gap: 12px;
  max-width: 464px;

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 12px 16px 16px;
    height: 56px;
    border-radius: 6px;
    background: #f4f9ff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) inset;
    color: #828282;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;
    font-feature-settings: 'cv01' on, 'cv02' on, 'cv09' on, 'cv05' on;

    .closeIcon {
      transition-duration: 300ms;
      transform: rotateX(0);

      &.open {
        transition-duration: 300ms;
        transform: rotateX(180deg);
      }
    }
  }

  &__list {
    width: 100%;
    position: absolute;
    left: 0;
    top: 68px;
    display: flex;
    flex-direction: column;
    justify-items: start;
    padding: 8px 0 8px 5px;
    row-gap: 5px;
    border-radius: 6px;
    background: #F4F9FF;
    overflow-y: scroll;
    max-height: 240px;

    .tagContainer {
      cursor: pointer;
      padding: 8px;

      &:hover {
        border-radius: 6px;
        background: #ffe1cc;
      }
    }
  }

  .tag {
    display: flex;
    align-items: center;
    width: fit-content;
    color: #333333;
    padding: 4px 8px;
    gap: 8px;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.40);
    border-radius: 6px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.07px;
    font-feature-settings: 'cpsp' on, 'case' on, 'cv05' on, 'cv02' on, 'cv01' on, 'cv09' on;

    svg {
      width: 12px;
      height: 12px;
      cursor: pointer;

      path {
        fill: #333333;
      }
    }
  }

  &__preview {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
