.sberEditPage {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;

  &__connectionNumber, &__terminalKey {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
  }

  .inputContainer__input {
    width: 400px;
  }

  &_https input {
    width: 560px;
  }

  &__connectionNumber {
    margin-bottom: 12px;
  }

  &__terminalKey {
    margin-bottom: 32px;
  }

  & > button {
    margin-top: 40px;
    padding: 14px 16px 15px;
  }

  .dropdownContainer {
    width: 400px;
  }
}
