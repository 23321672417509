.removeActionButton {
  &__trashIcon {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 26px;
    color: #F54242;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;

      & svg {
        margin-right: 6px;
      }
    }
  }

