.wabaEdit {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  height: calc(100vh - 128px);
  padding-right: 48px;
  color: #333333;

  & > p {
    margin-bottom: 12px;
  }

  & .inputContainer {
    margin-top: 20px;
  }

  & .inputContainer__input {
    width: 600px;
  }

  &__footer {
    margin-top: auto;

    & > button {
      padding: 15px 15px;
    }

    &.clicked {
      pointer-events: none;
    }
  }

  &__catalog {
    display: flex;
    margin-top: 32px;

    & span {
      font-weight: bold;
    }

    &.disconnected, &.expired {
      color: #F54242;

      & > svg path {
        fill: #F54242;
      }
    }

    &.connected {
      color: #0E6EEB;

      & > svg path {
        fill: #0E6EEB;
      }
    }
  }

  &__catalog > * + * {
    margin-left: 12px;
  }
}