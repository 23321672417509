.OpeningIcon {
  position: fixed;
  top: 16px;
  right: 12px;
  cursor: pointer;
  background: #e6e6ea;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.NavbarMobile {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: 100%;
  padding: 24px 20px;
  background: #E6E6EA;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.15);
  z-index: 11;
  transform: translateX(100%);
  animation: vslide 0.5s forwards;

  &_wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #E6E6EA;
    z-index: 8;

    .close {
      position: absolute;
      z-index: 100;
      right: 20px;
      top: 24px;

      path {
        fill: #333333;
      }
    }
  }

  .promotionBanner {
    margin-top: auto;

    img {
      width: 176px;
      height: 118px;
    }
  }

  &__header {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;

    &_logo {
      display: flex;
      justify-content: center;
      align-items: center;

      .companyLogo {

        &__logo {
          width: 32px;
          height: 32px;

          svg {
            width: 32px;
            height: 32px;
          }
        }

        &__id {
          margin: 0 0 0 12px;
        }
      }
    }

    &_close {
      display: flex;
      align-items: center;

      path {
        fill: #333333;
      }
    }
  }

  &__supportMenu {
    margin-top: 32px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #4f4f4f;

    & > div {
      display: flex;
      align-items: center;
      padding: 10px 0;
      width: fit-content;

      a {
        display: flex;
        align-items: center;
        color: #4f4f4f;
        text-decoration: none;
      }

      p {
        padding-left: 0;
      }

      svg {
        margin-right: 6px;
      }
    }

    .logout svg {
      width: 18px;
      height: 18px;
    }

    &_exit {
      color: #e93838;
    }
  }

  &__tabs {
    & svg {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }

    .tab {
      display: flex;
      align-items: center;
      padding: 8px 0;

      p {
        color: #333;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.09px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .tabShadow {
    display: flex;
    justify-content: space-between;

    &.active {
      background: linear-gradient(191.75deg, #e6e6ea 9.05%, #f5f4f3 91.82%);
      box-shadow: 0 2px 8px -2px rgba(63, 75, 138, 0.1);
      border-radius: 12px 12px 20px 20px;
      padding: 8px 10px;
      position: relative;
      left: -10px;
      width: calc(100% + 20px);

      .tab {
        svg {
          path {
            fill: #FE6D04;
          }
        }

        p {
          color: #FE6D04;
        }
      }
    }

    .tab {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }

      p {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cpsp' on;
        color: #333333;
      }
    }
  }

  &.sub {
    background: #FFFFFF;
    transform: translateY(100%);
    animation: vslide 0.4s forwards;

    & svg {
      width: 22px;
      height: 22px;
    }
  }

  .SubMenu {
    margin-top: auto;

    &_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 14px;

      h1 {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
        color: #333333;
      }
    }

    &_back {
      display: flex;
      align-items: center;

      & > svg {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }

      p {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #333333;
      }
    }

    .tabShadow {
      padding: 8px 0;

      &.active {
        background: #FFFFFF;
        box-shadow: 0 2px 8px -2px rgba(63, 75, 138, 0.1);
        border-radius: 10px;
        position: static;
        width: 100%;
      }
    }
  }
}
