.broadcastAlertWarn {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 222;

  &__wrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 70%;
    height: 70%;

    &__alert {
      display: grid;
      grid-template-rows: 42px 1fr 52px;
      background: #FFFFFF;
      box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);
      border-radius: 12px;
      padding: 32px;
      width: 510px;
      min-height: 533px;

      &__title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }

      &__content {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;

        &_text {
          padding-bottom: 20px;

          a {
            color: #FE6D04;
            text-decoration: underline;
            text-underline-color: #FE6D04;
          }
        }
      }

      &__buttons {
        display: flex;
        align-content: center;
        justify-content: space-between;

        button {
          padding: 13px 16px;
        }
      }
    }
  }
}
