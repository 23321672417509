.numbersOptions {
  .radioButton {
    margin-bottom: 8px;
  }

  &__option {
    display: flex;
  }

  &__info {
    position: relative;
    line-height: 24px;

    & > svg path {
      fill: #FE6D04;
    }
    & > svg {
      height: 14px;
      width: 14px;
      margin-left: 8px;
    }

    &_message {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      padding: 20px 18px 20px 24px;
      width: 338px;
      min-height: 80px;
      left: calc(100% - 14px);
      bottom: calc(100% + 12px);
      background: #FFFFFF;
      box-shadow: 0px 12px 48px -16px rgba(0, 0, 0, 0.65);
      border-radius: 12px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
    }
    &:hover &_message {
      display: flex;
      z-index: 200;
    }
  }
}
