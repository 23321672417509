.progressBar {

  &__circle {
    transition: stroke-dashoffset 1s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    animation: rotateCircleAnimation 3s linear infinite normal ;
  }
}

@keyframes rotateCircleAnimation {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg);
  }
}
