.previewProducts {


  &_header {

    p {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
      margin-bottom: 16px;
    }

    h1 {
      margin-bottom: 16px;
      overflow-wrap: break-word;
    }

    h3 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.03em;
      font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
      margin-bottom: 14px;
    }
  }

  &_catalogTitle {
    display: flex;

    img {
      margin-right: 8px;
    }
  }

  &_sliderImage {
    margin: 24px 0;

    .react-slideshow-container {
      width: 350px;
      height: 350px;
      margin: auto;
      justify-content: center;
    }

    .react-slideshow-wrapper.slide {
      border-radius: 4px;
    }

    .arrowSlide {
      display: flex;
      justify-content: center;
      background: #FFFFFF;
      box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      padding: 8px;

      &.disabled path{
        fill: #D1D1D1;
      }

      &.none{
        display: none;
      }
    }

    .arrowSlide:first-of-type {
      margin-left: 16px;
      transform: rotate(180deg);
    }

    .arrowSlide:last-of-type {
      margin-right: 16px;
    }

    .each-slide {
      width: 350px;
      height: 350px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
      }
    }
  }

  &_description {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
    overflow-wrap: break-word;
    white-space: break-spaces;
  }
}
