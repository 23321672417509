.imagesGroup_container {
  cursor: pointer;
  background: #F9F9F9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;

  &.files {
    align-items: stretch;
    padding: 20px;
  }

  .previewIcon {
    margin-bottom: 16px;
    width: 48px;
    height: 48px;
  }

  p {
    margin-bottom: 8px;
  }

  .imageContainer {
    display: flex;
    flex-flow: wrap;

    .previewEmpty {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 66px;
      background: #F2F2F2;
      border: 1px dashed #E0E0E0;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .previewImage {
      display: flex;
      position: relative;
      width: 72px;
      height: 66px;

      &_delete {
        position: absolute;
        display: flex;
        right: 0;
        padding: 4px;
        background: #FFFFFF;
        box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2);
        border-radius: 12px;

        img {
          width: 16px;
          height: 16px;
        }
      }

      &_wrapper {
        display: flex;
        justify-content: center;
        width: 20%;

        &:nth-child(7), &:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11) {
          margin-top: 16px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }
}
