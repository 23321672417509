.statisticPreview {
  margin-top: 30px;
  display: flex;
  align-items: center;

  h6 {
    margin-bottom: 16px;
  }

  &__number {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.03em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;

    &.recipients {
      font-weight: 700;
    }
  }

  &__percent {
    margin-left: 20px;
    padding: 3px 4px 3px 4px;
    height: fit-content;
    background: #f6f6f6;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: -0.04em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on,
      'cpsp' on;
    color: #333333;
  }
}
