.messaging > p + p {
  margin-top: 16px;
}

.messaging {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding-right: 48px;

  &__footer {
    display: flex;
    flex-direction: column;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;

    margin-top: 20px;

    &_switch {
      display: inline-flex;
    }
    
    .GlobalButton {
      padding: 14px 16px;
      margin-top: 40px;
    }
  }
}
