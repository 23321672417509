.interactiveMessageSettings {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;

  & > h1 {
    margin-bottom: 24px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 218px);
    overflow-y: auto;
    margin-bottom: 32px;
    padding-right: 15px;

    &__typesContainer {
      margin-top: 24px;
      width: 510px;

      & > p {
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        color: #333333;
      }
    }

    &__payloadContent {
      margin-top: 24px;
    }

    &__actions {
      margin-bottom: 24px;
    }
  }

  &__footer {
    display: flex;
    margin-top: auto;
    
    & > button {
      padding: 15px 15px;
    }
  }
  &__footer > * + * {
    margin-left: 24px;
  }
}

.errorMessage {
  margin-top: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.005em;
  color: #F54242
}

.interactiveMessageSettings > * {
  padding-right: 48px;
}
