@import 'src/mixin.scss';

.chatHeader {
  position: relative;
  display: flex;
  min-height: 88px;
  width: 100%;
  border-top-left-radius: 32px;
  background: #F6F6F6;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  @include mobile {
    border-top-left-radius: 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &_userInfo {
      display: flex;
      justify-content: center;
      align-items: center;

      &__avatar {
        margin-left: 20px;
        margin-right: 12px;
        border-radius: 24px;
        overflow: hidden;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #a3a3db;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }

        & > p {
          font-weight: 600;
          font-size: 20px;
          color: #FFFFFF;
        }
      }

      &__description {
        cursor: pointer;

        &_name {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.005em;
          color: #333333;
          margin-bottom: 2px;
        }

        &_phone {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.005em;
          color: #333333;
        }
      }
    }

    &_bill {
      margin-right: 30px;

      .GlobalButton {
        padding: 6px 12px 6px 8px;

        @include mobile {
          padding: 8px 12px;
        }
      }
    }
  }

  &__threeDots {
    display: grid;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__wrapperModal {
    position: absolute;
    z-index: 5;

    right: 14px;
    top: 71px;
    box-sizing: border-box;
    box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 12px;

    &__modal {
      display: grid;
      grid-template-rows: repeat(auto-fit, 24px);
      row-gap: 16px;
      padding: 20px;
      cursor: pointer;


      box-sizing: border-box;
      box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      border-radius: 12px;

      &__icon {
        display: grid;
        grid-template-columns: 16px 1fr;
        column-gap: 10px;
        align-content: end;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        color: #333333;

        svg {
          display: grid;
          justify-self: center;
          align-self: end;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.orangeButton {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #FFC093;
  box-sizing: border-box;
  box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.2);
  border-radius: 8px;
  padding: 6px 12px 6px 8px;
  display: flex;
  align-items: center;
  color: #FE6D04;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;

  img {
    margin-right: 8px;
  }
}
