.chatPlug {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 32px 0 0 32px;
  z-index: 7;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.2);

  & .loader {
    width: 80px;
    height: 80px;
  }

  & > p {
    margin-top: 72px;
    font-size: 16px;
    color: #333333;
    font-family: Inter, sans-serif;
  }
}


