.GlobalButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border: none;

  &.isImage {
    img, svg {
      margin-right: 8px;
    }
  }

  &.regular {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cpsp' on;
    border-radius: 10px;

    &.orange {
      color: #FFFFFF;
      background: #FE6D04;
      box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.25);

      &:hover {
        transition-duration: 301ms;
        background: #FA822C;
      }

      &:active {
        color: #FFF4EC;
        background: #FA822C;
        box-shadow: none;
        transition-duration: 301ms;
      }

      &.disabled {
        color: #828282;
        background: #D1D1D1;
        box-shadow: none;
      }
    }

    &.white {
      color: #FE6D04;
      background: #FFFFFF;
      border: 1px solid #FFC093;
      box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.2);

      &:hover {
        transition-duration: 301ms;
        color: #FA822C;
        border: 1px solid #FFE1CC;
      }

      &:active {
        color: #FF9344;
        border: 1px solid #FFE1CC;
        box-shadow: none;
      }

      &.disabled {
        color: #828282;
        border: 1px solid #E0E0E0;
        box-shadow: none;
      }
    }
  }

  &.small {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    border-radius: 8px;

    &.orange {
      color: #FFFFFF;
      font-weight: 400;
      background: #FE6D04;
      box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.25);

      &:hover {
        transition-duration: 301ms;
        background: #FA822C;
      }

      &:active {
        transition-duration: 301ms;
        color: #FFF4EC;
        background: #FA822C;
        box-shadow: none;
      }

      &.disabled {
        color: #828282;
        background: #D1D1D1;
        box-shadow: none;
      }
    }

    &.white {
      color: #FE6D04;
      font-weight: 500;
      background: #FFFFFF;
      border: 1px solid #FFC093;
      box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.2);

      &:hover {
        transition-duration: 300ms;
        color: #FA822C;
        border: 1px solid #FFE1CC;
      }

      &:active {
        transition-duration: 200ms;
        color: #FF9344;
        border: 1px solid #FFE1CC;
        box-shadow: none;
      }

      &.disabled {
        color: #828282;
        border: 1px solid #E0E0E0;
        box-shadow: none;

        img, svg path{
          fill: #828282
        }
      }
    }
  }
}

.GlobalButton:active:not(.disabled) {
  transform: translate(0em, 0.15em);
}
