@import 'src/mixin.scss';

.settingListContainer {
  background-color: #E6E6EA;
}

.settingList {
  min-width: 376px;
  height: 100%;
  background: #F6F6F6;
  border-radius: 20px 0 0 20px;

  @include _1170 {
    min-width: 292px;
  }
}

.settingListContent {
  position: fixed;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include _1170 {
    width: 100%;
    position: relative;
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  & a {
    text-decoration: none;
  }

  & .tabShadow {
    margin: 0;
    height: 88px;
    display: flex;
    align-items: center;
    position: relative;

    :after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 378px;
      height: 1px;
      border-radius: 1px;
      right: 0;
      margin: 0 auto;
      display: block;
      background: linear-gradient(90deg, #E0E0E0 0%, rgba(224, 224, 224, 0.991353) 6.67%, rgba(224, 224, 224, 0.96449) 13.33%, rgba(224, 224, 224, 0.91834) 20%, rgba(224, 224, 224, 0.852589) 26.67%, rgba(224, 224, 224, 0.768225) 33.33%, rgba(224, 224, 224, 0.668116) 40%, rgba(224, 224, 224, 0.557309) 46.67%, rgba(224, 224, 224, 0.442691) 53.33%, rgba(224, 224, 224, 0.331884) 60%, rgba(224, 224, 224, 0.231775) 66.67%, rgba(224, 224, 224, 0.147411) 73.33%, rgba(224, 224, 224, 0.0816599) 80%, rgba(224, 224, 224, 0.03551) 86.67%, rgba(224, 224, 224, 0.0086472) 93.33%, rgba(224, 224, 224, 0) 100%);
    }

    &.active {
      width: 376px;
      background: white;
      position: relative;
      z-index: 8;
      border-radius: 4px 0 0 4px;

      @include _1170 {
        width: 100%;
      }
    }

    &.active::after {
      content: url('../../../assets/top corner.svg');
      width: 8px;
      height: 8px;
      position: absolute;
      top: -16px;
      right: 0;
    }

    &.active::before {
      content: url('../../../assets/bottom corner.svg');
      width: 8px;
      height: 8px;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &.disabled {
      cursor: url("../../../assets/cursor.svg"), auto;
    }

    &.disabled .tab .settingListMiddle {

      & p {
        color: #c5c3c3 ;
      }

      & svg path {
        fill: #c5c3c3;
      }
    }
  }

  .settingListMiddle {
    margin-left: 24px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }

    p {
      color: #333333;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .text_sm {
    color: #333333;
    margin-left: 24px;
    margin-top: 8px;
    margin-bottom: -15px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
}
