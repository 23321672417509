.NotificationCard {
  display: flex;
  flex-direction: column;
  
  &__item {
    display: flex;
    flex-direction: column;
    min-width: 400px;
    
    padding: 24px;
    border: 1px solid #E0E0E0;
    border-radius: 12px;

    &_phone {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      
      & > .phoneField {
        .PhoneInput {
          height: 56px;
        }
      }

      & > .GlobalButton {
        border: 1px solid #FFB3B3 !important;
        padding: 6px 8px;
      }
    }
  }

  & .GlobalButton[type=submit] {
    margin-top: 15px;
    padding: 15px 16px;
  }
}
