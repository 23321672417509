.supportButton {
  cursor: pointer;
  display: flex;
  align-items: center;

  & p {
    padding-left: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #4F4F4F
  }
}
