.templateContent {
  text-align: left;
  background: #F5F4F3;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 12px;
  font-weight: normal;
  flex: 1;
  max-height: 500px;
  overflow-x: auto;

  & > p {
    white-space: pre-wrap;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #333333;
  }

  &__title {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;
      color: #333333;
    }

    input {
      outline: none;
      padding-left: 6px;
      width: 90px;
      height: 28px;
      background: #F4F9FF;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

  }

  &_wabaTemplate {
    white-space: pre-wrap;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;

    input {
      background: #F4F9FF;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      width: 100px;
      height: 25px;
      display: inline-block;
      outline: none;
      margin-top: 5px;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: -0.0005em;
      color: #333333;
      padding-left: 7px;
    }

    &__buttonsContainer {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > a, button {
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        text-decoration: none;
        border: none;
        width: 100%;
        height: 32px;
        background: #2F80ED;
        box-shadow: 0 4px 4px -2px rgba(0, 12, 68, 0.25);
        border-radius: 8px;
        font-family: Inter, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        margin-bottom: 5px;
      }
    }

    &__attachmentsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;


      & input {
        display: none;
      }

      &__label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #FFC093;
        box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        color: #FE6D04;
        cursor: pointer;
        margin-top: 12px;

        & svg {
          width: 12px;
          height: 14px;
          margin-right: 10px;

          & path {
            fill: #FE6D04;
          }
        }
      }

      &__labelInfo {
        margin-top: 8px;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #828282;
      }

      &_attachmentsPreview {
        margin-top: 10px;
        display: flex;
        align-self: flex-start;
        width: 100%;

        &.row {
          flex-wrap: wrap;
        }

        &.list {
          flex-direction: column;
        }

        & > img {
          height: 100px;
          width: 100px;
          object-fit: cover;
        }
      }
    }

    &__footer {
      margin-top: 10px;
      color: #9d9999;
      font-size: 12px;
    }
  }
}
