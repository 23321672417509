.itemStatistic {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  text-align: right;
  color: #333333;

  &__quantity {
    display: flex;
    flex-grow: 1;
    align-self: flex-end;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.03em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  }

  &__percentage {
    display: flex;
    align-self: flex-start;
    width: fit-content;
    height: fit-content;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 4px;
    background: #F6F6F6;
    border: 1px solid #E0E0E0;
    border-radius: 6px;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  }
}

.itemStatistic > * + * {
  margin-left: 8px;
}
