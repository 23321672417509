.profile {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-right: 48px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 28vw;

    .logout p {
      color: #F54242;
      font-variant-numeric: lining-nums proportional-nums stacked-fractions ordinal;
      font-feature-settings: 'cpsp' on, 'cv05' on, 'cv02' on, 'cv01' on, 'cv09' on;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.09px;
    }
  }

  &__content {
    input {
      width: 28vw;
    }

    .languageUI {
      margin-bottom: 48px;

      .uiSelect {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        width: 28vw;
        height: 56px;
        padding: 16px 12px 16px 16px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
        background: #F4F9FF;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 6px;

        &.open {
          border: 3px #8BACFF solid;
          background: #F4F9FF;
          box-shadow: 0 1px 2px 0 #00000033 inset;
          border-radius: 6px;
        }

        .uiSelected {
          position: absolute;
          display: none;
          margin-top: 8px;
          top: 100%;
          left: 0;
          background: #F4F9FF;
          box-shadow: 0 6px 11px rgba(125, 123, 123, 0.04);
          border-radius: 4px;
          z-index: 1;
          width: 28vw;

          &.open {
            display: block;
          }

          &__text {
            display: flex;
            align-items: center;
            padding: 12px 16px 12px 16px;
            cursor: pointer;

            img {
              margin-right: 12px;
            }

            &.active {
              color: #828282;
            }

            &.active:hover {
              background: none;
              cursor: default;
            }
          }

          &__text:hover {
            background: #FFE1CC;
            border-radius: 4px;
          }
        }

        &__text {
          display: flex;
          align-items: center;

          img {
            margin-right: 12px;
          }
        }

        &__arrow {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .changePassword {
      margin-bottom: 40px;

      & > .GlobalButton {
        padding: 14px 24px 15px;
      }
    }

    .notification {
      margin-bottom: 48px;
    }
  }

  .buttonSave {
    margin-top: auto;

    & > .GlobalButton {
      padding: 14px 16px 15px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .profile__content .languageUI .uiSelect {
    width: 60%;
  }
}

@media screen and (max-width: 800px) {
  .profile__content .languageUI .uiSelect {
    width: 50%;
  }
}

@media screen and (max-width: 420px) {
  .profile__content .languageUI .uiSelect {
    width: 40%;
  }
}
