.delayForm {

  display: flex;
  flex-direction: column;
  padding: 12px 16px 20px 16px;
  background: #F5F4F3;
  border-radius: 8px;

  .checkbox {
    padding-left: 24px;
  }

  &__date {
    display: flex;
    justify-content: space-between;

    & > span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      
      text-align: right;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;

      color: #828282;
    }
  }

  .react-datepicker {
    &__header {
      height: 104px;
    }
    
    &__day-names {
        margin-top: 48px;
      }
  
    &__today-button {
      position: absolute;
      top: 42px;
      width: 100%;
      padding: 5px 0;
      cursor: pointer;
      text-align: center;
      font-weight: bold;
      background: #FFFFFF;
      border-top: 0;
    }
  }
}
