.select {
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
  
  & > fieldset {
    border: none;
    position: relative;

    > input {
      cursor: pointer;
      outline: none;
      text-overflow: ellipsis;
    }

    & > .arrowIcon {
      position: absolute;
      right: 8px;
      top: calc(50% - .5em);
      width: 16px;
      height: 16px;
  
      cursor: pointer;
      pointer-events: none;
  
      transition-duration: 300ms;
      transform: rotateX(0);
  
      &.opened {
        transition-duration: 300ms;
        transform: rotateX(180deg);
      }
    }

    & > ul {
      position: absolute;
      top: 52px;
      left: 0;
      max-height: 300px;
      width: 100%;
      margin-top: 8px;
      overflow-y: auto;
      text-decoration: none;
      z-index: 101;
  
      padding: 6px 2px 6px 2px;
      background: #F4F9FF;
      box-shadow: 0 6px 11px rgba(125, 123, 123, 0.04);
      border-radius: 4px;
  
      & > li {
        cursor: pointer;
        text-decoration: none;
        padding: 8px 10px 10px 10px;
        border-radius: 6px;
  
        &:hover {
          background: #FFE1CC;
        }
  
        &.selected {
          background: #FFE1CC;
        }
      }
    }
  }
}
