.chatBill {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #E5E5EB;

  &__wrapper {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border-radius: 32px 0 0 32px;
    z-index: 1;
    box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.2);
  }

  .chatBillContainer {
    margin: 32px 32px 35px 48px;
    z-index: 7;

    &__header {
      display: flex;
      align-items: center;
      flex-flow: row;

      .GlobalButton {
        padding: 6px 12px 6px 8px;
        margin-right: 20px;
      }
    }

    &__content {
      margin-top: 20px;

      .dropdownContainer {
        width: 400px;
      }

      & .inputContainer {
        width: 100%;
        margin-bottom: 0;

        &__wrapper {
          width: 100%;
          & > .inputContainer__input {
            width: 100%;
          }
        }
      }

      .comboList, .lableInfo {
        width: 400px;
        margin-bottom: 20px;
      }
      
      .phoneField {
        .PhoneInput {
          height: 56px;
          width: 400px;
        }
      }

      .inputBill_contentList:hover {
        display: block;
      }

      .textareaContainer_textarea {
        width: 416px;
        height: 148px;
      }

      &_error {
        margin-top: 12px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        color: #F54242;
      }

      h2 {
        margin-top: 48px;
      }

      &_bottom {
        display: flex;
        margin: 62px 0;

        .GlobalButton {
          padding: 13px 16px 15px;

          &:last-child {
            margin-left: 24px;
          }
        }
      }

      .dropdownContainer__dropdown_content__item_logo {
        width: 28px;
        height: 28px;
      }

      .dropdownContainer__dropdown_logo {
        width: 42px;
        height: 37px;
      }
    }
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  input {
    padding-left: 16px;
    padding-right: 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cv08' on, 'case' on;
    color: #333333;
  }
}
