@import 'src/mixin.scss';

.contactCard {
  display: grid;
  grid-template-rows: 48px 1fr;

  .GlobalButton {
    margin-top: 12px;
    padding: 6px 12px 6px 8px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 160px 1fr;
    column-gap: 40px;
    padding: 32px 48px;
    min-height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    color: #333333;
    background: #FFFFFF;
    box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.05);
    border-radius: 32px 0 0 32px;
  
    h4 {
      margin-bottom: 8px;
    }
  }

  &__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 152px;
    width: 152px;
    background: #a3a3db;
    border-radius: 50%;

    .avatar {
      width: 100%;
      height: 100%;
    }
  
    p {
      font-weight: 600;
      font-size: 80px;
      color: #FFFFFF;
    }
  
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__photoContainer {
    display: grid;
    grid-template-rows: 160px 1fr;
    row-gap: 18px;

    .GlobalButton {
      padding: 5px 10px;
    }
  }
  
  &__wrap {
    display: grid;
    grid-template-rows: minmax(80px, auto) minmax(60px, auto) minmax(88px, auto) minmax(15px, auto) minmax(200px, auto);
  }
  
  &__name {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 44px;
    letter-spacing: -0.01em;
  }

  &__phone {
    display: grid;
    grid-template-columns: 31px 1fr;
  
    &__icon {
      margin: 8px 8px 8px 0;
    }
  
    &__numbers p {
      margin: 8px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.03em;
    }
  }

  &__emails {
    display: grid;
    grid-template-columns: 31px 1fr;
  
    &__icon {
      margin: 8px 8px 8px 0;
    }
  
    &__list p {
      margin: 8px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.03em;
    }
  }

  &__tags {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  
    .tagsList {
  
      &.empty {
        height: 32px;
      }
    }
  
    .useModalContact {
      width: 312px;
    }
  }

  &__notes {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  
    .notesList {
  
      &.empty {
        height: 32px;
      }
    }
  
    .note {
      width: 550px;
    }
  
    &__wrapperAddNote {
      position: relative;
    }
  }

  &__listChats__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 14px;
  }
}

.useModalContact {
  display: grid;
  grid-template-rows: 34px 1fr 56px;
  z-index: 12;
  padding: 20px 24px 24px 24px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 12px;
  position: absolute;

  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    h3 {
      width: 100%;
    }

    svg {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }

  &__content textarea {
    border: none;
    overflow: auto;
    outline: none;
    resize: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;

    width: 100%;
    height: 112px;
    padding: 8px;
    background: #F4F9FF;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    color: #333333;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.09px;
    font-feature-settings: 'cv01' on, 'cv02' on, 'cv09' on, 'cv05' on;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    .GlobalButton {
      padding: 6px 12px;
    }
  }
}

