.addTemplateView {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;

  & > h1 {
    margin-bottom: 24px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 186px);
    overflow-y: scroll;

    &_nameTextField, &_textTextField {

      & > label {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
        margin-bottom: 12px;
      }
    }

    &_nameTextField, &_textTextField__content {
      display: flex;
      flex-direction: column;
    }

    &_textTextField {
      display: flex;
      flex-direction: column;

      &__content {
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
        background: #F4F9FF;
        border-radius: 6px;

        & > textarea {
          margin-bottom: 0;
          box-shadow: none;
          background-color: transparent;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        &__footer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          background-color: transparent;
          width: 100%;

          &_buttonGroup {
            display: flex;
            justify-self: flex-end ;
            margin-right: 16px;
            position: relative;
            justify-content: space-between;
            width: 60px;

            & #file-attachment {
              opacity: 0;
              width: 0;
              height: 0;
              margin: 0;
              padding: 0;
            }

            & > label {
              position: absolute;
              top: -56px;
              right: 28px;
              
              & > svg {
                width: 14px;
                height: 16px;
              }
            }

            & > svg, & > label svg {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    margin-top: auto;

    & > button {
      padding: 15px 15px;
    }

    &.clicked {
      pointer-events: none;
    }
  }

  &__footer > * + * {
    margin-left: 24px;
  }

  &__attachmentsPreview {
    max-width: 200px;
    margin-bottom: 32px;
  }
}

.addTemplateView > * {
  padding-right: 48px;
}

