@import 'src/mixin.scss';

.subscriptionsInvoicePay {
  background: #FFFFFF;

  h1 {
    margin-bottom: 16px;
  }

  h3 {
    margin-bottom: 12px;
  }

  & > p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    img {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }

  &__period {
    margin-bottom: 40px;

    &_month {
      display: flex;
      align-items: flex-end;

      .radioButton {
        width: 138px;
      }
      .discount {
        padding: 2px 4px;
        background: #2CBA5F;
        box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
        border-radius: 6px;

        font-weight: 500;
        color: #FFFFFF;
      }

      & .addon {
        display: inline-flex;
      }

      & .addon > * + * {
        margin-left: 10px;
      }
    }

    &_month > * + * {
      @include desktop {
        margin-top: 8px;
      }
      @include mobile {
        margin-top: 12px;
      }
    }
  }

  &__subscriptions {

    @include desktop {
      &_container {
        display: flex;
        flex-direction: column;

        &_title {
          display: grid;
          grid-template-columns: 40% minmax(20%, 1fr) repeat(2, minmax(20%, 1fr));
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.005em;
          font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
          color: #333333;
          padding-bottom: 12px;
          border-bottom: 2px solid #E6E6EA;
          border-radius: 1px;

          :nth-last-child(-n+3) {
            display: flex;
            justify-content: flex-end;
          }

          & > th {
            text-align: left;
          }
        }

        &_bottom {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 16px;

          h2 {
            margin: 0 12px 0 0;
          }
        }
      }
    }
  }

  &__promoCode {
    margin-top: 20px;

    & > p {
      cursor: pointer;
      color: #FE6D04;
      text-decoration: underline;
    }

    & > p:active {
      transform: translate(0em, 0.15em);;
    }

    &_content {
      & > span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #333333;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    @include desktop {
      margin-top: 64px;
    }
    @include mobile {
      margin-top: 40px;
    }

    &.clicked {
      pointer-events: none;
    }

    & > button {
      padding: 13px 16px 15px;
      @include mobile {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }
}

.amountStyle {
  display: flex;
  @include desktop {
    justify-content: flex-end;
  }
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.03em;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  color: #333333;
}

@include desktop {
  .leftStyle {
    display: flex;
    justify-content: flex-end;
  }
}
