.itemStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .GlobalButton {
    padding: 6px 8px;

    & > svg path {
      fill: #FE6D04;
    }
  }

  &__info {
    position: relative;
    display: inline-flex;

    & > svg path {
      fill: #F54242;
    }
    & > svg {
      height: 16.67px;
      width: 16.67px;
      margin-left: 8px;
    }

    &_message {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      padding: 20px 22px 20px 24px;
      width: fit-content;
      white-space: nowrap;
      right: 100%;
      bottom: -10px;
      background: #FFFFFF;
      box-shadow: 0px 12px 48px -16px rgba(0, 0, 0, 0.65);
      border-radius: 12px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
    }
    &:hover &_message {
      display: flex;
    }
  }

  &__content {
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'cv05' on, 'cv09' on, 'cv02' on, 'cv01' on;

    &.deliver, &.finish {
      color: #333333;
    }
    
    &.error {
      color: #F54242;
    }
    
    & > button {
      display: flex;
      align-items: center;
      cursor: pointer;
      outline: none;
      border: none;
      background: transparent;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #F54242;

      & > svg {
        margin-right: 4px;
      }
    }
  }
}
