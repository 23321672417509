@import 'src/mixin.scss';

.chatContent {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: calc(100vh - 288px);
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 20px 20px 20px;

  @include mobile {
    padding: 0 8px 20px 8px;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;

    &__sticky {
      position: sticky;
      top: 0;
      padding: 1.5rem;
      z-index: 2;

      &.active {
        animation: activeSticky 1s forwards;
      }

      &.remove {
        opacity: 0;
        animation: removeSticky 0.2s ease-in alternate;
      }

      @keyframes activeSticky {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      @keyframes removeSticky {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
          display: none;
        }
      }

      &__messagesDate {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        &__date {
          font-size: 14px;
          color: white;
          font-weight: 600;
          padding: 6px 10px;
          border-radius: 16px;
          background-color: hsla(0, 0%, 30%, 0.4);
          z-index: 2;
        }

      }

    }

    &__sentinel {

      &_top {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;

        display: flex;
        align-items: center;
        justify-content: center;
        //покажет границу пересечения видимости top

        //background: yellowgreen;
      }

      &_bottom {
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        //покажет границу пересечения видимости bottom

        //background: yellowgreen;
      }
    }

  }

  &::-webkit-scrollbar {
    width: 12px;

    @include mobile {
      width: 0;
    }
  }
}
