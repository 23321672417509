.broadcastsPlug {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  & > p {
    text-align: center;
    white-space: pre-line;
    margin-top: 8px;
  }

  & > .GlobalButton {
    padding: 13px 16px 15px;
    margin-top: 40px;
  }
}