.plan_list {
  display: grid;
  
  &__header, &__item {
    display: grid;
    grid-template-columns: minmax(23%, 270px) minmax(18%, 210px) auto;
    grid-column-gap: 30px;
  }

  &__header {
    padding-bottom: 12px;
  }

  &__item {
    padding: 16px 0;
    border-bottom: 2px solid #E6E6EA;
  }

  &__item:first-child {
    border-top: 2px solid #E6E6EA;
  }
}
