.documentPreviewContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  background: #E5E5EB;
  border-radius: 0 8px;

  &__documentName {
    width: 100%;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #333333;
    position: relative;
    margin-left: 12px;
    overflow: hidden;
    white-space: nowrap;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 32px;
      height: 20px;
      background: linear-gradient(90deg, rgba(229, 229, 235, 0) 0%, rgba(229, 229, 235, 0.0086472) 6.81%, rgba(229, 229, 235, 0.03551) 13.61%, rgba(229, 229, 235, 0.0816599) 20.42%, rgba(229, 229, 235, 0.147411) 27.22%, rgba(229, 229, 235, 0.231775) 34.03%, rgba(229, 229, 235, 0.331884) 40.83%, rgba(229, 229, 235, 0.442691) 47.64%, rgba(229, 229, 235, 0.557309) 54.44%, rgba(229, 229, 235, 0.668116) 61.25%, rgba(229, 229, 235, 0.768225) 68.06%, rgba(229, 229, 235, 0.852589) 74.86%, rgba(229, 229, 235, 0.91834) 81.67%, rgba(229, 229, 235, 0.96449) 88.47%, rgba(229, 229, 235, 0.991353) 95.28%, #E5E5EB 102.08%);
    }
  }

  &__trashIcon {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 8px;
  }
}

