.paginator {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  &__settings {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.03em;
    font-feature-settings: "tnum" on, "lnum" on, "ordn" on, "cv01" on, "cv02" on, "cv05" on, "cv09" on, "case" on, "cpsp" on;
    color: #333333;

    &_list {
      position: absolute;
      width: 80px;
      bottom: 50px;
      background: #F4F9FF;
      box-shadow: inset 0 1px 2px rgb(0 0 0 / 20%);
      border-radius: 6px;
    }

    &_item {
      align-items: center;
      justify-items: center;
      cursor: pointer;
      padding: 5px;

      &:hover {
        background: #FFE1CC;
        border-radius: 6px;
      }
    }

    &_field {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      padding: 8px;
      width: 80px;
      background: #F4F9FF;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
    }
  }
}
