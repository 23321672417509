.showMore {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 32px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  
  letter-spacing: -0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
  
  color: #333333;

  & svg {
    margin-left: 8px;
  }
}
