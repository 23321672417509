.permissionItem {
  display: grid;
  row-gap: 4px;

  &__withDescription{
    display: grid;
    &__checkTitle{
      display: flex;
      align-items: center;
    }

    &__text{
      display: grid;
      padding-left: 36px;
    }

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &_title{
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    label{
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__withAccess{
    display: grid;
    row-gap: 4px;

    &__title{
      padding-left: 36px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;
    }
    &__accessList{
      display: flex;
      column-gap: 12px;

      &__item{
        display: flex;

        &_switch{

        }
        &_name{
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.005em;
          font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;
        }
      }
    }

  }
}
