@import 'src/mixin.scss';

.requisites__list {
  @include desktop {
    display: grid;
  }

  &_header {
    display: grid;
    grid-template-columns: 30% minmax(20%, 1fr) minmax(20%, 1fr);
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
    padding-bottom: 12px;
    border-bottom: 2px #E6E6EA solid;
    border-radius: 1px;

    .smallText {
      text-align: start;
    }
  }

  &_item {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;

    @include desktop {
      display: grid;
      grid-template-columns: 30% minmax(20%, 1fr) minmax(20%, 1fr);
      padding-top: 20px;
      padding-bottom: 16px;
      border-bottom: 1px #E6E6EA solid;
      border-radius: 1px;
    }

    &_text {
      overflow: hidden;
      margin-right: 16px;
    }

    .GlobalButton {
      padding: 6px 8px 6px 8px;
    }

    .GlobalButton img {
      margin: 0;
    }

    .GlobalButton:first-child {
      border: 1px solid #E0E0E0;
    }

    .GlobalButton:last-child {
      border: 1px solid #FFB3B3;
    }

    .approved, .declined, .pending {
      display: flex;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
      }

      & div {
        display: flex;

        @include mobile {
          margin-top: 12px;
        }
      }

      & div > * + * {
        margin-left: 8px;
      }
    }

    .approved {
      color: #2CBA5F;
    }

    .pending {
      color: #333333;
    }

    .declined {
      color: #EB5757;
    }

    @include desktop {
      .approved > * + * {
        margin-left: 8px;
      }
      .pending > * + * {
        margin-left: 8px;
      }
      .declined > * + * {
        margin-left: 8px;
      }
    }
  }

  &_item > div:last-child {
    display: flex;
    justify-content: flex-end;
  }
}

.ModalDeleteRequisites {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  @include desktop {
    position: fixed;
    left: 50%;
    width: 486px;
    border-radius: 12px;
  }
  @include mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 28px 28px 0 0;
  }
  padding: 20px 16px 32px 32px;
  box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);

  &_header {
    display: flex;
    justify-content: space-between;

    h4 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 700;

      @include desktop {
        font-size: 24px;
        line-height: 32px;
      }
      @include mobile {
        font-size: 18px;
        line-height: 26px;
      }
      letter-spacing: -0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      color: #333333;
      margin-bottom: 8px;
    }

    img {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }

  @include desktop {
    p {
      white-space: pre-line;
    }
  }

  @include mobile {
    &_content::before {
      content: '';
      display: block;
      position: fixed;
      left: 0;
      width: 100%;
      border-bottom: 1px solid #E0E0E0;
    }

    p {
      padding-top: 20px;
    }
  }

  &_bottom {
    display: flex;
    align-items: center;
    margin-top: 24px;

    @include desktop {
      margin-right: 50px;
      justify-content: space-between;
    }
    @include mobile {
      flex-direction: column;
    }

    @include mobile {
      & > button:first-of-type {
        margin-bottom: 16px;
      }
    }

    & .GlobalButton {
      padding: 13px 16px 15px 16px;

      @include mobile {
        width: 100%;
      }
    }
  }
}
