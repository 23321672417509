.catalogsCard {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 552px;
  padding: 20px 0 20px 24px;
  margin-bottom: 1px;
  background: #EAF3FF;
  box-sizing: border-box;
  box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #333333;
    margin-bottom: 4px;
  }

  &__products {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #333333;
  }

  &__buttonsGroup {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    align-self: self-end;

    & > button {
      cursor: pointer;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      padding: 10px 16px;
      border: none;
      outline: none;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;

      & > svg {
        margin-right: 6px;
      }
    }

    &>button:first-of-type {
      border-radius: 12px 0 0 0;
    }

    &_editButton {
      color: #333333;
    }

    &_deleteButton {
      color: #F54242;
    }
  }
}
