.textArea {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;

    &_title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.005em;
      color: #333333;
    }

    &_subtitle {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      letter-spacing: -0.005em;
      color: #333333;
    }
  }

  &__textarea {
    background: #F4F9FF;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    resize: none;
    border: none;
    outline: none;
    font-family: Inter, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    color: #333333;
    padding: 15px;
  }
}

.pickerIcon {
  cursor: pointer;
  position: absolute;
  right: 18px;
  bottom: 15px;
}

