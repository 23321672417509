.conversation {
  min-height: 85px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: -3px;
  width: calc(100% + 3px);

  &__arrowIcon {
    cursor: pointer;
    position: absolute;
    top: 26px;
    right: 20px;
    height: 16px;
    width: 16px;
    z-index: 3;

    &.active {
      transform: rotateX(180deg);
    }
  }

  &::after {
    content: "";
    width: 90%;
    height: 1px;
    background-color: #E0E0E0;
    position: absolute;
    bottom: -1px;
    left: 18px
  }

  &__content {
    display: flex;
    flex-grow: 1;
    position: relative;
    cursor: pointer;
    min-height: 85px;
    padding: 20px 20px 0 20px;

    &.active {
      border-bottom: 1px solid #E0E0E0;
      background: #FFFFFF;
      box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.12);
      border-radius: 4px 0 0 4px;
      z-index: 2;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 8px;
        height: 100%;
        top: 0;
        left: -2px;
        background: #FFFFFF;
        box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.12);
        border-radius: 4px 0 0 4px;
      }

      &:after {
        content: url("../../../../assets/top corner.svg");
        width: 8px;
        height: 8px;
        position: absolute;
        top: -15px;
        right: 0;
      }

      & .conversation__content__description {

        &_upperBlock {
          &__username {
            white-space: nowrap;
            max-width: 213px;

            &::after {
              background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.81%, rgba(255, 255, 255, 0.03551) 13.61%, rgba(255, 255, 255, 0.0816599) 20.42%, rgba(255, 255, 255, 0.147411) 27.22%, rgba(255, 255, 255, 0.231775) 34.03%, rgba(255, 255, 255, 0.331884) 40.83%, rgba(255, 255, 255, 0.442691) 47.64%, rgba(255, 255, 255, 0.557309) 54.44%, rgba(255, 255, 255, 0.668116) 61.25%, rgba(255, 255, 255, 0.768225) 68.06%, rgba(255, 255, 255, 0.852589) 74.86%, rgba(255, 255, 255, 0.91834) 81.67%, rgba(255, 255, 255, 0.96449) 88.47%, rgba(255, 255, 255, 0.991353) 95.28%, #FFFFFF 102.08%);
            }
          }
        }

        &_bottomBlock {
          &__lastMessageText {
            &::after {
              background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.81%, rgba(255, 255, 255, 0.03551) 13.61%, rgba(255, 255, 255, 0.0816599) 20.42%, rgba(255, 255, 255, 0.147411) 27.22%, rgba(255, 255, 255, 0.231775) 34.03%, rgba(255, 255, 255, 0.331884) 40.83%, rgba(255, 255, 255, 0.442691) 47.64%, rgba(255, 255, 255, 0.557309) 54.44%, rgba(255, 255, 255, 0.668116) 61.25%, rgba(255, 255, 255, 0.768225) 68.06%, rgba(255, 255, 255, 0.852589) 74.86%, rgba(255, 255, 255, 0.91834) 81.67%, rgba(255, 255, 255, 0.96449) 88.47%, rgba(255, 255, 255, 0.991353) 95.28%, #FFFFFF 102.08%);
            }
          }
        }

        &::after {
          content: url("../../../../assets/bottom corner.svg");
          width: 8px;
          height: 8px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 12px;
      margin-top: 2px;
      margin-bottom: -1px;
      border-bottom: 1px solid #E0E0E0;

      & .active {
        border: none;
      }

      &_upperBlock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &__username {
          position: relative;
          font-family: Inter, sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.005em;
          color: #333333;
          overflow: hidden;
          height: 24px;
          flex: 1;
          white-space: nowrap;
          max-width: 213px;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 32px;
            height: 30px;
            right: 0;
            background: linear-gradient(90deg, rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0.0086472) 6.81%, rgba(246, 246, 246, 0.03551) 13.61%, rgba(246, 246, 246, 0.0816599) 20.42%, rgba(246, 246, 246, 0.147411) 27.22%, rgba(246, 246, 246, 0.231775) 34.03%, rgba(246, 246, 246, 0.331884) 40.83%, rgba(246, 246, 246, 0.442691) 47.64%, rgba(246, 246, 246, 0.557309) 54.44%, rgba(246, 246, 246, 0.668116) 61.25%, rgba(246, 246, 246, 0.768225) 68.06%, rgba(246, 246, 246, 0.852589) 74.86%, rgba(246, 246, 246, 0.91834) 81.67%, rgba(246, 246, 246, 0.96449) 88.47%, rgba(246, 246, 246, 0.991353) 95.28%, #F6F6F6 102.08%);
          }
        }

        &__lastMessageDate {
          display: flex;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          text-align: right;
          letter-spacing: 0.3px;
          color: #4F4F4F;
          padding-right: 25px;
        }
      }

      &_bottomBlock {
        display: flex;
        justify-content: space-between;
        margin-top: 4px;

        &__lastMessageText {
          position: relative;
          overflow: hidden;
          white-space: nowrap;
          font-family: Inter, sans-serif;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.005em;
          color: #333333;
          width: 237px;
          flex-grow: 1;

          &.unread {
            font-weight: 600;
          }

          &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 32px;
            height: 30px;
            right: 0;
            background: linear-gradient(90deg, rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0.0086472) 6.81%, rgba(246, 246, 246, 0.03551) 13.61%, rgba(246, 246, 246, 0.0816599) 20.42%, rgba(246, 246, 246, 0.147411) 27.22%, rgba(246, 246, 246, 0.231775) 34.03%, rgba(246, 246, 246, 0.331884) 40.83%, rgba(246, 246, 246, 0.442691) 47.64%, rgba(246, 246, 246, 0.557309) 54.44%, rgba(246, 246, 246, 0.668116) 61.25%, rgba(246, 246, 246, 0.768225) 68.06%, rgba(246, 246, 246, 0.852589) 74.86%, rgba(246, 246, 246, 0.91834) 81.67%, rgba(246, 246, 246, 0.96449) 88.47%, rgba(246, 246, 246, 0.991353) 95.28%, #F6F6F6 102.08%);
          }
        }
      }
    }
  }

  &__chatsListContainer {
    display: flex;
    flex-direction: column;

    &_createChatButton {
      position: relative;
      cursor: pointer;
      font-family: Inter, sans-serif;
      display: flex;
      align-items: center;
      background: #F6F6F6;
      outline: none;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      color: #333333;
      margin: 10px 20px 15px 79px;

      & > svg {
        margin-right: 4px;
        width: 15px;
        height: 15px;

        & > path {
          fill: #333333;
        }
      }
    }
  }
}


