.inputsEmails {
 
  & > p.regularText {
    margin-bottom: 12px;
  }

  .emailItem {
    display: flex;
    align-items: baseline;

    .GlobalButton {
      margin-left: 16px;
      padding: 6px 8px;
      border: 1px solid #ffb3b3;
    }

    .inputContainer {
      margin-bottom: 0;
    }
  }

  &__footer {
    margin-top: 16px;

    .GlobalButton {
      padding: 6px 8px;
    }
  }
}

.inputsEmails > div.emailItem + div.emailItem {
  margin-top: 8px;
}
