.interactiveMessageTemplateCardBadges {
  background: #1884FF;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 6px;

  & svg path {
    fill: #FFFFFF;
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #FFFFFF;
    margin-left: 6px;
  }
}

