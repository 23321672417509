.catalogsContent {
  display: grid;
  grid-template-areas: 'header' 'list' 'bottom';
  grid-template-rows: 0fr 1fr 50px;
  width: 100vw;
  padding: 32px 0 20px 48px;
  background: #FFFFFF;
  box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.2);
  border-radius: 32px 0px 0px 32px;

  & .load {
    display: grid;
    grid-area: list;
    justify-content: center;
    align-items: center;
    height: 60vh;
    position: inherit;
    z-index: 500;
    background-color: rgba(0, 0, 0, 0);
  }

  & .paginator {
    grid-area: bottom;
    padding-right: 48px;
  }

  & > h3 {
    margin-bottom: 32px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }

  &__header {
    grid-area: header;
    display: flex;
    max-width: 1116px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-right: 48px;

    &_left {
      display: inline-flex;
      align-items: center;

      & > button {
        padding: 6px 12px 6px 8px;
        margin-right: 24px;
      }

      & > svg {
        height: 16.899993896484375px;
        width: 22.272724151611328px;
        margin-right: 8px;
      }
    }

    & .search {
      &__input {
        width: 301px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-shadow: inset 0px 2px 4px -2px rgba(0, 0, 0, 0.3);
        border-radius: 24px;
      }
    }
  }
}
