@import 'src/mixin.scss';

.pauseWarn {
  display: flex;
  flex-direction: column;
  @include desktop {
    position: absolute;
    z-index: 555;
    width: 510px;
    height: 452px;
    left: 99px;
    top: 60px;
    border-radius: 12px;
  }
  @include mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 28px 28px 0px 0px;
  }
  padding: 20px 32px 32px 32px;
  background: #FFFFFF;
  box-shadow: 0px 12px 48px -16px rgba(0, 0, 0, 0.65);
  
  &__wrapper {
    width: 100%;
    height: 100%;
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 500;

    background-color: rgba(0, 0, 0, 0);
  }

  @include mobile {
    &__content::before {
      content: '';
      display: block;
      position: fixed;
      left: 0;
      width: 100%;
      border-bottom: 1px solid #E0E0E0;
    }

    p {
      padding-top: 20px;
    }
  }

  &__title {
    margin-bottom: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    @include desktop {
      font-size: 24px;
      line-height: 32px;
    }
    @include mobile {
      font-size: 18px;
      line-height: 26px;
    }
    letter-spacing: -0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }

  & p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    @include desktop {
      margin-bottom: 24px;
    }       
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
    color: #333333;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    @include mobile {
      margin-top: 32px;
      flex-direction: column;
    }

    @include mobile {
      & > button:first-of-type {
        order: 2;
        margin-top: 16px;
      }
    }

    & .GlobalButton {
      padding: 13px 16px 15px 16px;
      @include mobile {
        width:  100%;
      }
    }
  }

  &__close {
    position: absolute;
    right: 24px;

    img {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
}
