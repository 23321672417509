.connectionCard {
  position: relative;
  margin-bottom: 16px;
  padding: 20px 0 20px 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 124px;
  background: #F5F4F3;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  color: #333333;

  &.disabled {
    color: #828282;
  }

  .statusBar {
    position: absolute;
    right: 42px;
    top: 16px;
  }

  .connectionNameEdit__buttonsWrapper {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  
  &__title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-size: 18px;
    margin-bottom: 8px;
  }

  &__connectionId {
    font-size: 18px;
    margin-bottom: 8px;
  }

  &__buttonsGroup {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-self: self-end;
    justify-content: space-around;
    background: #FFFFFF;
    border-radius: 12px 0 0 0;

    &_editButton, &_deleteButton {
      cursor: pointer;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border: none;
      outline: none;
      padding: 10px 16px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;

      & > svg {
        margin-right: 6px;
      }
    }

    &_editButton {
      color: #333333;

      &:disabled {
        color: #D9D0D0;
        
        & > svg path {
          fill: #D9D0D0;
        }
      }
    }

    &_deleteButton {
      color: #F54242;

      &:disabled {
        opacity: 0.5;
      }
    }

    & > button:first-of-type {
      border-radius: 12px 0 0 0;
    }
  }
}
