.registrationAllContainer{
  display: flex;
  width: 100%;
  height: 100vh;
  background: #FE6D04;

  .inputStandard{
    padding: 16px;
    background: #F4F9FF;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border: none;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
  }

  .preset {
    color: #828282;
  }

  .registrationImgContainer{
    display: flex;
    position: relative;
    flex-direction: column;

    &_logo{
      position: absolute;
      top: 20px;
      left: 24px;
      width: 27px;
      height: 28px;
    }
  }

  .registrationRightContainer{
    display: flex;
    width: 100%;
    padding: 34px 40px 34px 40px;
    flex-direction: column;
    background: linear-gradient(0deg, #F6F6F6, #F6F6F6);
    box-shadow: -12px 0px 48px -24px rgba(0, 0, 0, 0.2);
    border-radius: 32px 0 0 32px;

    .registrationRightContent{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__errorMessage{
        margin-top: 10px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.005em;
        color: #F54242;
      }

      &__attention {
        margin-top: 38px;
        max-width: 480px;

        &_lastBlock {
          margin-top: 20px;
        }
      }

      &_invitation{
        width: 464px;

        &_button{
          display: flex;
          justify-content: space-between;
          margin-top: 56px;

          & > .GlobalButton {
            padding: 13px 16px 15px 16px;
          }
        }
      }

      &_invitationCancel{
        display: grid;
        grid-template-rows: auto auto;
        row-gap: 20px;
        span {
          font-weight: bold;
        }

      }

      &__create {
        position: relative;

        &_name {
          & .inputContainer {

            & > .inputContainer__wrapper {
              width: 464px;
          
              input {
                width: 464px;
              }
            }
          }
        }

        &_phone {
          display: flex;

          & .phoneField {
            .PhoneInput {
              height: 56px;
              width: 300px;
              border: 1px solid #cacaca;
              border-radius: 6px;

              & .PhoneInputCountySelect {
                height: 56px;
              }
              
              & > input {
                height: 56px;
                font-family: Inter, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
              }
            }
            .error.PhoneInput {
              & > input {
                border-top: 3px solid #F54242;
                border-right: 3px solid #F54242;
                border-bottom: 3px solid #F54242;
                border-left: none;
              }
              & .PhoneInputCountySelect {
                border-top: 3px solid #F54242;
                border-left: 3px solid #F54242;
                border-bottom: 3px solid #F54242;
                border-right: none;
              }
            }
          }
        }

        &_promocode {

          & > p {
            cursor: pointer;
            text-decoration-line: underline;
            color: #FE6D04;
          }
        }

        &_phone, &_promocode {
          margin-bottom: 24px;
        }

        .GlobalButton {
          padding: 13px 16px 15px;
        }
      }
    }
    
    &_center{
      display: flex;
      flex-direction: column;
      margin-left: 88px;

      h1{
        margin-bottom: 24px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 56px;
        line-height: 72px;
        letter-spacing: -0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'ordn' on, 'cv06' on, 'cv07' on, 'cv08' on;
        color: #333333;
      }
      &_top{
        display: flex;
        flex-direction: column;
        width: 464px;
        p{
          margin-bottom: 12px;
        }
        input{
          margin-bottom: 32px;
        }
        button{
          margin-bottom: 64px;
        }
      }
      &_bottom{
        display: flex;
        flex-direction: column;
        align-items: center;

        p::after{
          content: '';
          display: inline-block;
          margin-bottom: 4px;
          margin-left: 12px;
          background: #E6E6EA;
          border-radius: 1px;
          width: 204px;
          height: 2px;
        }
        p::before{
          content: '';
          display: inline-block;
          margin-bottom: 4px;
          margin-right: 12px;
          background: #E6E6EA;
          border-radius: 1px;
          width: 204px;
          height: 2px;
        }
        button{
          margin-top: 38px;
          width: 100%;
        }
      }
      & > p {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
        color: #333333;
      }
    }
    &_logout{
      display: grid;
      align-content: end;
      height: 100%;

      &_item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        &_icon {
          display: flex;
        }

        & p {
          color: #E93838;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.005em;
          padding-left: 6px;
        }

      }
    }
  }
}

.registrationWarningContainer {
  display: flex;
  margin: 16px 0 0 20px;
  width: 280px;

  &_logo {
    width: 16.5px;
    height: 16.5px;
    margin-top: 2.5px;
  }

  &_message {
    margin-left: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #333333;
  }
}
