.APIIntegration {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding-right: 48px;

  & > p {
    margin-bottom: 32px;
  }

  &__title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;

    & > a {
      display: inline-flex;
      text-decoration: none;
      color: #333333;
    }

    & > a> * + * {
      margin-left: 4px;
    }

  }

  &__title > * + * {
    margin-left: 40px;
  }

  &__checkboxes {

    .GlobalButton {
      margin-top: 32px;
      padding: 6px 12px 6px 12px;
    }
  }

  &__footer {
    margin-top: 160px;

    & .switchControl {
      margin-bottom: 40px;
    }

    &_buttons {
      display: flex;

      .GlobalButton {
        padding: 13px 16px 15px 12px;
      }
    }

    &_buttons > * + * {
      margin-left: 32px;
    }
  }
}