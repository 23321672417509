.circleVideo {
  position: relative;
  width: 320px;
  height: 320px;
  margin-bottom: 40px;

  &.rolledUp{
    width: 72px;
    height: 72px;

    .videoContainer {

      video{
        top: 8px;
      }

      .video-controls {
        top: 26px;
        left: 22px;

        img{
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  .videoContainer {
    width: 100%;
    height: 100%;

    &__content {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;

      video {
        position: absolute;
        z-index: 10;
        top: 20px;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }

      div {
        color: #FFFFFF;
      }
    }

    input {
      position: absolute;
      width: 100%;
      box-shadow: none;
    }

    .video-controls {
      position: absolute;
      z-index: 11;
      top: 130px;
      left: 110px;

      img {
        cursor: pointer;
      }
    }
  }

  .duration {
    position: absolute;
    z-index: 11;
    bottom: 0;
    right: 140px;
    padding: 2px 6px;
    border-radius: 12px;
    background: #333333;
    color: #FFFFFF;
    font-feature-settings: 'cpsp' on, 'case' on, 'cv05' on, 'cv02' on, 'cv01' on, 'cv09' on;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.56px;
  }
}
