.ModalPaymentInvoiceLink {
  padding: 24px;
  position: absolute;
  width: 471px;
  height: 331px;
  top: 30%;
  left: 48%;
  background: #FFFFFF;
  box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);
  border-radius: 12px;
  z-index: 105;

  &_header {

    &_close {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 6px;

      img {
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }

    &_checkGreen {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      img {
        width: 76px;
        height: 54px;
      }
    }
  }

  &_content {
    display: flex;
    flex-flow: column;
    align-items: center;

    .inputContainer {
      width: 100%;
      margin: 20px 0 24px 0;

      &__wrapper{
        width: 100%;
      }

      input{
        width: 100%;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'cv08' on, 'case' on;
        color: #333333;
        padding: 0 16px;
      }
    }

    h5 {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      color: #333333;
    }
  }

  &_bottom {
    display: flex;
    justify-content: space-between;
  }
}
