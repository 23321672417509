.telegramStatus {
  display: flex;
  white-space: nowrap;

  & > button {
    padding: 6px 12px;
  }

  &__sessionEnded {
    background: #FFFFFF;
    padding: 6px 12px;
    border-radius: 8px;
    opacity: 1;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #F54242;
    position: relative;

    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      left: 100%;
      top: calc(50% - 6px);
      border-top: 6px solid transparent;
      border-left: 6px solid #FFFFFF;
      border-bottom: 6px solid transparent;
    }
  }
}

.telegramStatus > * + * {
  margin-left: 8px;
}
