@import 'src/mixin.scss';

.payLockedWarn {
    display: flex;
    flex-direction: column;

    @include desktop {
      position: absolute;
      z-index: 555;
      width: 534px;
      left: 109px;
      top: 180px;
      border-radius: 12px;
    }
    @include mobile {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 28px 28px 0 0;
    }
    padding: 20px 32px 32px 32px;
    background: #FFFFFF;
    box-shadow: 0 12px 48px -16px rgba(0, 0, 0, 0.65);

    &__wrapper {
      width: 100%;
      height: 100%;
      cursor: default;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 500;

      background-color: rgba(0, 0, 0, 0);
    }

    @include mobile {
      &__content::before {
        content: '';
        display: block;
        position: fixed;
        left: 0;
        width: 100%;
        border-bottom: 1px solid #E0E0E0;
      }

      p {
        padding-top: 20px;
      }
    }

    & h4 {
      margin-bottom: 8px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      @include desktop {
        font-size: 24px;
        line-height: 32px;
      }
      @include mobile {
        font-size: 18px;
        line-height: 26px;
        padding-right: 60px;
      }
      letter-spacing: -0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;
      color: #333333;
    }

    & p {
      margin-bottom: 24px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;

      letter-spacing: -0.005em;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on;

      color: #333333;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      @include mobile {
        flex-direction: column;
      }

      & a {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px 16px 15px;
        @include desktop {
          width: 262px;
        }
        @include mobile {
          width: 100%;
        }
        height: 52px;
        background: #FE6D04;
        box-shadow: 0 4px 4px -2px rgba(141, 76, 0, 0.25);
        border-radius: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.005em;
        font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on, 'cv08' on;
        color: #FFFFFF;
      }
      a:active {
        transform: translate(0em, 0.15em);
      }

      & .GlobalButton {
        padding: 13px 16px 15px 16px;
        @include mobile {
          width:  100%;
          margin-top: 16px;
        }
      }
    }

    &__close {
      position: absolute;
      right: 24px;

      img {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
  }
