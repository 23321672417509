.notesList {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .note {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    padding: 8px 12px;

    &__title {
      overflow-x: hidden;
      margin-bottom: 8px;
    }

    &__timeAt {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 40px;

      p {
        margin-right: 4px;
      }

      div {
        display: flex;
      }
    }

    &__buttons {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      padding: 12px 16px;
      border-radius: 12px 0px 0px 0px;
      border: 1px solid #f2f2f2;
      background: #ffffff;

      &_edit {
        margin-right: 20px;
      }

      &_delete p {
        color: #f54242;
      }

      button {
        display: flex;
        align-items: center;
        border: none;
        background: none;
        gap: 4px;
        cursor: pointer;
      }
    }
  }
}
