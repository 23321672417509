.invoiceList {
  width: 70%;

  &_titleList {
    display: grid;
    grid-template-columns: 24% minmax(20%, 1fr) repeat(2, minmax(20%, 1fr));
    padding: 12px 0;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.005em;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
    color: #333333;
    border-bottom: 2px #E6E6EA solid;
    border-radius: 2px;

    :last-child {
      display: flex;
      justify-content: flex-end;
    }
  }

  &_list {

    &_button {
      padding-top: 12px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      text-decoration-line: underline;
      font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #FE6D04;

      p {
        width: fit-content;
        cursor: pointer;
      }
    }

    &_item {
      display: grid;
      grid-template-columns: 24% minmax(20%, 1fr) repeat(2, minmax(20%, 1fr));
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.03em;
      font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on;
      color: #333333;
      border-bottom: 1px #E6E6EA solid;
      border-radius: 2px;
      padding: 16px 0;

      :last-child {
        display: flex;
        justify-content: flex-end;
        text-align: end;
      }
    }
  }
}
