.NoConnectionsIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow-y: scroll;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.005em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on;
  color: #333333;

  &__iconBlock {
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__text {
    margin-top: 72px;

    & > p {
      margin-bottom: 56px;
      white-space: pre-line;
    }

    & > h4 {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.01em;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    & > button {
      padding: 15px;
    }
  }
}
